import React from 'react';
// Social media icons that can change colors dynamically

// import {ReactComponent as FacebookDynamic} from '../assets/images/al_facebook.svg';
// import {ReactComponent as InstagramDynamic} from '../assets/images/al_instagram.svg';
// import {ReactComponent as LinkedinDynamic} from '../assets/images/al_linkedin.svg';
// import {ReactComponent as PinterestDynamic} from '../assets/images/al_pinterest.svg';
// import {ReactComponent as DribbleDynamic} from '../assets/images/al_dribble.svg';
// import {ReactComponent as BehanceDynamic} from '../assets/images/al_behance.svg';
// import {ReactComponent as TelegramDynamic} from '../assets/images/al_telegram.svg';
// import {ReactComponent as TwitterDynamic} from '../assets/images/al_twitter.svg';
// import {ReactComponent as YouTubeDynamic} from '../assets/images/al_youtube.svg';
// import {ReactComponent as TikTokDynamic} from '../assets/images/al_tiktok.svg';
// import {ReactComponent as SpotifyDynamic} from '../assets/images/al_spotify.svg';
// import {ReactComponent as SnapchatDynamic} from '../assets/images/al_snapchat.svg';
// import {ReactComponent as AnghamiDynamic} from '../assets/images/al_anghami.svg';
// import {ReactComponent as TwitchDynamic} from '../assets/images/al_twitch.svg';
import {ReactComponent as FacebookDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_facebook.svg';
import {ReactComponent as InstagramDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_instagram.svg';
import {ReactComponent as LinkedinDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_linkedin.svg';
import {ReactComponent as PinterestDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_pinterest.svg';
import {ReactComponent as DribbleDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_dribble.svg';
import {ReactComponent as BehanceDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_behance.svg';
import {ReactComponent as TelegramDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_telegram.svg';
import {ReactComponent as TwitterDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_twitter.svg';
import {ReactComponent as YouTubeDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_youtube.svg';
import {ReactComponent as TikTokDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_tiktok.svg';
import {ReactComponent as SpotifyDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_spotify.svg';
import {ReactComponent as SnapchatDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_snapchat.svg';
import {ReactComponent as AnghamiDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_anghami.svg';
import {ReactComponent as TwitchDynamic} from '../assets/images/versionV2/SocialIcons/ProfileSocialIcons/al_twitch.svg';

// Social media DEFAULT icons
// import FacebookDefault from '../assets/images/SocialMediaDefaultIcons/al_facebook.svg';
// import InstagramDefault from '../assets/images/SocialMediaDefaultIcons/al_instagram.svg';
// import LinkedinDefault from '../assets/images/SocialMediaDefaultIcons/al_linkedin.svg';
// import PinterestDefault from '../assets/images/SocialMediaDefaultIcons/al_pinterest.svg';
// import DribbleDefault from '../assets/images/SocialMediaDefaultIcons/al_dribble.svg';
// import BehanceDefault from '../assets/images/SocialMediaDefaultIcons/al_behance.svg';
// import TelegramDefault from '../assets/images/SocialMediaDefaultIcons/al_telegram.svg';
// import TwitterDefault from '../assets/images/SocialMediaDefaultIcons/al_twitter.svg';
// import YouTubeDefault from '../assets/images/SocialMediaDefaultIcons/al_youtube.svg';
// import TikTokDefault from '../assets/images/SocialMediaDefaultIcons/al_tiktok.svg';
// import SpotifyDefault from '../assets/images/SocialMediaDefaultIcons/al_spotify.svg';
// import SnapchatDefault from '../assets/images/SocialMediaDefaultIcons/Snapchat.svg';
// import VideoMsgDefault from '../assets/images/SocialMediaDefaultIcons/sendVideoMsg.svg';
// import AnghamiDefault from '../assets/images/SocialMediaDefaultIcons/al_anghami.svg';
// import TwitchDefault from '../assets/images/SocialMediaDefaultIcons/al_twitch.svg';

// Social media DEFAULT icons V2
import FacebookDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/facebookDefault.svg';
import InstagramDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/instagramDefault.svg';
import LinkedinDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/linkedinDefault.svg';
import PinterestDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/pinterestDefault.svg';
import DribbleDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/dribbleDefault.svg';
import BehanceDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/behanceDefault.svg';
import TelegramDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/telegramDefault.svg';
import YouTubeDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/youtubeDefault.svg';
import TikTokDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/tiktokDefault.svg';
import SpotifyDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/spotifyDefault.svg';
import SnapchatDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/snapchatDefault.svg';
import AnghamiDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/anghamiDefault.svg';
import TwitchDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/twitchDefault.svg';
import SocialMediaFollowDeafult from '../assets/images/versionV2/monetize/SocialMediaFollowDeafult.svg';
import AskMeAnyThing from '../assets/images/versionV2/monetize/SocialMediaPromoteDefault.svg';
import ShortCallDeafult from '../assets/images/versionV2/monetize/ShortCallDeafult.svg';
import TipJarDeafult from '../assets/images/versionV2/monetize/TipJarDeafult.svg';
import SellProductDefault from '../assets/images/versionV2/monetize/SellProductDefault.svg';
import TwitterDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/twitterDefault.svg';
import RamadanDefault from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/ramadanDefault.svg';

// Social media WHITE icons
import SpotifyWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Spotify.svg';
import InstagramWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Instagram.svg';
import FacebookWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Facebook.svg';
import PinterestWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Pinterest.svg';
import TwitterWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Twitter.svg';
import YoutubeWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Youtube.svg';
import LinkedinWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/LinkedIn.svg';
import DribbleWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Dribble.svg';
import TikTokWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/TikTok.svg';
import BehanceWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Behance.svg';
import TelegramWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Telegram.svg';
import SnapchatWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Snapchat.svg';
import VideoMsgWhite from '../assets/images/SocialMediaWhiteIcons/sendVideoMsg.svg';
import PromoteMyBrandWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/PromoteMyBrand.svg';
import AskMeAnyThingWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/AskMeAnything.svg';
import ShortCallWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/BookCall.svg';
import TipJarWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/TipJar.svg';
import AnghamiWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Anghami.svg';
import TwitchWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/Twitch.svg';
import SellProductWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/AccessMyContent.svg';
import RamadanWhite from '../assets/images/versionV2/SocialIcons/SocialMediaWhiteIcons/ramadanWhite.svg';

// Social media BLACK icons
import SpotifyBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Spotify.svg';
import FacebookBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Facebook.svg';
import InstagramBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Instagram.svg';
import TwitterBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/X-Black.svg';
import YoutubeBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Youtube.svg';
import LinkedinBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/LinkedIn.svg';
import TikTokBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/TikTok.svg';
import DribbleBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Dribble.svg';
import PinterestBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Pinterest.svg';
import BehanceBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Behance.svg';
import TelegramBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Telegram.svg';
import SnapchatBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Snapchat.svg';
import VideoMsgBlack from '../assets/images/SocialMediaBlackIcons/sendVideoMsg.svg';
import PromoteMyBrandBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/PromoteMyBrand.svg';
import AskMeAnyThingBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/AskMeAnything.svg';
import ShortCallBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/BookCall.svg';
import TipJarBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/TipJar.svg';
import AnghamiBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Anghami.svg';
import TwitchBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/Twitch.svg';
import SellProductBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/AccessMyContent.svg';
import RamadanBlack from '../assets/images/versionV2/SocialIcons/SocialMediaBlackIcons/ramadanBlack.svg';

// Public profile WHITE icons
import FacebookPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_facebook.svg';
import InstagramPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_instagram.svg';
import LinkedinPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_linkedin.svg';
import PinterestPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_pinterest.svg';
import SpotifyPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_spotify.svg';
import TwitterPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_twitter.svg';
import TelegramPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_telegram.svg';
import TiktokPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_tiktok.svg';
import YouTubePublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_youtube.svg';
import DribblePublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_dribble.svg';
import BehancePublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/al_behance.svg';
import SnapchatPublicProfileWhite from '../assets/images/PublicProfileWhiteIcons/snapchat_icon.svg';

// Public profile BLACK icons
import FacebookPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_facebook.svg';
import InstagramPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_instagram.svg';
import DribblePublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_dribble.svg';
import BehancePublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_behance.svg';
import YouTubePublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_youtube.svg';
import LinkedInPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_linkedin.svg';
import PinterestPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_pinterest.svg';
import SpotifyPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_spotify.svg';
import TelegramPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_telegram.svg';
import TikTokPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_tiktok.svg';
import TwitterPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/al_twitter.svg';
import SnapchatPublicProfileBlack from '../assets/images/PublicProfileBlackIcons/snapchat_icon.svg';

// Social media DEFAULT icons V2 transparent
import FacebookDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Facebook.png';
import InstagramDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Instagram.svg';
import LinkedinDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/LinkedIn.svg';
import PinterestDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Pinterest.svg';
import DribbleDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Dribbble.svg';
import BehanceDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Behance.svg';
import TelegramDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Telegram.svg';
import YouTubeDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/YouTube.svg';
import TikTokDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/TikTok.svg';
import SpotifyDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Spotify.svg';
import SnapchatDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Snapchat.svg';
import AnghamiDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Anghami.svg';
import TwitchDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Twitch.svg';
import TwitterDefaultTrans from '../assets/images/versionV2/SocialIcons/SocialIconsDefaultTransparent/Twitter.svg';

// Monitize deafult icons
// import videoMsg from '../assets/images/SocialMediaDefaultIcons/sendVideoMsg.svg';
// import socialMediaPromote from '../assets/images/SocialMediaDefaultIcons/socialMediaPromote.svg';
// import shortCall from '../assets/images/SocialMediaDefaultIcons/shortCall.svg';
// import socialMediaFollow from '../assets/images/SocialMediaDefaultIcons/socialMediaFollow.svg';

import {SocialIcon} from 'react-social-icons';

import {ReactComponent as RegularLink} from '../assets/images/addLink/monetize/regular_link.svg';
import {ReactComponent as AskMeAnything} from '../assets/images/addLink/monetize/ask_me_anything.svg';
import {ReactComponent as PromoteYourBrand} from '../assets/images/addLink/monetize/promote_your_brand.svg';
import {ReactComponent as BookCall} from '../assets/images/addLink/monetize/book_1_1_call.svg';
import {ReactComponent as TipJar} from '../assets/images/addLink/monetize/tipjar.svg';
import {ReactComponent as AccessExclusive} from '../assets/images/addLink/monetize/access_exclusive_content.svg';

import {ReactComponent as Instagram} from '../assets/images/addLink/social/instagram.svg';
import {ReactComponent as Facebook} from '../assets/images/addLink/social/facebook.svg';
import {ReactComponent as LinkedIn} from '../assets/images/addLink/social/linkedin.svg';
import {ReactComponent as Pinterest} from '../assets/images/addLink/social/pinterest.svg';
import {ReactComponent as TwitterX} from '../assets/images/addLink/social/x.svg';
import {ReactComponent as YouTube} from '../assets/images/addLink/social/youtube.svg';
import {ReactComponent as TikTok} from '../assets/images/addLink/social/tiktok.svg';
import {ReactComponent as Spotify} from '../assets/images/addLink/social/spotify.svg';
import {ReactComponent as Snapchat} from '../assets/images/addLink/social/snapchat.svg';
import {ReactComponent as Telegram} from '../assets/images/addLink/social/telegram.svg';
import {ReactComponent as Behance} from '../assets/images/addLink/social/behance.svg';
import {ReactComponent as Dribbble} from '../assets/images/addLink/social/dribbble.svg';
import {ReactComponent as Anghami} from '../assets/images/addLink/social/anghami.svg';
import {ReactComponent as Twitch} from '../assets/images/addLink/social/twitch.svg';

export const addLinkIcons: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  regularLink: RegularLink,
  videoMsg: AskMeAnything,
  socialMediaPromote: PromoteYourBrand,
  shortCall: BookCall,
  tipJar: TipJar,
  sellProduct: AccessExclusive,
  instagram: Instagram,
  facebook: Facebook,
  linkedin: LinkedIn,
  pinterest: Pinterest,
  twitter: TwitterX,
  youtube: YouTube,
  tiktok: TikTok,
  spotify: Spotify,
  snapchat: Snapchat,
  telegram: Telegram,
  behance: Behance,
  dribbble: Dribbble,
  anghami: Anghami,
  twitch: Twitch,
};

export const dynamicSocials = (fill:string | null)=>{
  if (!fill) fill = 'white';
  return [{
    key: 'facebook',
    dynamic: <FacebookDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="facebook" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'instagram',
    dynamic: <InstagramDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="instagram" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'linkedin',
    dynamic: <LinkedinDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="linkedin" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'pinterest',
    dynamic: <PinterestDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="pinterest" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'dribbble',
    dynamic: <DribbleDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="dribbble" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'behance',
    dynamic: <BehanceDynamic fill={fill}/>,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="behance" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'telegram',
    dynamic: <TelegramDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="telegram" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'twitter',
    dynamic: <TwitterDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="twitter" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'tiktok',
    dynamic: <TikTokDynamic fill={fill}/>,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'#00000000'} network="tiktok" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'spotify',
    dynamic: <SpotifyDynamic fill={fill}/>,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="spotify" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'youtube',
    dynamic: <YouTubeDynamic fill={fill}/>,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="youtube" style={{height: 20, width: 20, borderRadius: 0}} />,
  }, {
    key: 'snapchat',
    dynamic: <SnapchatDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="snapchat" style={{height: 20, width: 20, borderRadius: 0}} />,
  },
  {
    key: 'anghami',
    dynamic: <AnghamiDynamic fill={fill} />,
  }, {
    key: 'twitch',
    dynamic: <TwitchDynamic fill={fill} />,
    // dynamic: <SocialIcon fgColor={fill} bgColor={'transparent'} network="twitch" style={{height: 20, width: 20, borderRadius: 0}} />,
  },
];
};

export const socialMediaLinks = [
  {
    key: 'facebook',
    name: 'Facebook',
    defaultIconTransparent: FacebookDefaultTrans,
    defaultIcon: FacebookDefault,
    blackIcon: FacebookBlack,
    whiteIcon: FacebookWhite,
    profileBlack: FacebookPublicProfileBlack,
    profileWhite: FacebookPublicProfileWhite,
    placeholder: 'fb_url_placeholder',
    linkTemplates: ['facebook.com', 'facebook.net', 'fb.com', 'fb.gg', 'fb.watch'],
  },
  {
    key: 'instagram',
    name: 'Instagram',
    defaultIconTransparent: InstagramDefaultTrans,
    defaultIcon: InstagramDefault,
    blackIcon: InstagramBlack,
    whiteIcon: InstagramWhite,
    profileBlack: InstagramPublicProfileBlack,
    profileWhite: InstagramPublicProfileWhite,
    placeholder: 'insta_username_placeholder',
    linkTemplates: ['instagram.com', 'ig.me'],
  },
  {
    key: 'linkedin',
    name: 'Linkedin',
    defaultIconTransparent: LinkedinDefaultTrans,
    defaultIcon: LinkedinDefault,
    blackIcon: LinkedinBlack,
    whiteIcon: LinkedinWhite,
    profileBlack: LinkedInPublicProfileBlack,
    profileWhite: LinkedinPublicProfileWhite,
    placeholder: 'linkedin_url_plcaheolder',
    linkTemplates: ['linkedin.com/in'],
  },
  {
    key: 'pinterest',
    name: 'Pinterest',
    defaultIconTransparent: PinterestDefaultTrans,
    defaultIcon: PinterestDefault,
    blackIcon: PinterestBlack,
    whiteIcon: PinterestWhite,
    profileBlack: PinterestPublicProfileBlack,
    profileWhite: PinterestPublicProfileWhite,
    placeholder: 'pinterest_placeholder',
    linkTemplates: ['pinterest.com'],
  },
  {
    key: 'dribbble',
    name: 'Dribbble',
    defaultIconTransparent: DribbleDefaultTrans,
    defaultIcon: DribbleDefault,
    blackIcon: DribbleBlack,
    whiteIcon: DribbleWhite,
    profileBlack: DribblePublicProfileBlack,
    profileWhite: DribblePublicProfileWhite,
    placeholder: 'dribble_placeholder',
    linkTemplates: ['dribbble.com'],
  },
  {
    key: 'behance',
    name: 'Behance',
    defaultIconTransparent: BehanceDefaultTrans,
    defaultIcon: BehanceDefault,
    blackIcon: BehanceBlack,
    whiteIcon: BehanceWhite,
    profileBlack: BehancePublicProfileBlack,
    profileWhite: BehancePublicProfileWhite,
    placeholder: 'behance_placeholder',
    linkTemplates: ['behance.net'],
  },
  {
    key: 'telegram',
    name: 'Telegram',
    defaultIconTransparent: TelegramDefaultTrans,
    defaultIcon: TelegramDefault,
    blackIcon: TelegramBlack,
    whiteIcon: TelegramWhite,
    profileBlack: TelegramPublicProfileBlack,
    profileWhite: TelegramPublicProfileWhite,
    placeholder: 'telegram_placeholder',
    linkTemplates: ['telegram.me', 't.me', 'telegram.org'],
  },
  {
    key: 'twitter',
    name: 'Twitter',
    defaultIconTransparent: TwitterDefaultTrans,
    defaultIcon: TwitterDefault,
    blackIcon: TwitterBlack,
    whiteIcon: TwitterWhite,
    profileBlack: TwitterPublicProfileBlack,
    profileWhite: TwitterPublicProfileWhite,
    placeholder: 'twitter_username_placeholder',
    linkTemplates: ['twitter.com', 'twttr.com', 'twitter.co'],
  },
  {
    key: 'tiktok',
    name: 'Tik Tok',
    defaultIconTransparent: TikTokDefaultTrans,
    defaultIcon: TikTokDefault,
    blackIcon: TikTokBlack,
    whiteIcon: TikTokWhite,
    profileBlack: TikTokPublicProfileBlack,
    profileWhite: TiktokPublicProfileWhite,
    placeholder: 'tiktok_placeholder',
    linkTemplates: ['tiktok.com', 'tiktokv.com'],
  },
  {
    key: 'spotify',
    name: 'Spotify',
    defaultIconTransparent: SpotifyDefaultTrans,
    defaultIcon: SpotifyDefault,
    blackIcon: SpotifyBlack,
    whiteIcon: SpotifyWhite,
    profileBlack: SpotifyPublicProfileBlack,
    profileWhite: SpotifyPublicProfileWhite,
    placeholder: 'spotify_url_placeholder',
    linkTemplates: ['open.spotify.com/artist', 'spoti.fi', 'spotify.com'],
  },
  {
    key: 'youtube',
    name: 'YouTube',
    defaultIconTransparent: YouTubeDefaultTrans,
    defaultIcon: YouTubeDefault,
    blackIcon: YoutubeBlack,
    whiteIcon: YoutubeWhite,
    profileBlack: YouTubePublicProfileBlack,
    profileWhite: YouTubePublicProfileWhite,
    placeholder: 'yt_url_placeholder',
    linkTemplates: ['youtube', 'youtu.be', 'youtubekids.com', 'yt.be'],
  },
  {
    key: 'snapchat',
    name: 'Snapchat',
    defaultIconTransparent: SnapchatDefaultTrans,
    defaultIcon: SnapchatDefault,
    blackIcon: SnapchatBlack,
    whiteIcon: SnapchatWhite,
    profileBlack: SnapchatPublicProfileBlack,
    profileWhite: SnapchatPublicProfileWhite,
    placeholder: 'snapchat_placeholder',
    linkTemplates: ['story.snapchat.com', 'snapchat.com/add'],
  },
  {
    key: 'videoMsg',
    name: 'Ask me anything',
    defaultIcon: AskMeAnyThing,
    blackIcon: AskMeAnyThingBlack,
    whiteIcon: AskMeAnyThingWhite,
    profileBlack: AskMeAnyThing,
    profileWhite: AskMeAnyThing,
    placeholder: 'ask_me_anything_placeholder',
    linkTemplates: [],
    hideFromPrePollulated: true,
  },
  {
    key: 'tipJar',
    name: 'Tip jar',
    defaultIcon: TipJarDeafult,
    blackIcon: TipJarBlack,
    whiteIcon: TipJarWhite,
    profileBlack: TipJarBlack,
    profileWhite: TipJarWhite,
    placeholder: 'tip_jar_placeholder',
    linkTemplates: [],
    hideFromPrePollulated: true,
  },
  {
    key: 'socialMediaPromote',
    name: 'Promte Me',
    defaultIcon: SocialMediaFollowDeafult,
    blackIcon: PromoteMyBrandBlack,
    whiteIcon: PromoteMyBrandWhite,
    profileBlack: SocialMediaFollowDeafult,
    profileWhite: SocialMediaFollowDeafult,
    placeholder: 'social_media_promote_placeholder',
    linkTemplates: [],
    hideFromPrePollulated: true,
  },
  {
    key: 'anghami',
    name: 'Anghami',
    defaultIconTransparent: AnghamiDefaultTrans,
    defaultIcon: AnghamiDefault,
    blackIcon: AnghamiBlack,
    whiteIcon: AnghamiWhite,
    profileBlack: '',
    profileWhite: '',
    placeholder: 'anghami_placeholder',
    linkTemplates: ['play.anghami.com'],
  },
  {
    key: 'twitch',
    name: 'Twitch',
    defaultIconTransparent: TwitchDefaultTrans,
    defaultIcon: TwitchDefault,
    blackIcon: TwitchBlack,
    whiteIcon: TwitchWhite,
    profileBlack: '',
    profileWhite: '',
    placeholder: 'twitch_placeholder',
    linkTemplates: ['twitch.tv'],
  },
  {
    key: 'sellProduct',
    name: 'Sell Product',
    defaultIcon: SellProductDefault,
    blackIcon: SellProductBlack,
    whiteIcon: SellProductWhite,
    profileBlack: '',
    profileWhite: '',
    placeholder: 'sell_product_placeholder',
    linkTemplates: [],
    hideFromPrePollulated: true,
  },
  {
    key: 'shortCall',
    name: 'Book call with me',
    defaultIcon: ShortCallDeafult,
    blackIcon: ShortCallBlack,
    whiteIcon: ShortCallWhite,
    profileBlack: '',
    profileWhite: '',
    placeholder: 'sell_product_placeholder',
    linkTemplates: [],
    hideFromPrePollulated: true,
  },
  {
    key: 'ramadan',
    name: 'Ramadan Giving',
    defaultIcon: RamadanDefault,
    blackIcon: RamadanBlack,
    whiteIcon: RamadanWhite,
    profileBlack: '',
    profileWhite: '',
    placeholder: '',
    linkTemplates: [],
    section: 'seasonal',
  },
];

export const ExploreModalData = [
  {
    key: 'instagram',
    name: 'Instagram',
    defaultIcon: InstagramDefault,
    subTitle: 'add_your_profile_brands_profile_or_a_post_you_love',
  },
  {
    key: 'youtube',
    name: 'YouTube',
    defaultIcon: YouTubeDefault,
    subTitle: 'add_your_channel_or_link_to_a_video',
  },
  {
    key: 'tiktok',
    name: 'Tik Tok',
    defaultIcon: TikTokDefault,
    subTitle: 'add_your_profile_or_a_post_you_love',
  },
  {
    key: 'snapchat',
    name: 'Snapchat',
    defaultIcon: SnapchatDefault,
    subTitle: 'add_your_snap_account_or_show',
  },
  {
    key: 'facebook',
    name: 'Facebook',
    defaultIcon: FacebookDefault,
    subTitle: 'add_your_Facebook_page_profile_or_group',
  },
  {
    key: 'twitter',
    name: 'Twitter',
    defaultIcon: TwitterDefault,
    subTitle: 'add_your_profile_or_a_tweet_you_love',
  },
  {
    key: 'spotify',
    name: 'Spotify',
    defaultIcon: SpotifyDefault,
    subTitle: 'add_your_profile_song_or_favourite_playlist',
  },
  {
    key: 'telegram',
    name: 'Telegram',
    defaultIcon: TelegramDefault,
    subTitle: 'share_your_direct_profile',
  },
  {
    key: 'linkedin',
    name: 'Linkedin',
    defaultIcon: LinkedinDefault,
    subTitle: 'share_your_profile_or_companies_page',
  },
  {
    key: 'pinterest',
    name: 'Pinterest',
    defaultIcon: PinterestDefault,
    subTitle: 'Showcase pins, boards and more',
  },
  {
    key: 'dribbble',
    name: 'Dribbble',
    defaultIcon: DribbleDefault,
    subTitle: 'Quick links to your social media accounts',
  },
  {
    key: 'behance',
    name: 'Behance',
    defaultIcon: BehanceDefault,
    subTitle: 'Quick links to your social media accounts',
  },
  {
    key: 'anghami',
    name: 'Anghami',
    defaultIcon: AnghamiDefault,
    subTitle: 'Quick links to your social media accounts',
  },
  {
    key: 'twitch',
    name: 'Twitch',
    defaultIcon: TwitchDefault,
    subTitle: 'Quick links to your social media accounts',
  },
];

export const BrandPromotionMedia = [
  {
    key: 'instagram',
    name: 'Instagram',
  },
  {
    key: 'youtube',
    name: 'YouTube',
  },
  {
    key: 'tiktok',
    name: 'Tik Tok',
  },
  {
    key: 'snapchat',
    name: 'Snapchat',
  },
  {
    key: 'facebook',
    name: 'Facebook',
  },
  {
    key: 'twitter',
    name: 'Twitter',
  },
  {
    key: 'telegram',
    name: 'Telegram',
  },
  {
    key: 'twitch',
    name: 'Twitch',
  },
];

export const SellMyProduct = [
  {
    key: 'guide',
    name: "Guide",
    enable: true,
  },
  {
    key: 'picture',
    name: "Picture",
    enable: true,
  },
  {
    key: 'video',
    name: "Video",
    enable: true,
  },
  {
    key: 'link',
    name: "Link",
    enable: false,
  },
  {
    key: 'course',
    name: "Course",
    enable: false,
  },
  {
    key: 'ebook',
    name: "E-book",
    enable: false,
  },
];

export const monetizeImageMap: any = {
  'videoMsg': AskMeAnyThing,
  'socialMediaFollow': SocialMediaFollowDeafult,
  'socialMediaPromote': SocialMediaFollowDeafult,
  'shortCall': ShortCallDeafult,
  'tipJar': TipJarDeafult,
  'sellProduct': SellProductDefault,
};

export const socialPlatformContentType: {[key: string]: string[]} = {
  'Instagram': ['Instagram Reel', 'Instagram Story', 'Instagram picture'],
  'YouTube': ['Video Integration 60-90 seconds', 'Full Video', 'YouTube Shorts'],
  'Tik Tok': ['Tik Tok Video', 'Sponsored Livestream'],
  'Snapchat': ['Snapchat Story', 'Snapchat Spotlight'],
  'Facebook': ['Video Integration 60-90 seconds', 'Full Video', 'Picture', 'Facebook Reel'],
  'Twitter': ['Tweet', 'Retweet'],
  'Telegram': ['Shoutout'],
  'Twitch': ['Live Integration'],
};

export const extendUrl = (data:any)=>{
  const {type, url} = data;
  if (!url) return url;
  const socialMediaDetails = socialMediaLinks.find((item)=>item.key === type);
  if (!socialMediaDetails) return url;

  const usedTemplate = socialMediaDetails.linkTemplates.find((item)=>{
    return url.includes(item);
  });

  if (usedTemplate) return url;
  const template = socialMediaDetails.linkTemplates[0];
  if (type === 'youtube') return template + '.com/' + url;
  else if (type === 'tiktok') return template + '/@' + url;
  else if (type === 'snapchat') return template + '/@' + url;
  return template + '/' + url;
};

export const monetizeKeyToText: any = {
  'videoMsg': 'Ask me anything',
  'socialMediaPromote': 'Promote on social media',
  'shortCall': 'Book 1:1',
  'tipJar': 'Support me',
  'sellProduct': 'Access exclusive content',
};
