import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface UserThemeState{
    themeName: string | null,
    mobileImage: string | null,
    desktopImage: string | null,
    cardTextColor: string | null,
    nonCardTextColor: string | null,
    backgroundColor: string | null,
    cardColor: string | null,
    mobileSocialMediaColor: string | null,
    desktopSocialMediaColor: string | null,
    cardShape: string | null,
    englishFont: string | null,
    arabicFont: string | null,
    displayLocale: string | null,
    iconSet: string | null,
    boxShadow: string | null,
    cardStyle: string | null,
    borderColor: string | null,
}

const initialState: UserThemeState = {
  themeName: null,
  mobileImage: null,
  desktopImage: null,
  cardTextColor: null,
  nonCardTextColor: null,
  backgroundColor: null,
  cardColor: null,
  mobileSocialMediaColor: null,
  desktopSocialMediaColor: null,
  cardShape: null,
  englishFont: null,
  arabicFont: null,
  displayLocale: null,
  iconSet: null,
  boxShadow: null,
  cardStyle: null,
  borderColor: null,
};


export const userThemeSlice = createSlice({
  name: 'userTheme',
  initialState,
  reducers: {
    setUserTheme: (state, action: PayloadAction<UserThemeState>) => {
      const theme = action.payload;
      state.themeName = theme.themeName;
      state.mobileImage = theme.mobileImage;
      state.desktopImage = theme.desktopImage;
      state.cardTextColor = theme.cardTextColor;
      state.nonCardTextColor = theme.nonCardTextColor;
      state.backgroundColor = theme.backgroundColor;
      state.cardColor = theme.cardColor;
      state.mobileSocialMediaColor = theme.mobileSocialMediaColor;
      state.desktopSocialMediaColor = theme.desktopSocialMediaColor;
      state.cardShape = theme.cardShape;
      state.englishFont =theme.englishFont;
      state.arabicFont = theme.arabicFont;
      state.displayLocale = theme.displayLocale;
      state.iconSet = theme.iconSet;
      state.boxShadow= theme.boxShadow;
      state.cardStyle= theme.cardStyle;
      state.borderColor=theme.borderColor;
    },
  },
  extraReducers: {
    'user/setMe': (state, {payload}) => {
      if (payload.me?.linksUser) {
        const user = payload.me.linksUser;
        // state = payload.me.linksUser;
        state.themeName = user.themeName;
        state.mobileImage = user.mobileImage;
        state.desktopImage = user.desktopImage;
        state.cardTextColor = user.cardTextColor;
        state.nonCardTextColor = user.nonCardTextColor;
        state.backgroundColor = user.backgroundColor;
        state.cardColor = user.cardColor;
        state.mobileSocialMediaColor = user.mobileSocialMediaColor;
        state.desktopSocialMediaColor = user.desktopSocialMediaColor;
        state.cardShape = user.cardShape;
        state.englishFont =user.englishFont;
        state.arabicFont = user.arabicFont;
        state.displayLocale = user.displayLocale;
        state.iconSet = user.iconSet;
        state.boxShadow= user.boxShadow;
        state.cardStyle= user.cardStyle;
        state.borderColor=user.borderColor;
      }
    },
  },
});

export const {setUserTheme} = userThemeSlice.actions;

export const selectUserTheme = (state: RootState) => state.userTheme;

export default userThemeSlice.reducer;
