import React, {useEffect, useState} from 'react';
import styles from '../../style/global.module.scss';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrand.svg';
// import loginImage from '../../assets/images/login-image.png';
import forgotImage from '../../assets/images/forgot-banner.png';
import classNames from 'classnames';
import {toast, ToastContainer} from 'react-toastify';
import useInput from '../../hooks/useInput';
import useRequest from '../../features/API/request';
import arrowLeft from '../../assets/images/arrow-left.svg';
import {raiseToast} from '../../components/Toast/Toast';
import {determineSafariCss} from '../../features/util';
import {sendForgetPasswordCognito, createUserSession, removeUserSession} from '../../features/Login/login';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {useTranslation} from 'react-i18next';
import forgetStyle from './Forget.module.scss';

const cn = classNames.bind(styles);

const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Forgot = () => {
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    validate: validateEmail,
  } = useInput(isEmail);
  const {t} = useTranslation();
  const resetRequest = useRequest({url: '/auth/forgot', method: 'POST'});
  const handleClickButton = async () => {
    // validate email
    validateEmail();
    if (!emailIsValid) return;
    const result = await sendForgetPasswordCognito(emailValue.toLowerCase());
    if (result.error) {
      const splitData = result.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (result.error) {
        case 'LimitExceededException':
          raiseToast({message: msg || result.message, ...(result.error && {variant: 'error'})});
          break;
        case 'UserNotFoundException': 
          raiseToast({message: msg || 'User not found.', ...(result.error && {variant: 'error'})});
          break;
        default:
          console.log('no match found');  
      }
      return;
    }
    raiseToast({message: 'Check your email!' || result.message, ...(result.error && {variant: 'error'})});
    createUserSession({username: emailValue});
    // window.location.assign('/public/reset-password');
  };

  const emailClasses = emailHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];

  useEffect(() => {
    removeUserSession();
  }, []);  

  return (
    <section className={styles['signin-section']}>
      <div className={forgetStyle.form_section}>
        <a href="/">
          <span>
            <object data={logo} type="image/svg+xml"></object>
          </span>
        </a>
        <div className={forgetStyle['forget-form']}>
          <div className={forgetStyle['forget-form-inner']}>
            <h4>{t('reset_password')}</h4>
            <p>
              {t('enter_register_email')}
            </p>
            <form onSubmit={(e)=>e.preventDefault()}>
              <div className={emailClasses} >
                 <TextFieldInput 
                    placeholder={t('email')}
                    label={t('your_email')}
                    staticHeader={true}
                    customStyle={{padding: '27px 22px 10px'}}
                    value={emailValue}
                    onKeyUp={(e: any)=>{
                      if (e.keyCode === 13) {
                        handleClickButton();
                      }
                    }}
                    onChange={(e: any) => {
                      emailChangeHandler(e.target.value);
                    }}
                  />
                { emailHasError && <p className={styles['error-text']}>Please enter a valid email address.</p>}
              </div>
              <div className={forgetStyle.btn_container}>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickButton();
                  }}
                  className={styles['main-btn']}
                >
                  {t('reset_instruction')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

Forgot.propTypes = {};

export default Forgot;
