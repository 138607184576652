import React from 'react';
import styles from './LinkCard.module.scss';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {useTranslation} from 'react-i18next';

export interface ExploreLinkItem {
  type: string;
  name?: string;
  title: string;
  subTitle: string;
  url?: string;
  defaultIcon: React.ReactNode;
  editable?: boolean;
  sectionId?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface ILinkCardProps {
  item: ExploreLinkItem;
  onAddLink: (item: ExploreLinkItem) => void;
  viewBtn?: boolean;
}

const LinkCard = ({item, onAddLink, viewBtn = false}: ILinkCardProps) => {
  const {t} = useTranslation();
  return (
    <div className={styles.card_item_box}>
      <div className={styles.card_item_box_left}>
        <div className={styles.imageContainer}>{item.defaultIcon}</div>
        <div className={styles.card_item_box_middle}>
          <div>{item.title}</div>
          <div className={styles.subtitle}>{t(item.subTitle)}</div>
        </div>
      </div>
      <div className={styles.card_item_box_right}>
        {viewBtn && (
          <a href={item.url} className={styles.view_link} target='_blank' rel='noreferrer'>
            {t('view_link')}
          </a>
        )}
        <AddCircleIcon htmlColor='black' onClick={() => onAddLink(item)} />
      </div>
    </div>
  );
};

export default LinkCard;
