import React from 'react';
import {LinkModalProps, LinkModalType} from './modals/types';
import LinkModal from './LinkModal';
import VideoMsgLinkModal from './modals/VideoMsgLinkModal';
import TipJarLinkModal from './modals/TipJarLinkModal';
import SocialMediaPromoteLinkModal from './modals/SocialMediaPromoteLinkModal';
import ShortCallLinkModal from './modals/ShortCallLinkModal';
import SellProductLinkModal from './modals/SellProductLinkModal';

type Props = LinkModalProps;

const LinkModalFactory = (props: Props) => {
  const type: LinkModalType = (props.defaultLink?.type as LinkModalType) ?? 'link';

  switch (type) {
    case 'videoMsg':
      return <VideoMsgLinkModal {...props} />;
    case 'tipJar':
      return <TipJarLinkModal {...props} />;
    case 'socialMediaPromote':
      return <SocialMediaPromoteLinkModal {...props} />;
    case 'sellProduct':
      return <SellProductLinkModal {...props} />;
    case 'shortCall':
      return <ShortCallLinkModal {...props} />;
    default:
      return <LinkModal {...props} />;
  }
};

export default LinkModalFactory;
