import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Suspense,
  lazy,
} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../app/store';
import {
  setDataChange,
  setShouldSave,
} from '../../features/stores/changeDataSlicer';
import useRequest, {useUploadRequest} from '../../features/API/request';
import RouteLeavingGuard from '../../components/UnsavedChanges/RouteLeavingGuard';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {refreshMe} from '../../features/Login/login';
import Loader from 'react-loader-spinner';
import WarningModal from '../../components/WarningModal/WarningModal';
import useWindowWidth from '../../hooks/useWindowWidth';
import {raiseToast} from '../../components/Toast/Toast';
import {useTranslation} from 'react-i18next';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {removeStepCookie} from '../../Helper/generalHelper';
import {store} from '../../app/store';
import {isRtl} from './../../features/util';
import styles from './NewSettings.module.scss';
import UserSettings from '../../components/UserSettings/UserSettings';
import Design from '../Design/DesignV2';
import UpdatePassword from '../AdvanceSettings/UpdatePassword/UpdatePassword';
import {dataURLtoFile} from '../../Helper/imageCropper';
import {toast} from 'react-toastify';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';

const MobilePreview = lazy(
    () => import('../../components/MobilePreview/MobilePreview'),
);
const MyProfileItem = lazy(
    () => import('../../components/MyProfileItems/MyProfileItem'),
);

interface StyleProps {
  width: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      border: 0,
      boxShadow: 'none',
      paddingTop: ({width}) => (width < 1024 ? '0px' : '80px'),
    },
    tabs: {
      'minHeight': '0px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTabs-flexContainer': {
        'padding': ({width}) => (width < 768 ? '0px' : '0 80px'),
        'columnGap': ({width}) => (width < 768 ? '8px' : '10px'),
        '& button': {
          'padding': ({width}) => (width < 768 ? '6px 14px' : '8px 16px'),
          'margin': '0 !important',
          '& .MuiTab-wrapper': {
            textTransform: 'none',
            fontWeight: 400,
          },
        },
      },
    },
  }),
);

const tabStyle: React.CSSProperties = {
  borderRadius: '32px',
  height: '35px',
  minHeight: '0',
  minWidth: 'auto',
  fontSize: '16px',
  margin: '0 10px',
  background: '#8080801c',
  color: 'rgb(64 64 64)',
};

const activeStyle: React.CSSProperties = {
  ...tabStyle,
  background: 'black',
  color: 'white',
};

const getStyle = (active: boolean, width: number): React.CSSProperties => ({
  ...(active ? activeStyle : tabStyle),
  margin: width < 768 ? '0 5px' : tabStyle.margin,
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, width, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={width < 1024 ? 1 : 3} pr={width < 1024 ? 1 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  'id': `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

const NewSettings: React.FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const selectUser = (state: any) => state.user.me;
  const user = useSelector(selectUser);
  const theme = useSelector(selectUserTheme);
  const dataChange = useSelector(
      (state: RootState) => state.changeData.changeData,
  );
  const shouldSave = useSelector(
      (state: RootState) => state.changeData.shouldSaveEverything,
  );
  const linksUser = useSelector(selectLinksUser);
  const [validationFinish, setValidationFinish] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [value, setValue] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const width = useWindowWidth();
  const classes = useStyles({width});

  const updateProfileRequest = useRequest({
    method: 'POST',
    url: '/linksUser/profile-settings',
  });

  const uploadAvatarRequest = useUploadRequest({
    method: 'POST',
    url: '/linksUser/user-avatar',
  });

  const themeRequest = useRequest({url: '/linksUser/theme', method: 'POST'});
  const customBackgroundReq = useRequest({
    url: '/linksUser/custom-background-image',
    method: 'POST',
  });

  const handleProfileEvent = () => {
    if (window.clevertap) {
      const data = [];
      if (linksUser.avatar?.includes('base64') && !user.avatar) {
        data.push('profile_pic');
      }
      if (linksUser.bio && !user?.linksUser?.bio) {
        data.push('bio');
      }
      if (linksUser.fullname && !user?.linksUser?.fullname) {
        data.push('name');
      }
      if (linksUser.location && !user?.linksUser?.location) {
        data.push('country');
      }
      if (data.length) {
        window.clevertap.event.push('add_profile_data', {
          'data_type': data.join(),
          'UUID': user.email,
        });
      }
    }
  };

  const handleSave = useCallback(async () => {
    const result = await themeRequest.doRequest({
      ...theme,
    });
    store.dispatch(setDataChange(false));
    store.dispatch(setShouldSave(false));
  }, [themeRequest, t]);

  const profileContent = <UserSettings />;
  const designContent = <Design handleSave={handleSave} />;

  const SectionList = useMemo(
      () => [
        {
          id: '1',
          key: 'account',
          name: t('Account'),
          subtitle: t(''),
          isAccordion: false,
          content: profileContent,
          isVisible: true,
        },
        {
          id: '2',
          key: 'design',
          name: t('Design'),
          subtitle: t(''),
          isAccordion: false,
          content: designContent,
          isVisible: true,
        },
        {
          id: '3',
          key: 'password',
          name: t('Password'),
          subtitle: t(''),
          isAccordion: false,
          content: <UpdatePassword />,
          isVisible: true,
        },
      ],
      [t, handleSave],
  );

  useEffect(() => {
    dispatch(setShouldSave(false));
    removeStepCookie();
  }, [dispatch]);

  useDidUpdateEffect(() => {
    if (shouldSave) {
      validateAllBeforeSave().then((valid) => {
        if (valid && validationFinish && dataChange) {
          saveEverything();
        }
      });
    }
  }, [shouldSave, validationFinish, dataChange]);

  const validateAllBeforeSave = useCallback(async () => {
    setValidationFinish(false);
    const error = false; // Your validation logic here
    if (error) {
      setValidationFinish(false);
      dispatch(setShouldSave(false));
      return false;
    }
    setValidationFinish(true);
    return true;
  }, [dispatch]);

  const saveEverything = useCallback(async () => {
    const currentTab = value;
    const regex = new RegExp(/^[a-z0-9_.]{3,20}$/gm);
    try {
      if (
        linksUser?.nickname != user?.linksUser?.nickname &&
        !regex.test(linksUser.nickname || '')
      ) {
        store.dispatch(setShouldSave(false));
        toast.warning(
            <span style={{color: 'black'}}>{t('username_regex_error')}</span>,
        );
        return;
      }
      if (linksUser.avatar?.includes('base64')) {
        const file = dataURLtoFile(linksUser.avatar, `avatar-${linksUser.id}`);
        const params = {
          file: {
            key: 'avatar',
            value: file,
            fileName: linksUser.id,
          },
        };

        await uploadAvatarRequest.doRequest(params);
      }
      const payload = {
        bio: linksUser.bio,
        fullname: linksUser.fullname,
        location: linksUser.location,
        displayFollower: linksUser.displayFollower,
        city: linksUser?.city,
        contentCategoryIds: linksUser?.contentCategoryIds || [],
        categoryIds: linksUser?.categoryIds || [],
        phone: linksUser?.phone,
        gender: linksUser?.gender,
      };
      await updateProfileRequest.doRequest(payload);
      handleProfileEvent();
      handleSave();
      raiseToast({message: t('changes_saved')});
    } catch (error) {
      console.error('Error during saveEverything:', error);
      // Handle errors appropriately here
    } finally {
      dispatch(setShouldSave(false));
      dispatch(setDataChange(false));
      setValidationFinish(false);
      refreshMe();
      setValue(currentTab); // Restore active tab after save
    }
  }, [
    dispatch,
    t,
    value,
    linksUser,
    user,
    uploadAvatarRequest,
    updateProfileRequest,
    handleProfileEvent,
    raiseToast,
    setValidationFinish,
    refreshMe,
    setValue,
  ]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    localStorage.setItem('activeTab', newValue.toString());
  };

  useEffect(() => {
    if (updateProfileRequest.hasError) {
      toast.warning(
          <span style={{color: 'black'}}>{t('username_already_taken')}</span>,
      );
    }
  }, [updateProfileRequest.hasError]);

  return (
    <div className={styles.container}>
      <RouteLeavingGuard
        when={dataChange}
        navigate={() => '/profile/settings'}
        shouldBlockNavigation={() => true}
        saveFunction={async () => {
          const validationResult = await validateAllBeforeSave();
          if (validationResult) {
            await saveEverything();
            return true;
          }
          return false;
        }}
      />

      <WarningModal
        setIsOpen={setShowModal}
        open={showModal}
        contentHeadline={modalText}
        buttonText={modalButtonText}
      />
      <div className={styles.loadingOnSave}>
        {shouldSave && (
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        )}
      </div>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <div className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {SectionList.map((section, index) => {
                const label = section.name as string;
                return (
                  <Tab
                    key={`${section.key}-header`}
                    style={getStyle(index == value, width)}
                    label={label}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
            {SectionList.map((section, index) => {
              return (
                <div key={section.key} className={styles.section_container}>
                  {section.isVisible && (
                    <TabPanel
                      width={width}
                      value={value}
                      index={index}
                      key={`${section.key}-item`}
                      dir={isRtl(i18n.language) ? 'rtl' : 'ltr'}
                    >
                      <MyProfileItem
                        name={section.name as string}
                        id={section.id}
                        subtitle={section.subtitle}
                        content={section.content}
                        isAccordion={section.isAccordion}
                        isVisible={section.isVisible}
                        editableTitle={section.key === 'featuredVideos'}
                      />
                    </TabPanel>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.divider}></div>
        </div>
      </div>
      {value!=2 && <div className={styles.right}>
        <Suspense fallback={<div>Loading...</div>}>
          <MobilePreview showLink={width > 768} />
        </Suspense>
      </div>}
    </div>
  );
};

export default NewSettings;
