import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./CountrySelect.module.scss";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {CountryDropdown} from 'react-country-region-selector';
import Arrow from "../../assets/images/al_arrow.svg";


type Props = {};

const CountrySelect: React.FC<Props> = ()=> {
  const {t, i18n} = useTranslation();
  const linksUser = useSelector(selectLinksUser);

  const [selectedCountries, setSelectedCountries] = useState<string>(linksUser?.location || '');
  return (
    <div className={styles.content}>
      <CountryDropdown
          value={selectedCountries || ''}
          classes={`${styles.select}`}
          onChange={(val) => {
            setSelectedCountries(val);
            store.dispatch(setLinksUser({...linksUser, location: val ?? '', city: ''}));
            store.dispatch(setDataChange(true));
          }} />
          <img className={styles.arrow} src={Arrow} />
    </div>
  );
};

export default CountrySelect;
