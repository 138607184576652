import profileActive from '../assets/images/versionV2/Header/al_profile_active.svg';
import profileUnactive from '../assets/images/versionV2/Header/al_profile_inactive.svg';
import linksActive from '../assets/images/versionV2/Header/linksActive.svg';
import linksInactive from '../assets/images/versionV2/Header/linksInactive.svg';
import dashboardUnactive from '../assets/images/versionV2/Header/al_dashboard_inactive.svg';
import dashboardActive from '../assets/images/versionV2/Header/al_dashboard_active.svg';
import homeActive from '../assets/images/versionV2/Header/al_home_active.svg';
import homeInactive from '../assets/images/versionV2/Header/al_home_inactive.svg';
import networkInactive from '../assets/images/Header_routes_icons/al_network_inactive.svg';
import networkActive from '../assets/images/Header_routes_icons/al_network_active.svg';
import passwordInactive from '../assets/images/Earning_Route/al_password_inactive.svg';
import passwordActive from '../assets/images/Earning_Route/al_password_active.svg';
import dealsInactive from '../assets/images/versionV2/Header/dealsInactive.svg';
import dealsActive from '../assets/images/versionV2/Header/dealsActive.svg';


export const headerRoutes = [
  {
    key: 'home',
    route: '/profile/home',
    name: 'Home',
    label: '',
    icon: homeInactive,
    activeIcon: homeActive,
    activatedRoute: ['/profile/home', '/profile/profile-icons'],
  },
  {
    key: 'myprofile',
    route: '/profile/myprofile',
    name: 'Links',
    label: '',
    icon: linksInactive,
    activeIcon: linksActive,
    activatedRoute: [],
  },
  {
    key: 'deals',
    route: '/profile/deals',
    name: 'Deals',
    label: '',
    icon: dealsInactive,
    activeIcon: dealsActive,
  },
  // {
  //   key: 'analytics',
  //   route: '/profile/analytics',
  //   name: 'Analytics',
  //   label: '',
  //   icon: analyticsUnactive,
  //   activeIcon: analyticsActive,
  // },
  {
    key: 'requestdashboard',
    route: '/profile/request-dashboard',
    name: 'Dashboard',
    label: '',
    icon: dashboardUnactive,
    activeIcon: dashboardActive,
    activatedRoute: [],
  },
];


