import React from 'react';
import styles from './ViewDealDetailsModal.module.scss';
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/TimerOutlined';
import copy from 'copy-to-clipboard';
import {dealItem} from '../../features/stores/dealsSlicer';
import {toast} from 'react-toastify';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import {calculateTimeLeft, isArabic} from '../../features/util';
import {Tooltip} from 'antd';
import {dealType} from '../../constants/helper';

interface IViewDealDetailsModalProps {
  open: boolean;
  setIsOpen: (arg: boolean) => void;
  dealItem: dealItem;
  translate: (key: string) => string;
  theme: UserThemeState;
}

const ViewDealDetailsModal = ({open, setIsOpen, dealItem, translate, theme}: IViewDealDetailsModalProps) =>{
  const {t} = useTranslation();

  const handleCopy = (value: string) => {
    copy(value);
    toast(<span style={{color: 'black'}}>{translate('code_copied')}</span>, {autoClose: 3000});
  };

  const codeItems = dealItem?.userDealContents?.filter((item) => item.type == dealType.COUPON) || undefined;
  const collections = dealItem?.userDealContents?.filter((item) => item.type == dealType.COLLECTIONS) || undefined;

  const getLocalizedText = (theme: UserThemeState, value: number, unit: string) => {
    const language = theme.displayLocale || 'en';
    return isArabic(language) ? `${value} ${translate(unit)} متبقي` : `${value} ${translate(unit)}${value > 1 ? 's' : ''}`;
  };

  const expiry = (endDate: string) => {
    return endDate ? calculateTimeLeft(theme, endDate, getLocalizedText) : translate('limited_time_deal');
  };

  return (
    open ? 
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div className={styles.content_body}>
            <div className={styles.row_top}>
              <div className={styles.content_image}>
                <img src={dealItem?.logoUrl || dealItem?.deal?.logoUrl} />
              </div>
              <div className={styles.content}>
                <div className={styles.content_headline}>{dealItem?.deal?.name}</div>
              </div>
            </div>
            <div className={styles.row_middle}>
              {codeItems && codeItems.length > 0 && <div>
                <div className={styles.coupon}>{translate('coupon_code')}</div>
                {codeItems?.map((codeItem) => 
                  <div key={codeItem.id} className={styles.coupon_container}>
                    <div className={styles.custom_input}>
                      <p>{codeItem.content}</p>
                      <div className={styles.coupon_right}>
                      {codeItem.endDate && <Tooltip title={expiry(codeItem.endDate)}><TimerIcon htmlColor='orange'></TimerIcon></Tooltip>}
                      <div className={styles.button_wrapper} onClick={() => handleCopy(codeItem.content)}>{translate('copy')}</div>
                      </div>
                    </div>
                </div>)
                }
              </div>}
              <br></br>
              {collections && collections.length > 0 && <div>
                <div className={styles.coupon}>{translate(dealType.COLLECTIONS)}</div>
                {collections?.map((collection) => 
                  <div key={collection.id} className={styles.collection}>
                  <img src={collection.logoUrl || dealItem?.logoUrl}></img>
                  <a href={collection.content} rel="noreferrer" target="_blank">
                  <p><Tooltip title={collection?.description}><div className={styles.colection_description}>{collection?.description}</div></Tooltip></p>
                  </a>
                  </div>)
                }
              </div>}
            </div>
            <div className={styles.content_text}>
              <label className={styles.how_to}>{translate('how_it_works')}</label>
              <ul>
                <li>{translate('coupons_may_come')}</li>
                <li>{translate('to_avail_discount')}</li>
              </ul>             
            </div>
          </div>
        </div>
      </div>
    </div> : <></>
  );
};

export default ViewDealDetailsModal;
