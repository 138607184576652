import React, {useEffect} from 'react';
import classNames from 'classnames';
import styles from '../../style/global.module.scss';
// import logo from '../../assets/images/logoSvg.svg'; // replaced
import logo from '../../assets/images/alfan-logo.svg';
import HeaderImageV2 from '../../assets/images/mobiteli.svg'; // replaced
import spotifySvg from '../../assets/images/spotify.svg'; // replaced
import tiktok2 from '../../assets/images/tiktok2.svg'; // replaced
import instagramSvg2 from '../../assets/images/instagram2.svg'; // replaced
import facebookSvg from '../../assets/images/facebook.svg';// replaced
import snapchatSvg from '../../assets/images/snapchat.svg';// replaced
import deezerJpg from '../../assets/images/deezer.svg';// replaced
import youtubeSvg from '../../assets/images/youtube.svg';// replaced
import shopifySvg from '../../assets/images/shopify.svg';// replaced
import twitchSvg from '../../assets/images/twitch.svg';// replaced
import discordSvg from '../../assets/images/discord.svg';// replaced
import anghamiSvg from '../../assets/images/Anghami.svg';// replaced
import activitySvg from '../../assets/images/analytics-icon.svg'; // replaced
import macbookPro from '../../assets/images/MacBookPro.svg'; // replaced
import analytics from '../../assets/images/analytics.svg';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import ReactGA from 'react-ga';
import {Trans, useTranslation} from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import createTheme from '@material-ui/core/styles/createTheme';
import MetaTagWrapper from '../../components/MetaTagWrapper/MetaTagWrapper';
import {Helmet} from 'react-helmet';
import Hero from '../../assets/images/landing/hero.svg';
import Logo from "../../assets/images/landing/logo.svg";
import Vector from "../../assets/images/landing/Vector.svg";
import icon from "../../assets/images/landing/icon.svg";
import Line from "../../assets/images/landing/Line.svg";
import TopCreator from "../../assets/images/landing/top-creator-1.png";
import TopCreator2 from "../../assets/images/landing/top-creator-2.png";
import yaso from "../../assets/images/landing/yaso.png";
import TopCreator4 from "../../assets/images/landing/top-creator-4.png";
import karol from "../../assets/images/landing/karol.png";
import profile from "../../assets/images/landing/profile-1.png";
import newFollower from "../../assets/images/landing/new-followers.svg";
import commity from "../../assets/images/landing/community-img.svg";
import penIcon from "../../assets/images/landing/pen-icon.svg";
import gradientBlue from "../../assets/images/landing/gradient-blue.svg";
import gradientRed from "../../assets/images/landing/gradient-red.svg";
import makeItYours from "../../assets/images/landing/make-it-yours.svg";
import fullInsight from "../../assets/images/landing/full-insight.svg";
import fraction from "../../assets/images/landing/Fraction-icon.svg";
import askAnything from "../../assets/images/landing/ask-anything.png";
import promoteProduct from "../../assets/images/landing/promote-product.png";
import affiliate from "../../assets/images/landing/affiliate.png";
import bookCall from "../../assets/images/landing/book-call.png";
import './style.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Link, NavLink} from 'react-router-dom';
import {isArabic} from "../../features/util";
import Cookies from 'js-cookie';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 5,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};
const cn = classNames.bind(styles);

const LandingPage = () => {
  const {t, i18n} = useTranslation();
  const arabicFormat: boolean = isArabic(i18n.language);
  const handleCreateOwnLinkCTA = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryValue = searchParams?.get('redirect_via')?.toLowerCase();
    const queryUserID = searchParams?.get('link_user_id')?.toLowerCase();
    if (queryValue == 'create_link' && queryUserID?.length && window && window.clevertap) {
      window.clevertap.event.push("receive_click_create_your_link", {UUID: queryUserID});
    }
  };
  useEffect(() => {
    handleCreateOwnLinkCTA();
  });
  useEffect(() => {
    Cookies.remove('signup_clicked');
  }, []);
  return (
    <div className={arabicFormat ? 'siwa-font-ar' : 'lufga-font'}>
     <Helmet>
     <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
     <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossOrigin="anonymous"></script>
     </Helmet>
     <header>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="" />
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                <div className="d-flex">
                    <ul className="navbar-nav align-items-left align-items-md-center">
                        <li className="nav-item">
                          <LanguagePicker onChange={() => {}} newUi={true}></LanguagePicker>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/public/login">{t('signin')}</Link>
                        </li>
                        <li className="nav-item">
                          <Link onClick={() => Cookies.set('signup_clicked', '1', {expires: new Date(new Date().getTime() + 60 * 60 * 1000)})} className="nav-link" to="/public/signup"><button className="btn btn-outline-secondary" type="submit">{t('get_started')}</button></Link>
                        </li>
                      </ul>
                  
                </div>
              </div>
            </div>
          </nav>
    </div>
    </header>
    <div className="container">
      <section className="hero">
        <div className="row align-items-center">
            <div className={`col-md-12 col-lg-6 text-center ${i18n.language=='ar' ? 'text-md-end':'text-md-start'}`}>
                <div className="sub-title">
                  {t('best_tool_for_content_creator')} 
                </div>
                <div className={`title ${i18n.language}`}>
                    {t('your_public_profile_&_creator_store_all_in')} <span className='text-nowrap'> {t('one_link')}<img src={Vector} alt="" /></span>
                </div>
                <p className="pt-1">{t('increase_your_connection_with_your_fans_and_start_monetizing_today')}</p>
                <form action="" className="get-started" onClick={(e)=>e.preventDefault()}>
                    <div className={"d-flex align-items-center justify-content-center justify-content-md-start"}>
                        {/* <div className="col">
                          <div className="input-form-control">
                            <img src={icon} className="me-2" /> Alfan.link/  <input type="text" placeholder={t('your_name')} />
                        </div>
                      </div> */}
                        <div>
                        <Link to="/public/signup">
                          <button className={`btn btn-primary d-inline-flex align-items-center ${i18n.language}`}><div>{t('get_started_its_free')}</div> <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.5H15" stroke="white" strokeWidth="2" strokeLinecap='round' strokeLinejoin="round"/>
                            <path d="M12 1.5L16 5.5L12 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </button>
                        </Link>  
                        </div>
                    </div>
                     
                </form>
                
            </div>
            <div className="col-md-12 col-lg-6">
              <img src={Hero} alt="Best Link Share Platform" className="mt-n4 w-100" />
            </div>
        </div>
    </section>
    </div>

    <section className="join-community">
      <div className="container text-center">
        <div className="sub-title">
          {t('build_your_community')}
        </div>
        <div className="title">
         <Trans i18nKey="top_creators_who_use_alfan_link"> Top <span><i>creators</i></span> who use Alfan Link </Trans>
        </div>
        <p>{t('influencers_creators_artists_brands_use_Alfan_link_to_scale_their_business')}</p>
        <img src={Line} alt="Alfan Link" className="pattern-link" />
      </div>
      <div className="px-4 px-sm-0">
        <div className="top-creators">
          <div className="">
          <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3500} customTransition="all .8s ease">
            <div className="item">
              <a href="https://alfan.link/mmoshaya" target="_blank" rel="noopener noreferrer nofollow">
              <div className="top-creators-cards">
                  <div className="top-creators--img">
                    <img src={TopCreator} alt="" />
                  </div>
                  <div className="top-creators--title d-flex">
                    <h3>Moshaya Family</h3><div className="top-creators--type">
                      {t('creator')}
                    </div>
                  </div>
              </div>
              </a>
            </div>
            <div className="item">
              <a href="https://alfan.link/bigsam" target="_blank" rel="noopener noreferrer nofollow">
              <div className="top-creators-cards">
                  <div className="top-creators--img">
                    <img src={TopCreator2} alt="" />
                  </div>
                  <div className="top-creators--title d-flex">
                    <h3>Big Sam</h3><div className="top-creators--type">
                      {t('artist')}
                    </div>
                  </div>
              </div>
              </a>
            </div>
            <div className="item">
              <a href="https://alfan.link/yaso" target="_blank" rel="noopener noreferrer nofollow">
              <div className="top-creators-cards">
                  <div className="top-creators--img">
                    <img src={yaso} alt="" />
                  </div>
                  <div className="top-creators--title d-flex">
                    <h3>Yaso</h3><div className="top-creators--type">
                      {t('creator')}
                    </div>
                  </div>
              </div>
              </a>
            </div>
            <div className="item">
              <a href="https://alfan.link/klo" target="_blank" rel="noopener noreferrer nofollow">
              <div className="top-creators-cards">
                  <div className="top-creators--img">
                    <img src={TopCreator4} alt="" />
                  </div>
                  <div className="top-creators--title d-flex">
                    <h3>Khalood25</h3><div className="top-creators--type">
                      {t('gamer')}
                    </div>
                  </div>
              </div>
              </a>
            </div>
            <div className="item">
              <a href="https://alfan.link/karol" target="_blank" rel="noopener noreferrer nofollow">
              <div className="top-creators-cards">
                  <div className="top-creators--img">
                    <img src={karol} alt="" />
                  </div>
                  <div className="top-creators--title d-flex">
                    <h3>Karol</h3><div className="top-creators--type">
                      {t('creator')}
                    </div>
                  </div>
              </div>
              </a>
            </div>
            </Carousel>
        </div>
        </div>
      </div>
      <div className="text-center">
        <Link to="/public/signup">
        <button className={`btn btn-primary d-inline-flex align-items-center ${i18n.language}`}><div>{t('get_started_its_free')}</div> <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5.5H15" stroke="white" strokeWidth="2" strokeLinecap='round' strokeLinejoin="round"/>
          <path d="M12 1.5L16 5.5L12 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          </button>
        </Link>  
      </div>
    </section>
   
    <section className="your-community">
      <div className="container text-center mb-4">
        <div className="sub-title">
          {t('your_community')}
        </div>
        <div className={`title ${i18n.language}`}>
         <Trans i18nKey="your_official_public_profile_for_your_community_connect_grow_monetize"
         > Your official <span><i>public profile</i></span> for<div className="d-none d-md-inline-block" >&nbsp;</div> your community, connect,<div className="d-none d-md-inline-block" >&nbsp;</div> grow, monetize</Trans>
        </div>

      </div>

      <div className="container">
        <div className="public-profile">
          <div className="public-profile-img position-relative">
            <div className="profile-slider">
              <div className="profile-slide-box">
                <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={profile} className="d-block w-100" alt="..." />
                    </div>
                  </div>
                </div>
                <div className="new-followers">
                  <img src={newFollower} alt="New Followers" />
                </div>
              </div>
            </div>
            <img src={commity} alt="Public Profile" className="w-100" />
          </div>
        </div>
      </div>
      
    </section>

    <section className="make-it-yours">
      <div className="container">
        <div className="row">
          <div className="col-md-5 make-it-yours-box">
            <img src={penIcon} alt="" className="mb-4 pen" />
            <div className={`title mb-4 ${i18n.language}`}>
              <Trans i18nKey="make_it_yours,_with_unlimited_customizations" >Make it yours, with <span><i>unlimited</i></span> customizations</Trans>
            </div>
            <p>
              {t('with_premade_themes_the_ability_to_add_your_own_designs_and_10s_of_customizations_you_can_create_your_alfan_link_in_minutes')}
            </p>
            <div className={`alfan-link-com ${i18n.language}`}>
              <div className="alfan-link d-flex">
                <div>Alfan Link</div>
                <img className='alfanLink' src={gradientBlue} alt="" />
              </div>
              <div className="alfan-link d-flex mt-3">
                <div>Other Link</div>
                <img className='alfanOther' src={gradientRed} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <img src={makeItYours} alt="" className="w-100" />
          </div>
        </div>
        <div className="row full-insight">
          <div className="col-md-6">
            <img src={fullInsight} alt="" className="w-100" />
          </div>
          <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
            <img src={fraction} alt="" className="mb-4" />
            <div className={`title mb-4 ${i18n.language}`}>
              <Trans i18nKey='full_insights_to_see_what_your_community_loves'>   <span><i>Full insights</i></span> to see what your community loves</Trans>
            </div>
            <p>
              {t('share_your_pages_projects_directly_with_your_fans_Grow_your_community')}   
                     </p>
                     <Link to="/public/signup">
                     <button className={`btn btn-outline-secondary d-flex align-items-center ${i18n.language}`}>
                      <div>{t('get_started_its_free')}</div>  <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.5H15M12 1.5L16 5.5L12 9.5" stroke="#121212" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        
                     </button>
                     </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section className="make-money">
      <div className="container text-center">
        <div className="sub-title">
          {t('your_community')}   
        </div>
        <div className={`title ${i18n.language}`}>
          <Trans i18nKey='ways_to_make_money_with_alfan_link'>
            Ways to <span><i>make money</i></span> <div className="d-none d-md-block" >&nbsp;</div> with Alfan Link
          </Trans>
        </div>
        <div className={`make-money-card ${i18n.language}`}>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <img src={askAnything} alt={t('ask_me_anything')} />
                {t('ask_me_anything')}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card">
                <img src={promoteProduct} alt={t('I_will_promote_your_product_on_tiktok')} />
                {t('I_will_promote_your_product_on_tiktok')}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card">
                <img src={affiliate} alt={t('affiliate_links')} />
                {t('affiliate_links')}
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="card">
                <img src={bookCall} alt={t('book_1_1_call')} />
                {t('book_1_1_call')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer style={{padding: '0px'}}>
      <div className="footer-gradient">
        <div className="container">
          <div className="footer-get-started">
            <div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <a href="">
                    <img src={Logo} alt="Alfan Link" />
                  </a>
                </div>
                <div className="col-12 col-md-4 footer-label align-self-center">
                  <Trans i18nKey='Share_your_pages_&_projects_directly_with_your_fans_Grow_your_community'>
                    Share your pages & projects directly<div className="d-none d-md-inline-block" >&nbsp;</div> with your fans, Grow your community.
                  </Trans>
                </div>
                <div className="col-12 col-md-4 text-md-end">
                    <div className="d-inline-block">
                      <Link to="/public/signup">
                      <button className={`btn btn-primary d-flex align-items-center ${i18n.language}`}>
                      <div>{t('get_started_its_free')}</div><svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 5.5H15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M12 1.5L16 5.5L12 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          
                      </button>
                      </Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={`footer-link ${i18n.language}`}>
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <a href="">{t('support')}</a>
              <a href="https://app.al.fan/privacy-policy">{t('Privacy')}</a>
              <a href="https://app.al.fan/terms-and-condition">{t('TnC')}</a>
            </div>
            <div className={`col-md-6 ${i18n.language==='ar'? 'text-md-start': 'text-md-end'}`}>
              © 2022 Alfan Link, All
            </div>
          </div>
        </div>
      </div>
    </footer>
    </div>
  );
};


LandingPage.propTypes = {
};

export default LandingPage;
