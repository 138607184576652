import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Compressor from 'compressorjs';
import {useSelector} from "react-redux";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {blobToBase64, convertBase64ToBlob} from "../../Helper/imageCropper";
import ModalCrop from "../ModalCrop/ModalCrop";
import styles from "./AvatarDisplay.module.scss";
import defaultAvatar from '../../assets/images/al_default_avatar.png';
import EditImg from '../../assets/images/versionV2/Profile/profile_edit.png';
import CreateIcon from '@material-ui/icons/Create';

const selectUser = (state: any) => state.user.me.linksUser;

type AvatarProps = {
  onBoard?: boolean;
};

const AvatarDisplay = ({onBoard = false}: AvatarProps)=> {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const linksUser = useSelector(selectLinksUser);
  const user = useSelector(selectUser);

  const handleOpenChoseImage = () => {
    document.getElementById('avatarInput')?.click();
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]) as string;
      store.dispatch(setLinksUser({...linksUser, avatar: imageDataUrl}));
    }
    setIsOpen(true);
  };

  return (
      <>
      {isOpen && (
        <ModalCrop setIsOpen={setIsOpen} imgSrc={linksUser.avatar} setImageUrl={(image) => {
          if (image.includes('base64')) {
            new Compressor(convertBase64ToBlob(image), {
              quality: 0.8,
              async success(result) {
                const baseImage = await blobToBase64(result) as string;
                store.dispatch(setLinksUser({...linksUser, avatar: baseImage}));
                store.dispatch(setDataChange(true));
              },
              error(err) {
                console.log(err.message);
              },
            });
          } else {
            store.dispatch(setLinksUser({...linksUser, avatar: image}));
          }
        }}
        type={'profile'}
        handleCancelUpload={() => {
          store.dispatch(setLinksUser({...linksUser, avatar: user?.avatar?.url || defaultAvatar}));
        }}
        />
      )}
      {onBoard ? <div className={styles.avatar_wrapper_onboard} onClick={handleOpenChoseImage}>
          <div className={styles.image_container}>
            <img src={linksUser.avatar || defaultAvatar} className={styles.avatar} />
            <input
                id="avatarInput"
                style={{display: 'none'}}
                type='file'
                onChange={(e) => {
                    fileSelectedHandler(e);
                }}
            />
          </div>
          <div className={styles.edit_btn_wrapper}>
            <div><CreateIcon /></div>
            <div>{t('edit_photo')}</div>
          </div>
      </div> : <div
          className={styles.avatar_wrapper}
          onClick={handleOpenChoseImage}
          style={{
              backgroundImage: `url(${linksUser.avatar || defaultAvatar})`,
          }}
      >
          <img src={EditImg} className={styles.edit_img} />
          <input
              id="avatarInput"
              style={{display: 'none'}}
              type='file'
              onChange={(e) => {
                  fileSelectedHandler(e);
              }}
          />
      </div>}
      </>
  );
};

export default AvatarDisplay;
