import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {toast} from 'react-toastify';
import useRequest from '../../features/API/request';
import styles from '../../style/global.module.scss';
import {logIn, loginCognito, createUserSession, removeUserSession, socialAuth} from '../../features/Login/login';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrand.svg';
import passwordIconSvg from '../../assets/images/password-icon.svg';
import loginImage from '../../assets/images/login-bg-new.jpg';
import useInput from '../../hooks/useInput';
import classNames from 'classnames';
import loginStyle from './login.module.scss';
import {determineSafariCss, isRtl} from '../../features/util';
import {useTranslation} from 'react-i18next';
import facebookCircle from '../../assets/images/fbCircleLogo.svg';
import googleCircle from '../../assets/images/googleCircleLogo.svg';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import {Link} from 'react-router-dom';
import {removeStepCookie} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import Loader from 'react-loader-spinner';

const cn = classNames.bind(styles);

const isNotEmpty = (value: String) => value.trim() !== '';
const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Login = () => {
  const {t, i18n} = useTranslation();
  const isRTL: boolean = isRtl(i18n.language);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    validate: validateEmail,
  } = useInput(isEmail);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    validate: validatePassword,
  } = useInput(isNotEmpty);

  let formIsValid = false;
  if (emailIsValid && passwordIsValid) {
    formIsValid = true;
  }

  if (emailHasError || passwordHasError) {
    formIsValid = false;
  }

  const request = useRequest({method: 'POST', url: '/auth/login'});
  const doLogin = async () => {
    validateEmail();
    validatePassword();
    removeStepCookie();
    if (!formIsValid) {
      return;
    }
    setLoading(true);
    const credentials = {
      email: emailValue.toLowerCase(),
      password: passwordValue,
      rememberMe: false,
      termsChecked: true,
      origin: 'LINKS',
    };
    const result = await loginCognito(credentials);
    if (result.error) {
      setLoading(false);
      const splitData = result.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (result.error) {
        case 'UserNotConfirmedException':
          createUserSession({username: emailValue, creds: passwordValue});
          window.location.href='/public/confirm';
          break;
        case 'NotAuthorizedException':
          toast.error(<span style={{color: 'white'}}>{msg || result.message || t('something_went_wrong')}</span>);
          break;
        case 'UserNotFoundException':  
          toast.error(<span style={{color: 'white'}}>{msg || t('user_not_found')}</span>);
          break;
        default:
          console.log('no error matched');  
      }
    } else {
      const tokens: any = await Auth.currentSession();
      if (tokens && tokens.idToken) {
        resetEmail();
        resetPassword();
        const me = await logIn({Bearer: tokens.idToken.jwtToken});
        if (me.hasNickname) {
            localStorage.setItem('token', tokens.idToken.jwtToken);
            setTimeout(()=>{
              sessionStorage.clear();
            }, 300);
        } else {
          window.location.assign('/public/claim');
        }
        setLoading(false);
      }
    }
  };

  const emailClasses = emailHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const passwordClasses = passwordHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const isPasswordVisibleFieldType = isPasswordVisible ? 'text' : 'password';

  useEffect(() => {
    removeUserSession();
  }, []);

  return (
    <section className={styles['signin-section']}>
      {loading && <div className={loginStyle.loader}>
      <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
    </div>}
      <div className={styles['form-section']}>
        <Link to="/">
          <span>
            <img src={logo} alt="logo" style={{width: '93px'}}/>
          </span>
        </Link>


        <div className={styles['signin-form']}>
          <div className={styles['signin-form-inner']}>
            <h4>{t('signin')}</h4>
            <form>
              <div className={emailClasses}>
                  <TextFieldInput
                    label={t('email')}
                    customStyle={{padding: '27px 22px 10px'}}
                    staticHeader={true}
                    // style={determineSafariCss()}
                    type="text"
                    value={emailValue}
                    onChange={(e: any) => {
                      emailChangeHandler(e.target.value);
                    }}
                    onBlur={emailBlurHandler}
                    placeholder={t('email')}
                  />
                {emailHasError && (
                  <p className={styles['error-text']}>
                    {t('invalid_email_error')}
                  </p>
                )}
              </div>
              <div className={passwordClasses}>
                <div className={loginStyle.flex_relative}>
                  <TextFieldInput
                    label={t('pwd')}
                    customStyle={{padding: '27px 22px 10px'}}
                    staticHeader={true}
                    // style={determineSafariCss()}
                    type={isPasswordVisibleFieldType}
                    value={passwordValue}
                    onKeyUp={(e: any)=>{
                      if (e.keyCode === 13) {
                        doLogin();
                      }
                    }}
                    onChange={(e: any) => {
                      passwordChangeHandler(e.target.value);
                      setPasswordError('');
                    }}
                    onBlur={passwordBlurHandler}
                    placeholder={t('pwd')}
                  />
                  <img
                  style={isRTL ? {left: '20px', top: '30px'} :
                  {right: '20px', top: '30px'}}
                  src={passwordIconSvg}
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                  alt=""
                />
                </div>
                {passwordError && <p className={styles['error-text']}>{passwordError}</p>}
                {passwordHasError && !passwordError && (
                  <p className={styles['error-text']}>
                    {t('need_pwd')}
                  </p>
                )}
              </div>

              <div className={loginStyle.forgotPassword}>
                <a href='forgot'>{t('forgot_pwd')}</a>
              </div>
              <div className={styles['form-group']}>

                <button
                  type="button"
                  onClick={doLogin}
                  disabled={loading}
                  className={styles['main-btn']}
                >
                  {t('signin')}
                </button>
                <div className={loginStyle.socialLabelContainer}>
                  <div>{t('or_login_with')}</div>
                  <div className={loginStyle.line}></div>
                </div>
                <div className={loginStyle.socialLogoContainer}>
                  <div className={loginStyle.google} onClick={() => {
                    removeStepCookie();
                    socialAuth(CognitoHostedUIIdentityProvider.Google);
                  }}>
                    <img className={loginStyle.circleLogo} src={googleCircle} />
                    {t('continue_with_google')}
                  </div>
                  <div className={loginStyle.facebook} onClick={() => {
                    removeStepCookie();
                    socialAuth(CognitoHostedUIIdentityProvider.Facebook);
                  }}>
                    <img className={loginStyle.circleLogo} src={facebookCircle} />
                    {t('continue_with_facebook')}
                  </div>
                </div>
              </div>
            </form>
            <span>
              {t('no_account_yet')} <Link to="signup">{t('signup')}</Link>
            </span>
          </div>
          <LanguagePicker onChange={() => {}}/>
        </div>
        <p className={styles['footer']}>{t('claim_cr')}</p>
      </div>
      <div className={styles['form-img']}>
        <img src={loginImage} alt="" />
      </div>
    </section>
  );
};


export default Login;
