import React, {useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import styles from './Deals.module.scss';
import {store} from '../../app/store';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import DealItem from './DealItem';
import {useTranslation} from 'react-i18next';
import {dealsConst} from '../../constants/helper';
import {dealItem, getDealsStore, updateApprovedDeal} from '../../features/stores/dealsSlicer';
import Loader from 'react-loader-spinner';
import EditCollectionsModal from './EditCollectionsModal';
import {refreshMe} from '../../features/Login/login';

const Deals = () => {
  const {t} = useTranslation();
  const approvedDealItems = useSelector(getDealsStore)?.approvedDeals || [];
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<dealItem>();
  const [activeId, setActiveId] = useState('');
  const scrollableDivRef = useRef() as any;

  const handleVisibilityToggle = (id: string, visible: boolean) => {
    store.dispatch(updateApprovedDeal({id, data: {visible: !visible}}));
    store.dispatch(setDataChange(true));
  };

  const handleEditDealCollection = (deal: dealItem) => {
    setSelectedDeal(deal);
    setShowCollectionsModal(true);
  };

  return (
    <>
      {activeId && <div className={styles.loader}>
        <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
      </div>}
      <div className={styles.container}>
        {approvedDealItems.length > 0 && (
          <div className={styles.scrollable} ref={scrollableDivRef}>
            {approvedDealItems.map((item, index) => (
              <div key={item.id} className={styles.scrollableItem}>
                <DealItem
                  dealItem={item}
                  deal={item.deal}
                  handleDealApply={() => {}}
                  activeId={activeId}
                  status={dealsConst.APPROVED}
                  handleVisibilityToggle={handleVisibilityToggle}
                  handleEditDealCollection={() => handleEditDealCollection(item)}
                />
              </div>
            ))}
          </div>
        )}
        {showCollectionsModal && <EditCollectionsModal setIsOpen={setShowCollectionsModal} onSubmit={() => {
          refreshMe();
        }} open={true} dealItem={selectedDeal}></EditCollectionsModal>}
      </div>
    </>
  );
};

export default Deals;
