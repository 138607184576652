import React, {useEffect, useState} from 'react';
import styles from './ChargeCardModal.module.scss';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {useTranslation} from 'react-i18next';
import defaultAvatar from './../../assets/images/al_default_avatar.png';
import LockIcon from '@material-ui/icons/Lock';
import Loader from 'react-loader-spinner';

interface IProps{
    theme?: any;
    monetizeObj: any;
    linksUser: any;
    getHeading: any;
};
const Preview = ({theme, monetizeObj, linksUser, getHeading}: IProps) => {
  const {t} = useTranslation();
  const [imgLoaded, setImgLoaded] = useState(false);
  const profileAvatar = linksUser ? (linksUser.avatar?.url || linksUser.avatar || defaultAvatar) : (theme.avatar || defaultAvatar);

  return (
    <>
    {monetizeObj?.attachment?.thumbUrl && <div className={styles.img_wrapper}>
      <div className={styles.lock}>
        <div className={styles.lockBackground}>
          {imgLoaded ? <LockIcon fontSize='large' /> : <Loader type="TailSpin" color="#EB3B5A" height={30} width={30} />}
        </div>
      </div>
      <img className={styles.attachment} src={monetizeObj.attachment.thumbUrl} onLoad={() => setImgLoaded(true)} />
    </div>}
    <div className={styles.creator}>
      <div className={styles.left}>
        <img src={profileAvatar} />
      </div>
      <div className={styles.right}>
        <div>
          <strong>{linksUser.fullname}</strong>
        </div>
        <div className={styles.sell_heading}>{t(getHeading()?.text, {lng: theme.displayLocale || 'en'})}</div>
        {monetizeObj.note && <div className={styles.bio}>
          {monetizeObj.note} 
        </div>}
      </div>
    </div>
  </>
  );
};

export default Preview;
