/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React, {useRef, useEffect} from 'react';
import styles from './OnboardHeader.module.scss';
import logo from '../../assets/images/alfan-logo.svg';
import useWindowWidth from '../../hooks/useWindowWidth';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import {getPreferredLocale} from '../../features/util';

const OnboardHeader = ()=>{
  const mobile = useWindowWidth();
  const isMobileView = () => mobile < 625;

  const setLanguagePreferenceCT = () => {
    window.clevertap.profile.push({
      "Site": {
        "Language": getPreferredLocale(),
      },
    });
  };

  useEffect(() => {
    if (isMobileView()) {
      window.Trengo = window.Trengo || {};
      window.Trengo.render = false;
    }
  }, []);

  // useEffect(() => {
  //   if (isMobileView()) {
  //     window.FreshworksWidget('hide', 'launcher');
  //   }
  // }, []);

  return (
    <>
      {isMobileView() ? <div className={styles.copy_link}>
      <div className={styles.mobile_head_user}>
        <div>
            <object style={{pointerEvents: 'none'}} data={logo} className={styles.logo} type="image/svg+xml"></object>
        </div>
        <div>
        <LanguagePicker onChange={setLanguagePreferenceCT} newUi={true}></LanguagePicker>
        </div>
      </div>
      </div> : <div className={styles.desktop_onboard_picker}>
      <LanguagePicker onChange={setLanguagePreferenceCT} newUi={true}></LanguagePicker>
      </div>}
    </>);
};

export default OnboardHeader;
