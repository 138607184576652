import React from "react";
import PhoneInput, {PhoneInputProps as props} from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import "./PhoneNumber.scss";
type PhoneInputProps = props;

const PhoneNumber: React.FC<PhoneInputProps> = (props)=> {
  return (
    <div id="phone">
        <PhoneInput {...props} inputProps={{style: {width: '100%', paddingTop: '20px', paddingBottom: '20px', borderRadius: '30px', height: '56px'}}} />
    </div>
  );
};

export default PhoneNumber;
