import {socialMediaLinks} from '../constants/socialMedia';
import defaultIconBlack from '../assets/images/al_defaultLinkIconBlack.svg';
import defaultIconWhite from '../assets/images/al_defaultLinkIconWhite.svg';
import {envConfig} from './EnvironmentVariables/enviromentVariables';
import Cookies from 'js-cookie';
import moment from 'moment';
import {isProdSite} from '../Helper/generalHelper';

export const onChangeField = (e, setObject) => {
  setObject((prev) => ({
    ...prev,
    [e.target.name]: e.target.value,
  }));
};

export const parseLineChartLabels = (data)=>{
// 6daysAgo
  const newData = data.map((el)=>{
    if (el === '1daysAgo') return 'Yesterday';
    if (el === 'today') return 'Today';

    const characters = el.split('');

    let numberPart = '';
    const otherPart = '';

    characters.map((char, index)=>{
      if (Number(char)) numberPart += char;
    });

    let word = el;
    word = word.replace(numberPart, '');
    word = word.charAt(0).toUpperCase() + word.slice(1);

    // split at capital letter
    const words = word.split(/(?=[A-Z])/);

    // join everything
    let finalWord = numberPart + ' ';

    words.map((w, index)=>{
      finalWord += w;
      if (index !== words.length-1) finalWord += ' ';
    });

    return finalWord;
  });
  return newData;
};

export const isValidHttpUrl = (string) => {
  // if (/^(?:(http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/gi.test(string)) {
  if (/^(?:(https|http)?:\/\/)?(?=www\.)?(?!w{1,2}\.)(?!w{4,}\.)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
      .test(string)) {
    return true;
  } else {
    return false;
  }
};


export const extractVideoUrl = (link)=>{
  let linkItem = link;
  if (linkItem.includes('youtu.be')) {
    const lastIndex = linkItem.lastIndexOf('/');
    if (linkItem.includes('?')) {
      linkItem = linkItem.split('?')[0];
    }
    return linkItem.substring(lastIndex+1);
  }
  const validUrlBeginnings = ['https://'];
  if (!validUrlBeginnings.some((l)=>linkItem.startsWith(l))) {
    // append protocol
    linkItem = 'https://' + linkItem;
  }
  const url = new URL(linkItem);
  const videoId = url.searchParams.get('v');
  return videoId;
};


export const convertTime =(d) => {
  // ignore the "PT" part
  d = d.search(/PT/i) > -1? d.slice(2) : d;
  let h = 0; let m; let s;
  // indexes of the letters h, m, s in the duration
  const hIndex = d.search(/h/i);
  const mIndex = d.search(/m/i);
  const sIndex = d.search(/s/i);
  // is h, m, s inside the duration
  const dContainsH = hIndex > -1;
  const dContainsM = mIndex > -1;
  const dContainsS = sIndex > -1;
  // setting h, m, s
  h = dContainsH? d.slice(0, hIndex) + ':' : '';
  m = dContainsM? d.slice(dContainsH ? hIndex + 1 : 0, mIndex) : dContainsH? '0' : '0';
  s = dContainsS? d.slice(dContainsM ? mIndex + 1 : hIndex + 1, sIndex) : '0';
  // adding 0 before m or s
  s = (dContainsM || dContainsS) && s < 10? '0' + s: s;
  m = (dContainsH || dContainsM) && m < 10? '0' + m + ':' : m + ':';
  return d !== '0S' ? h + m + s : 'LIVE';
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


// this function is used to determine links icon with regard to the variant of the theme
export const determineLinkItemIcon = (linkItem, variant = 'default') => {
  const {customUploadedImage, type} = linkItem;

  if (customUploadedImage) {
    return customUploadedImage.url || customUploadedImage;
  }

  const defaultIcon = (()=> {
    switch (variant) {
      case 'white':
        return defaultIconWhite;
      case 'black':
        return defaultIconBlack;
      default:
        return defaultIconBlack;
    }
  })();

  if (type === 'other') {
    return defaultIcon;
  }

  const socialMediaLink = socialMediaLinks.find((e) => e.key === type);
  if (!socialMediaLink) {
    return defaultIcon;
  }

  switch (variant) {
    case 'white':
      return socialMediaLink.whiteIcon;
    case 'black':
      return socialMediaLink.blackIcon;
    default:
      return socialMediaLink.defaultIcon;
  }
};

export const getImagePath = ({path})=>{
  if (path?.includes('assets')) {
    return require('../'+path).default;
  }
  return path;
};

export const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;

export const determineSafariCss = ()=>{
  if (isSafari) {
    return {
      // width: '133.333333333%',
      fontSize: '14px',
      borderRadius: '11px',
      padding: '13px 19px 24px 1px',
      // transform: 'scale(0.75)',
      transformOrigin: 'left top',
      marginBottom: '-10px',
      // marginRight: '30%',
    };
  }
  return {};
};

export const setPreferredLocale = (locale) => {
  localStorage.setItem('preferred_locale', locale);
};

export const getPreferredLocale = () => {
  let preferredLanguage = 'en';
  if (localStorage.getItem('preferred_locale') !== null) {
    preferredLanguage = localStorage.getItem('preferred_locale');
  }
  return preferredLanguage;
};

export const isArabic = (language) => {
  return language === 'ar';
};

export const isRtl = (language) => {
  switch (language) {
    case 'ar':
      return true;
    case 'en':
      return false;
    default:
      return false;
  }
};

export const haveSegmentPermission = (config, parameter) => {
  if (config) {
    const index = config.findIndex((e) => e.name == parameter);
    if (index != -1) {
      const data = config[index].value;
      return Array.isArray(data) ? data : (data?.hasOwnProperty('enabled') ? data?.enabled : data);
    }
  }
  return false;
};

export const getPGFeePercentage = (config) => {
  if (config) {
    const pgFeeObject = haveSegmentPermission(config, 'paymentGatewayFee') || {};
    const feeItem = pgFeeObject.hasOwnProperty('Link') ? pgFeeObject['Link'].find((e) => e.paymentGateway == envConfig().activePaymentMethod.toLocaleUpperCase()) : {};
    return feeItem ? feeItem.processingChargesPercent : 0;
  }
  return 0;
};


export const getUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  if (utmSource) Cookies.set('utm_source', utmSource.toLowerCase(), {expires: 30});
  if (utmMedium) Cookies.set('utm_medium', utmMedium.toLowerCase(), {expires: 30});
};

// Function to send events to Google Analytics
export const sendEvent = (eventName) => {
  if (isProdSite) {
    const utmSource = Cookies.get('utm_source');
    const utmMedium = Cookies.get('utm_medium');

    window.gtag('event', eventName, {
      event_category: eventName,
      event_label: eventName,
      utm_source: utmSource || '',
      utm_medium: utmMedium || '',
    });
  }
};


// Function to complete signup process
export const completeSignup = () => {
  sendEvent('overall_signup_done');
  Cookies.remove('utm_source');
  Cookies.remove('utm_medium');
};

const setLocale = (language) => {
  if (isArabic(language)) {
    moment.locale('ar'); // Set the locale to Arabic
  } else {
    moment.locale('en'); // Set the locale to English (default or any other language)
  }
};

const calculateTimeDifference = (endDate) => {
  const now = moment();
  const target = moment(endDate);
  return moment.duration(target.diff(now));
};

export const calculateTimeLeft = (theme, endDate, getLocalizedText) => {
  setLocale(theme.displayLocale || 'en');

  const duration = calculateTimeDifference(endDate);

  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (months > 0) {
    return getLocalizedText(theme, months, 'month');
  } else if (days > 0) {
    return getLocalizedText(theme, days, 'day');
  } else if (hours > 0) {
    return getLocalizedText(theme, hours, 'hour');
  } else if (minutes > 0) {
    return getLocalizedText(theme, minutes, 'minute');
  } else {
    return getLocalizedText(theme, seconds, 'second');
  }
};
