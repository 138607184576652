import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import useRequest from '../../features/API/request';
import styles from '../../style/global.module.scss';
import {refreshMe, updateUserCognitoAttribute} from '../../features/Login/login';
import logo from '../../assets/images/AlfanBrand.svg';
import picBg from '../../assets/images/claimpage.jpg';
import nextPicBg from '../../assets/images/nextclaim.jpg';
import useInput from '../../hooks/useInput';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import useWindowWidth from '../../hooks/useWindowWidth';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {determineSafariCss, sendEvent} from '../../features/util';
import {decodeToken} from '../../features/Login/login';
import nicknameStyles from './ClaimNickname.module.scss';
import {completeSignupTitkokEvent} from '../../Helper/generalHelper';
import nonHookRequest from '../../features/API/nonHookRequest';
import {useTranslation} from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreatorProfile from '../../components/CreatorProfileV2/CreatorProfile';
import {useSelector} from 'react-redux';
import {selectLinksUser, setLinksUser} from '../../features/stores/linksUserSlicer';
import Loader from 'react-loader-spinner';

const cn = classNames.bind(styles);
const isNotEmpty = (value: String) => value.trim() !== '';

const ClaimNickname = () => {
  const me = selectMe(useAppSelector(store.getState)).me;
  const user = useSelector(selectMe).me;
  const linksUser = useSelector(selectLinksUser);
  const [nicknameClaimed, setNicknameClaimed] = useState(false);
  const {
    value: nicknameValue,
    isValid: nicknameIsValid,
    hasError: nicknameHasError,
    valueChangeHandler: nicknameChangeHandler,
    inputBlurHandler: nicknameBlurHandler,
    reset: resetNickname,
    validate: validateNickname,
  } = useInput(isNotEmpty, me?.linksUser?.nickname);
  const {t} = useTranslation();
  const formIsValid = nicknameIsValid && !nicknameHasError ? true : false;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});
  const request = useRequest({method: 'POST', url: '/user/nickname', session: true});
  const screenWidth = useWindowWidth();
  const setNickname = async () => {
    validateNickname();
    if (!formIsValid) {
      return;
    }
    const regex = new RegExp(/^[a-z0-9_.]{3,20}$/gm);
    if (!regex.test(nicknameValue)) {
      setError('Username should be 3 to 20 characters long and can only contain lowercase letters (a-z), numbers (0-9), underscore(_) and periods (.)');
      return;
    }

    const result = await request.doRequest({nickname: nicknameValue});
    if (result) {
      // refreshMe();
      // resetNickname();
      setNicknameClaimed(true);
      sendEvent('username_claimed');
      const token = localStorage.getItem('token');
      localStorage.setItem('claim_username', '1');
      if (!token) {
        const tokenFromSession = sessionStorage.getItem('token');
        if (tokenFromSession) {
          localStorage.setItem('token', tokenFromSession);
          sessionStorage.clear();
        }
      }
      // window.location.href = '/profile/onboard';
    } else {
      nicknameChangeHandler('');
      toast.warning(<span style={{color: 'black'}}>Username already taken.</span>);
    }
  };

  const nicknameClasses = (nicknameHasError || error) ? cn(styles['form-group'], styles['invalid']) : styles['form-group'];

  const claimUsernameStyle = ()=>{
    return {...determineSafariCss()};
  };

  const handleClevertapEvents = async (email: string, events: string[]) => {
    if (window?.clevertap) {
      window.clevertap.profile.push({
        'Site': {
          'Email': email,
        },
      });
      events.forEach((event) => {
        window.clevertap.event.push(event, {'UUID': email});
      });
    }
  };

  const handleSocialSignup = async (provider: string, events: string[]) => {
    sendEvent('email_verified');
    sendEvent(`signup_${provider}`);
    const userDecoded: any = await decodeToken();
    setTimeout(() => {
      if (userDecoded) {
        handleClevertapEvents(userDecoded.email, events);
      }
    });
  };

  const handleEvent = async () => {
    const Trafficads = Cookies.get('Trafficads');
    const SignupSubmit = Cookies.get('signup_submit');
    const utmSource = Cookies.get('utm_source');
    const verifyEmail = localStorage.getItem('verify_email');

    if (verifyEmail) {
      const userDecoded: any = await decodeToken();
      setTimeout(() => {
        if (userDecoded) {
          handleClevertapEvents(userDecoded.email, ['verify_email']);
          localStorage.removeItem('verify_email');
        }
      });
    }

    if (Trafficads) {
      setTimeout(() => {
        if (window?.fbq) {
          window.fbq('track', 'CompleteRegistration', {content_name: Trafficads, value: 0, currency: 'USD'});
          Cookies.remove('Trafficads');
        }
      });
    }

    if (SignupSubmit && window.ttq && utmSource === 'tiktok') {
      completeSignupTitkokEvent();
    }

    if (user?.socialSignupProvider) {
      const provider = user.socialSignupProvider.toLowerCase();
      handleSocialSignup(provider, ['verify_email', `signup_${provider}`]);
    } else {
      handleSocialSignup('manual', ['verify_email', `signup_manual`]);
    }
  };


  const handleReferral = async () => {
    if (Cookies.get('referrer')) {
      updateUserCognitoAttribute({'custom:referredBy': Cookies.get('referrer')});
      nonHookRequest({method: 'POST', url: '/user/referralSignUpSocial', body: {referralCode: Cookies.get('referrer')}}).then((response) => {
        Cookies.remove('referrer');
      });
    }
  };

  const handleProfileEvent = (eventName: string) => {
    if (window.clevertap) {
      const data = [];
      if (linksUser?.categoryIds?.length && eventName == 'onboard_personal_info') {
        data.push('categoryIds');
      }
      if (linksUser?.contentCategoryIds?.length && eventName == 'onboard_personal_info') {
        data.push('contentCategoryIds');
      }
      if (data.length) {
        window.clevertap.event.push(eventName, {'data_type': data?.length ? data.join() : undefined, 'UUID': me.email});
      }
      window.location.href = '/profile/onboard';
    }
  };

  const handleNext = async (removeData?: boolean) => {
    try {
      setLoading(true);
      const payload = {bio: linksUser?.bio, fullname: linksUser?.fullname, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || []};
      if (!removeData) {
        await updateProfileRequest.doRequest(payload);
        const result = await refreshMe(true);
        sendEvent('type_of_content_confirm');
        if (result) {
          handleProfileEvent('onboard_personal_info');
        }
      } else {
        store.dispatch(setLinksUser({...linksUser, bio: me?.linksUser?.bio, fullname: me?.linksUser?.fullname, contentCategoryIds: me?.userContentCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [], categoryIds: me?.userCategory?.map((e: {category: {id: string}}) => e?.category?.id) || []}));
        window.location.href = '/profile/onboard';
        sendEvent('type_of_content_skip');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me?.linksUser?.nickname) {
      setNicknameClaimed(true);
    }
  }, [me?.linksUser?.nickname]);

  useEffect(() => {
    handleEvent();
    handleReferral();
  }, []);

  return (
    <section className={nicknameStyles.claimSection}>
      {loading && <div className={nicknameStyles.loader}>
        <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
      </div>}
      <div className={nicknameStyles.boxes}>
        <div className={nicknameStyles.logoContainer}>
          <img src={logo} className={nicknameStyles.logo_img} />
        </div>
        <div className={nicknameStyles.formContainer}>
          <form className={nicknameStyles.form}>
            <div className={nicknameStyles.heading}>{t(nicknameClaimed ? 'help_us_personalize' : 'claim_your_unique_link')}</div>
            <div className={`${nicknameStyles.inputContainer} ${nicknameClaimed ? nicknameStyles.disabled : ''}`}>
              <label className={nicknameStyles.labelInInput}>alfan.link/</label>
              <input
                id='username'
                name='username'
                className={nicknameStyles.nicknameInput}
                style={{...claimUsernameStyle(), paddingLeft: '1px'}}
                type='text'
                value={nicknameValue || me?.linksUser?.nickname}
                onChange={(e) => {
                  nicknameChangeHandler(e.target.value?.toLowerCase());
                  if (error) setError('');
                }}
                placeholder={'username'}
                // required = {true}
                onBlur={nicknameBlurHandler}
                disabled={nicknameClaimed}
                onKeyPress={(e:any)=>{
                  if (e.code === 'Enter') {
                    e.preventDefault();
                    setNickname();
                  }
                }}
              />
              {/* <label htmlFor="username">alfan.link/</label> */}
              {!!nicknameClaimed && <CheckCircleIcon style={{color: '#35CF60'}} />}
            </div>
            {error && <p className={styles['error-text']} style={{color: '#EF3124', display: 'flex'}}>{error}</p>}
            {nicknameHasError && <p className={styles['error-text']} style={{color: '#EF3124', display: 'flex'}}>Please enter a valid username.</p>}
            {nicknameClaimed && <div className={nicknameStyles.creator_profile}>
              <CreatorProfile />
            </div>}
            {!nicknameClaimed &&
              <div>
                <button disabled={!nicknameValue?.length || loading} type="button" onClick={setNickname} className={nicknameStyles.submit}>Claim Username</button>
              </div>}
            {nicknameClaimed && <div>
              <button disabled={loading} type="button" onClick={() => handleNext()} className={nicknameStyles.submit}>{t('next')}</button>
              <div onClick={() => handleNext(true)} className={nicknameStyles.skip}>
                {t('skip')}
              </div>
            </div>}
          </form>
        </div>
      </div>
      <div className={`${nicknameStyles.boxes} ${nicknameStyles.right}`}>
        <img src={nicknameClaimed ? nextPicBg : picBg} />
      </div>
    </section>
  );
};


ClaimNickname.propTypes = {
};

export default ClaimNickname;
