import React, {useEffect, useState} from 'react';
import ImportantLinks from '../../components/ImportantLinks/ImportantLinks';
import MobilePreview from '../../components/MobilePreview/MobilePreview';
import MyProfileItem from '../../components/MyProfileItems/MyProfileItem';
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks';
import UserSettings from '../../components/UserSettings/UserSettings';
import styles from '../MyProfile/myProfile.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import FeaturedVideo from '../../components/FeaturedVideo/FeaturedVideo';
import MusicLinks from '../../components/MusicLinks/MusicLinks';
import {store} from '../../app/store';
import {setDataChange, setShouldSave} from '../../features/stores/changeDataSlicer';
import {selectMe, userSlice} from '../../features/stores/userSlicer';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import RouteLeavingGuard from '../../components/UnsavedChanges/RouteLeavingGuard';
import {toast} from 'react-toastify';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import useRequest, {useUploadRequest} from '../../features/API/request';
import {deleteLinksSectionItem, LinksSection, LinksSectionItem, selectLinksUser, updateSectionItem} from '../../features/stores/linksUserSlicer';
import {refreshMe, fetchMusicLinks} from '../../features/Login/login';
import {dataURLtoFile} from '../../Helper/imageCropper';
import Loader from 'react-loader-spinner';
import {extendUrl, socialMediaLinks} from '../../constants/socialMedia';
import WarningModal from '../../components/WarningModal/WarningModal';
import {isValidHttpUrl} from '../../features/util';
import useWindowWidth from '../../hooks/useWindowWidth';
import {event} from 'react-ga';
import {useAppSelector} from '../../app/hooks';
import {raiseToast} from '../../components/Toast/Toast';
import {useTranslation} from 'react-i18next';
import {makeStyles, Theme, useTheme, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {isRtl} from './../../features/util';
import nonHookRequest from '../../features/API/nonHookRequest';
import {populateApiDataSocial, removeStepCookie} from '../../Helper/generalHelper';
import {getMusicAlbums, MusicAlbumItem, SaveActionOperations} from '../../features/stores/musicAlbumsSlicer';
import {selectPreviewMode, setPreviewMode} from '../../features/stores/previewModeSlicer';

export interface StyleProps {
    width: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, width, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={width < 1024 ? 1 : 3} pr={width < 1024 ? 1 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const profileContent = (<UserSettings/>);
const socialMediaContent = (<SocialMediaLinks/>);
const linksContent = (<ImportantLinks/>);
const youtubeVideosContent = (<FeaturedVideo/>);
const musicLinksContent = (<MusicLinks/>);

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;
const selectUser = (state: any) => state.user.me;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: 'none',
    paddingTop: ({width}) => width < 1024 ? '0px' : '80px',
  },
  tabs: {
    'minHeight': '0px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      'padding': ({width}) => width < 768 ? '0px 0px 0px 0px' : '0 80px 0 80px',
      'column-gap': ({width}) => width < 768 ? '8px' : '10px',
      '& button': {
        'padding': ({width}) => width < 768 ? '6px 14px' : '8px 16px',
        'margin': '0 !important',
        '& .MuiTab-wrapper': {
          textTransform: 'none',
          fontWeight: 400,
        },
      },
    },
  },
}));

const tabStyle = {
  borderRadius: '32px',
  height: '35px',
  minHeight: '0',
  minWidth: 'auto',
  fontSize: '16px',
  margin: '0 10px 0 10px',
  background: '#8080801c',
  color: 'rgb(64 64 64)',
};

const activeStyle = {...tabStyle, ...{background: 'black', color: 'white'}};

const getStyle = (active: boolean, width: number) => {
  let styleProps = {};
  if (active) {
    styleProps = activeStyle;
  } else {
    styleProps = tabStyle;
  }
  return width < 768 ? {...styleProps, margin: '0 5px'} : styleProps;
};

const MyProfile = () => {
  const {t, i18n} = useTranslation();
  const dataChange = useSelector(selectChangeData);
  const shouldSave = useSelector(selectShouldSave);
  const linksUser = useSelector(selectLinksUser) as any;
  const dispatch = useDispatch();
  const previewMode = useSelector(selectPreviewMode);
  const {musicLinks: musicLinks, toBeDeletedMusicLinks: toBeDeletedMusicLinks} = useSelector(getMusicAlbums);
  const user = useSelector(selectUser);
  const [validationFinish, setValidationFinish] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [value, setValue] = React.useState(0);
  const width = useWindowWidth();
  const classes = useStyles({width});
  const theme = useSelector(selectUserTheme);

  const uploadLinksRequest = useRequest({
    method: 'POST',
    url: '/linksSectionItem/section-items',
  });
  const updateSectionDataRequest = useRequest({
    method: 'POST',
    url: '/linksSection/title',
  });
  const uploadMusicLinksRequest = useRequest({
    method: 'POST',
    url: '/albums',
  });
  const deleteMusicLinksRequest = useRequest({
    method: 'DELETE',
    url: '/albums',
  });
  const updateMusicLinksRequest = useRequest({
    method: 'PUT',
    url: '/albums',
  });

  const themeRequest = useRequest({url: '/linksUser/theme', method: 'POST'});
  const errorToast = (msg?: string) => toast(<span style={{color: 'red'}}>{msg}</span>, {autoClose: 3000});

  const videoSection = linksUser?.linksSections?.find((el:any)=>el.type === 'video');

  const videoSectionTitle = videoSection?.title;

  const SectionList = [
    {
      key: 'mylinks',
      name: t('mylinks'),
      subtitle: t('mylinks_subtitle'),
      isAccordion: false,
      content: linksContent,
      isVisible: true,
    },
    {
      key: 'musicLinks',
      name: t('music'),
      subtitle: t('musicLinks_subtitle'),
      isAccordion: false,
      content: musicLinksContent,
      isVisible: true,
    },
    {
      key: 'featuredVideos',
      name: t(videoSectionTitle),
      id: videoSection?.id,
      subtitle: t('featuredVideos_subtitle'),
      isAccordion: false,
      content: youtubeVideosContent,
      isVisible: true,
    },
  ];

  const validateUrl = (el: any) => {
    if (el?.price) {
      const amountOrUrlValid = (!!!el?.price[0]?.amount && (!el.url || !isValidHttpUrl(el.url)));
      return (amountOrUrlValid);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (uploadMusicLinksRequest.hasError && uploadMusicLinksRequest.error) {
      errorToast(t('error_uploading_music_links'));
    }
  }, [uploadMusicLinksRequest.hasError]);

  useEffect(() => {
    if (deleteMusicLinksRequest.hasError && deleteMusicLinksRequest.error) {
      errorToast(deleteMusicLinksRequest.error.message || t('something_went_wrong'));
    }
  }, [deleteMusicLinksRequest.hasError]);

  useEffect(() => {
    if (updateMusicLinksRequest.hasError && updateMusicLinksRequest.error) {
      errorToast(t('error_updating_music_links'));
    }
  }, [updateMusicLinksRequest.hasError]);

  useDidUpdateEffect(async () => {
    if (shouldSave) {
      validateAllBeforeSave();
    }
    if (shouldSave && validationFinish && dataChange) {
      saveEverything();
    }
  }, [shouldSave, validationFinish]);

  const validateAllBeforeSave = () => {
    setValidationFinish(false);
    let error = false;
    const myLinksSection = linksUser?.linksSections.find((e: LinksSection) => e.type === 'important_links');
    myLinksSection?.linksSectionItems.map((el: LinksSectionItem) => {
      if (el.hidden) {
        store.dispatch(deleteLinksSectionItem({sectionItemId: el.id}));
      } else if (!el.title) {
        setShowModal(true);
        setModalText(t('link_nickname'));
        setModalButtonText(t('valid_title_btn_text'));
        error = true;
        return;
      } else if (validateUrl(el)) {
        setShowModal(true);
        setModalText(t('invalid_url_error'));
        setModalButtonText(t('add_valid_link_btn_text'));
        error = true;
        return;
      }
    });
    linksUser?.linksSections.forEach((section: LinksSection) => {
      if (!section.title) {
        setShowModal(true);
        setModalText(t('links_empty_title_error'));
        setModalButtonText(t('valid_title_btn_text'));
        error = true;
        return;
      }
    });
    if (error) {
      // error has occured, need to stop the saving flow
      setValidationFinish(false);
      store.dispatch(setShouldSave(false));
      return false;
    }
    setValidationFinish(true);
    return true;
  };

  const handleProfileEvent = () => {
    if (window.clevertap) {
      const data = [];
      if (linksUser.avatar?.includes('base64') && !user.avatar) {
        data.push('profile_pic');
      }
      if (linksUser.bio && !user?.linksUser?.bio) {
        data.push('bio');
      }
      if (linksUser.fullname && !user?.linksUser?.fullname) {
        data.push('name');
      }
      if (linksUser.location && !user?.linksUser?.location) {
        data.push('country');
      }
      if (data.length) {
        window.clevertap.event.push('add_profile_data', {'data_type': data.join(), 'UUID': user.email});
      }
    }
  };

  const handleProfileLinkEvent = () => {
    if (window.clevertap) {
      const links: any = [];
      user?.linksUser?.linksSections.map((link: any) => {
        const linkType = link.type;
        const changeLink = linksUser?.linksSections.filter((e: any) => e.type == linkType) || [];
        if (changeLink.length && !link.linksSectionItems.length && changeLink[0].linksSectionItems.length>0) {
          links.push(linkType);
        }
      });
      if (links.length) {
        window.clevertap.event.push('add_profile_link', {'link_type': links.join(), 'UUID': user.email});
      }
    }
  };

  const saveEverything = async () => {
    await Promise.all(linksUser.linksSections.map((section: LinksSection) => {
      if (section.updateTitle) {
        return updateSectionDataRequest.doRequest({id: section.id, title: section.title, subtitle: section.subtitle});
      }
    }));

    const populateApiData = populateApiDataSocial(linksUser);
   
    await uploadLinksRequest.doRequest(populateApiData?.data);
    const toBeAddedMusicLinks = musicLinks.filter((element: MusicAlbumItem) => element.saveAction === SaveActionOperations.ADD)
        .map((({saveAction, id, ...rest}) => rest));
    const toBeUpdatedMusicLinks = musicLinks.filter((element: MusicAlbumItem) => element.saveAction === SaveActionOperations.UPDATE)
        .map((({saveAction, ...rest}) => rest));
    if (toBeDeletedMusicLinks.length > 0) {
      await deleteMusicLinksRequest.doRequest({'ids': toBeDeletedMusicLinks});
    }
    if (toBeAddedMusicLinks.length > 0) {
      await uploadMusicLinksRequest.doRequest({'albums': toBeAddedMusicLinks});
    }
    if (toBeUpdatedMusicLinks.length > 0) {
      await updateMusicLinksRequest.doRequest({'albums': toBeUpdatedMusicLinks});
    }
    handleProfileLinkEvent();
    raiseToast({message: t('changes_saved')});
    store.dispatch(setShouldSave(false));
    store.dispatch(setDataChange(false));
    setValidationFinish(false);
    refreshMe();
    fetchMusicLinks();
    return true;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  // const handleSave = () => {
  //   if (dataChange) {
  //     store.dispatch(setShouldSave(true));
  //   }
  // };

  useEffect(() => {
    if (linksUser?.fullname && linksUser?.location && linksUser?.bio) {
      handleChangeIndex(0);
    }
  }, []);

  useEffect(() => {
    dispatch(setPreviewMode(false));
  }, [dispatch]);


  return (
    <div className={styles.container}>
      {!previewMode && <RouteLeavingGuard
        when={dataChange}
        navigate={() => '/profile/myprofile'}
        shouldBlockNavigation={() => true} // Block navigation only when dataChange is true and previewMode is false
        saveFunction={async () => {
          const validationResult = await validateAllBeforeSave();
          if (validationResult) {
            await saveEverything();
            return true;
          }
          return false;
        }}
      />}
      <WarningModal setIsOpen={setShowModal} open={showModal} contentHeadline={modalText} buttonText={modalButtonText}/>
      <div className={styles.loadingOnSave}>{(shouldSave || uploadLinksRequest.isFetching) && <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />}</div>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <div className={classes.root}>
            {/* <AppBar position="static" color="default"> */}
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {SectionList.map((section, index) => {
                const label = section.name as string;
                return <Tab key={`${section.key}-header`} style={getStyle(index == value, width)} label={label} {...a11yProps(index)} />;
              })}
            </Tabs>
            {SectionList.map((section, index) => {
              return (
                <div key={section.key} className={styles.section_container}>
                  {section.isVisible &&
                <TabPanel width={width} value={value} index={index} key={`${section.key}-item`} dir={isRtl(i18n.language) ? 'rtl' : 'ltr'}>
                  <MyProfileItem
                    name={section.name as string}
                    id={section.id}
                    subtitle={section.subtitle}
                    content={section.content}
                    isAccordion={section.isAccordion}
                    isVisible={section.isVisible}
                    editableTitle={section.key === 'featuredVideos'}
                  />
                </TabPanel>
                  }
                </div>
              );
            })}
            {/* <div className={styles.save_container}>
       <button className={styles.button + ' ' + (!dataChange? styles.button_disabled : '')} onClick={handleSave}>{dataChange? t('save') : t('saved')}</button>
      </div>     */}
          </div>

          <div className={styles.divider}></div>
        </div>
      </div>
      <div className={styles.right}>
        <MobilePreview showLink={width > 768}/>
      </div>
    </div>
  );
};

export default MyProfile;
