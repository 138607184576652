import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import defaultAvatar from '../../../assets/images/al_default_avatar.png';
import defaultStyle from '../UserPublicProfile/UserPublicProfile.module.scss';
import desktopPreviewStyle from '../UserPublicProfile/UserPublicProfile_desktop_preview.module.scss';
import mobilePreviewStyle from '../UserPublicProfile/UserPublicProfile_mobile_preview.module.scss';
import {ReactComponent as LocationDynamic} from '../../../assets/images/al_location_new.svg';
import {
  dynamicSocials,
} from '../../../constants/socialMedia';
import PublicProfileSectionCard from '../../../components/PublicProfileSectionCard/PublicProfileSctionCard';
// import alfanLogoWhite from '../../../assets/images/alfan-link-logo-design-blue.svg';
import alfanLogoWhite from '../../../assets/images/alfan-logo.svg';
import ReactGa from 'react-ga';
import useWindowWidth from '../../../hooks/useWindowWidth';
import {ReactComponent as PlusIcon} from '../../../assets/images/iconmonstr-plus-5.svg';
import {CARD_STYLE, getShapeData, NULL_THEME, previewStyles} from '../../../constants/newThemes';
import {getImagePath, isArabic, isRtl} from '../../../features/util';
import nonHookRequest from '../../../features/API/nonHookRequest';
import {isSafari} from '../../../features/util';
import {numberFollowerFormat} from '../../../Helper/generalHelper';
import {useTranslation} from 'react-i18next';
const CleverTap = require('clevertap');
import ChargeCardModal from '../../../components/ChargeCardModal/ChargeCardModal';
import ChargeCardTapModal from '../../../components/ChargeCardTapModal/ChargeCardTapModal';
import {envConfig} from './../../../features/EnvironmentVariables/enviromentVariables';
import {MusicAlbumItem} from '../../../features/stores/musicAlbumsSlicer';
import {MusicLinkPreview} from '../../../components/MusicLinks/MusicLinkLineItem';
import AlbumDetails from '../../../components/MusicLinks/AlbumDetails';

interface IPublicUserProfile {
  linksUser: any;
  musicLinks: MusicAlbumItem[];
  selectedStyle?: string;
  themeProp?: any;
  useOnlyLinksUser?: boolean;
}

// const clevertap = CleverTap.init('487-47Z-K46Z');

const UserPublicProfile = ({
  linksUser,
  musicLinks,
  selectedStyle = 'default',
  themeProp,
}: IPublicUserProfile) => {
  const [openMonitizeModal, setOpenMonitizeModal] = useState(false);
  const [monitizeLinkID, setMonitizeLinkID] = useState('');
  const [clientSecret, setClientSecret] = useState<any>('');
  const [monetizeObj, setMonetizeObj] = useState<any>({type: '', title: '', note: '', attachment: '', amount: 0, url: '', maxPrice: 1000, minPrice: 1});
  const [activePaymentMethod, setActivePaymentMethod] = useState(envConfig().activePaymentMethod || 'stripe');
  const [selectedAlbum, setSelectedAlbum] = useState<MusicAlbumItem>();
  const [albumDetailsPopupVisibility, setAlbumDetailsPopupVisibility] = useState(false);
  const theme = themeProp === NULL_THEME ? linksUser : themeProp;
  const uniqueFollowerCount = linksUser?.displayFollower ? (numberFollowerFormat(linksUser?.followersCount || 0)) : 0;
  const mobile = useWindowWidth();
  const {t, i18n} = useTranslation();
  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale});
  };
  // const themeFont = isArabic(i18n.language) ? theme.arabicFont : theme.englishFont;
  const themeFont = theme.englishFont;
  const shapeData = getShapeData(theme.cardShape!);
  const nickname = linksUser.nickname;
  const copyLinkUrl = envConfig().copyLinkUrl;

  // the avatar ifologic needs to be changed for when the avatar becomes an URL instead of an object
  const profileAvatar = linksUser ? (linksUser.avatar?.url || linksUser.avatar || defaultAvatar) : (theme.avatar || defaultAvatar);
  let backgroundImage = '';
  let socialMediaIconsStyle = {};
  let socialMediaIconsColor = '';
  let headerBorderRadius = '';

  // if (window && window.clevertap && linksUser.userId) {
  //   window.clevertap.event.push('receive_profile_visit', {'profile_username': nickname});
  // }

  let styles: any = '';
  if (selectedStyle === previewStyles.DESKTOP_PREVIEW) {
    styles = desktopPreviewStyle;
    backgroundImage = getImagePath({path: theme.desktopImage});
    socialMediaIconsStyle = {transform: 'scale(0.85)', width: '8px'};
    socialMediaIconsColor = theme.desktopSocialMediaColor;
    headerBorderRadius = shapeData?.header.desktopPreviewBorderRadius!;
  }
  if (selectedStyle === previewStyles.MOBILE_PREVIEW) {
    styles = mobilePreviewStyle;
    socialMediaIconsStyle = {transform: 'scale(0.85)'};
    backgroundImage = getImagePath({path: theme.mobileImage});
    socialMediaIconsColor = theme.mobileSocialMediaColor;
    headerBorderRadius = shapeData?.header.mobilePreviewBorderRadius!;
  }
  if (selectedStyle === previewStyles.DEFAULT) {
    styles = defaultStyle;
    if (mobile < 768) {
      backgroundImage = getImagePath({path: theme.mobileImage});
      // socialMediaIconsStyle = {transform: 'scale(1.3)', width: '30px'};
      socialMediaIconsColor = theme.mobileSocialMediaColor;
      headerBorderRadius = shapeData?.header.mobileBorderRadius!;
    } else {
      backgroundImage = getImagePath({path: theme.desktopImage});
      socialMediaIconsColor = theme.desktopSocialMediaColor;
      headerBorderRadius = shapeData?.header.borderRadius!;
    }
  }

  // // SECTIONS
  const userLinksSections = linksUser.linksSections;

  // // SOCIAL MEDIA SECTION
  const userSocialMediaLinks = userLinksSections.find(
      (section: any) => section.type === 'social',
  )?.linksSectionItems.slice().sort((a:any, b:any) => a.orderNumber - b.orderNumber);

  const socials = dynamicSocials(socialMediaIconsColor);
  const socialMediaData = userSocialMediaLinks?.map((item:any, index:number)=>{
    const dynamicData = socials.filter((soc:any)=>soc.key === item.type);
    if (dynamicData.length) {
      return (<div key={'social-icon-link'+index}
        style={{...socialMediaIconsStyle, cursor: 'pointer'}}
        onClick={() => {
          const regexp = new RegExp(item.type, 'gm');
          if (item.url.match(regexp) && item.url.match(regexp).length>1) {
            const final = item.url.substr(item.url.lastIndexOf('/') + 1);
            item.url = 'https://' + item.type + '.com/' + final;
          }
          handleOpenLink(item.url, item.id);
        }}
      >
        {dynamicData[0].dynamic}
      </div>);
    }
  });

  // // IMPORTANT LINKS SECTION
  const userImportantLinks = userLinksSections.find(
      (section: any) => section.type === 'important_links',
  );

  const importantLinksData = userImportantLinks?.length !== 0 && (
    <PublicProfileSectionCard
      theme={theme}
      id={userImportantLinks?.id}
      isVisible={userImportantLinks?.isVisible}
      linksSectionItems={userImportantLinks?.linksSectionItems.filter((e: any) => e.isVisible)}
      title={userImportantLinks?.title}
      subtitle={userImportantLinks?.subtitle}
      type={userImportantLinks?.type}
      selectedStyle={selectedStyle}
      linksUserId={linksUser.id}
      ownerUserId={linksUser?.userId}
      key={'important_links' + userImportantLinks?.id}
      nickname={nickname}
      email={linksUser?.publicEmail}
      setMonitizeID={setMonitizeLinkID}
      setMonetizeObj={setMonetizeObj}
      maxDesktopContainerWidth={'270px'}
    />
  );

  // // VIDEO LINKS SECTION
  const userVideoLinks = userLinksSections.find(
      (section: any) => section.type === 'video',
  );

  const videoData =
    // userVideoLinks.isVisible &&
    userVideoLinks?.linksSectionItems?.length !== 0 && (
      <PublicProfileSectionCard
        theme={theme}
        id={userVideoLinks?.id}
        isVisible={userVideoLinks?.isVisible}
        linksSectionItems={userVideoLinks?.linksSectionItems}
        title={userVideoLinks?.title}
        subtitle={'See the latest video'}
        type={userVideoLinks?.type}
        selectedStyle={selectedStyle}
        linksUserId={linksUser.id}
        key={'video' + userVideoLinks?.id}
        email={linksUser?.publicEmail}
        setMonitizeID={setMonitizeLinkID}
        enableSubtitle={true}
        maxDesktopContainerWidth={'270px'}
      />
    );

  const visibleMusicLinks = musicLinks.filter((link) => link.visible);
  const musicLinksData = visibleMusicLinks.length > 0 ? (
      <div className={styles.musicLinks}>
        <p className={styles.title}
          style={{
            // fontFamily: isArabic(i18n.language) ? theme.arabicFont! : theme.englishFont!,
            fontFamily: theme.englishFont!,
            color: theme.nonCardTextColor!}}
        >{translate('music')}</p>
        {musicLinks.map((musicLinkItem: MusicAlbumItem) => musicLinkItem.visible ? (
          <MusicLinkPreview
            theme={theme}
            parentStyles={styles}
            selectedStyle={selectedStyle}
            album={musicLinkItem}
            onClick={() => {
              if (selectedStyle === previewStyles.DEFAULT) {
                setAlbumDetailsPopupVisibility(true);
                setSelectedAlbum(musicLinkItem);
              }
            }}
            baseUrl={`${copyLinkUrl}/${linksUser?.nickname?.toLowerCase()}`}
            linkCopySuccessText={translate('copy_link_success')}
          />
        ): <></>)}
      </div>
    ) : <></>;

  const handleLinkCount = async (itemId?: any) => {
    if (linksUser && linksUser.userId && itemId) {
      await nonHookRequest({method: 'POST', url: '/linkAnalytics/count', body: {userId: linksUser.userId, linkId: itemId, nickname: nickname}});
    }
  };

  // FUNCTIONS
  const handleOpenLink = async (item: any, itemId?: any) => {
    if (!!item?.isMonitize) {
      if (selectedStyle === previewStyles.DEFAULT) {
        setMonitizeLinkID(itemId);
        handleLinkCount(itemId);
      } else {
        toast(<span style={{color: 'black'}}>{'Not active from this section!'}</span>, {autoClose: 3000});
      }
      return;
    }
    if (item == 'redirect_to_landing_page') {
      window.location.href = `/?redirect_via=create_link&link_user_id=${linksUser?.userId}`;
    } else {
      window.open(item.includes('http') ? item : '//'+ item, '_blank');
    }
    handleLinkCount(itemId);
  };


  const Footer = ()=>{
    return (<div className={styles.footer}
    >
      <img src={alfanLogoWhite} className={styles.footerLogo} />
      <div
        className={styles.addLinkButton}
        style={{color: mobile < 768 && selectedStyle!==previewStyles.DESKTOP_PREVIEW ? theme.nonCardTextColor : 'white'}}
        onClick={() => handleOpenLink('redirect_to_landing_page')}
      >
        <div className={styles.plusIcon}>
          <PlusIcon fill={theme.cardColor?.includes('rgba') ? theme.cardColor?.slice(0, 18) + ')' : theme.cardColor} />
        </div>
        {translate('create_link')}
      </div>
    </div>);
  };

  const getTextAlignment = () => {
    return isRtl(theme.displayLocale) ? 'right' : 'left';
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientSecretParam = urlParams.get('payment_intent_client_secret') || urlParams.get('tap_id');
    const bookUrl = urlParams.get('bookUrl');
    if (urlParams.get('tap_id') || urlParams.get('token') || bookUrl) {
      const q = window.location.search.slice(1, window.location.search.length);
      const b = q.split('&');
      let c = '';
      for (let i=0; i < b.length; i++) {
        c = c + (b[i].includes('token') || b[i].includes('tap_id') || b[i].includes('bookUrl') ? '' : b[i]+'&');
      };
      const url = `${window.location.pathname}?${c}`;
      window.history.pushState({}, document.title, url);
    }
    if (clientSecretParam) {
      setMonetizeObj({...monetizeObj, url: bookUrl || '', type: urlParams.get('linkType') == 'shortCall' ? 'shortCall' : ''});
      setClientSecret(clientSecretParam);
      setMonitizeLinkID('');
      setOpenMonitizeModal(true);
    }
  }, []);

  useEffect(() => {
    if (monitizeLinkID) {
      setClientSecret('');
      setOpenMonitizeModal(true);
    }
  }, [monitizeLinkID]);


  return (
    <div
      dir={isRtl(theme.displayLocale) ? 'rtl': 'ltr'}
      className={styles.main}
      style={{
        backgroundColor: theme.backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        fontFamily: themeFont,
        // ...(!isSafari && {overflowY: 'scroll',
        //   scrollbarWidth: 'none',
        //   MsOverflowStyle: 'none'} ),
      }}>
      {openMonitizeModal && activePaymentMethod == 'stripe' ? <ChargeCardModal linksUser={linksUser} theme={theme} monetizeObj={monetizeObj} monitizeID={monitizeLinkID} setCientSecret={setClientSecret} clientSecret={clientSecret} handleClose={() => {
        setOpenMonitizeModal(false);
        setMonitizeLinkID('');
        setMonetizeObj({type: '', title: '', note: '', attachment: '', url: '', maxPrice: 1000, minPrice: 1});
      }} /> : null}
      {openMonitizeModal && activePaymentMethod == 'tap' ? <ChargeCardTapModal linksUser={linksUser} theme={theme} monetizeObj={monetizeObj} monitizeID={monitizeLinkID} clientSecret={clientSecret} userId={linksUser?.userId} handleClose={() => {
        setOpenMonitizeModal(false);
        setMonitizeLinkID('');
        setMonetizeObj({type: '', title: '', note: '', attachment: '', amount: 0, url: '', maxPrice: 1000, minPrice: 1});
      }} /> : null}
      {albumDetailsPopupVisibility && <AlbumDetails open={albumDetailsPopupVisibility}
        albumId={selectedAlbum?.id || ''}
        coverImageUrl={selectedAlbum?.coverImageUrl}
        audioUrl={selectedAlbum?.previewUrl}
        items={selectedAlbum?.albumLinks}
        playText={translate('play')}
        albumTitle={selectedAlbum?.title || ''}
        dir={isRtl(theme.displayLocale) ? 'rtl': 'ltr'}
        albumDescription={selectedAlbum?.description || ''}
        onClose={() => {
          setAlbumDetailsPopupVisibility(false);
        }} />}
      <div className={styles.container}
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat',
          ...(selectedStyle===previewStyles.MOBILE_PREVIEW && {WebkitBorderRadius: '23px'}),
        }}
      >
        <div className={styles.header_wrapper}>
          <div
            className={styles.header}
            style={{
            // backgroundColor: mobile <= 768 || selectedStyle === previewStyles.MOBILE_PREVIEW ? 'transparent' : theme.cardColor,
              backgroundColor: theme.cardColor,
              ...(mobile < 768 && {marginBottom: 0}),
              borderRadius: headerBorderRadius,
              ...(socialMediaData && socialMediaData.length === 0 && mobile < 768 && {paddingBottom: 0}),
              ...(mobile<= 768 && selectedStyle=== previewStyles.DESKTOP_PREVIEW && theme.cardStyle === CARD_STYLE.SOLID && {backgroundColor: theme.cardColor, borderRadius: '4px'}),
              ...(mobile<= 768 && selectedStyle=== previewStyles.DESKTOP_PREVIEW && theme.cardStyle === CARD_STYLE.OUTLINE && {border: '1px solid '}),
              ...((selectedStyle === previewStyles.DESKTOP_PREVIEW || (selectedStyle === previewStyles.DEFAULT && mobile > 768)) && {
                boxShadow: theme.boxShadow,
              }),

              border: '1px solid ' + theme.borderColor,
              ...(theme.themeName === '07' && {
                borderRadius: 0,
                border: '1px solid ' + theme.borderColor,
              }),
            }}
          >
            <div className={styles.user_wrapper}>
              <div className={styles.avatar_wrapper}>
                <img className={styles.avatar} src={profileAvatar} />
              </div>

              <div className={styles.username_location_wrapper}
                style={{
                  color: theme.cardTextColor,
                }}>
                <span
                  style={{fontFamily: themeFont, textAlign: getTextAlignment()}}
                  className={styles.username}>
                  {linksUser.fullname}
                </span>

                <div className={styles.location_followers_container}>
                  {uniqueFollowerCount != 0 ? <div className={styles.follower_wrapper}>
                    <span className={styles.follower}
                      style={{
                        fontFamily: themeFont,
                        color: theme.cardTextColor}}>
                      {/* {linksUser.location} */}
                      {uniqueFollowerCount} {translate('Followers')}
                    </span>
                  </div> : null}
                  {(linksUser.location || linksUser?.city) ? <div className={styles.location_container}>
                    <div className={styles.pin}>
                      <LocationDynamic className={styles.location_wrapper__location}
                        fill={mobile > 768 && selectedStyle !== previewStyles.MOBILE_PREVIEW ? theme.desktopSocialMediaColor : theme.mobileSocialMediaColor} />
                    </div>
                    <span className={styles.locations}
                      style={{
                        fontFamily: themeFont,
                        color: theme.cardTextColor}}>
                      {`${(linksUser?.city ? `${linksUser?.city}${linksUser?.location ? ',' : ''}` : '')} ${linksUser.location}`}
                    </span>
                  </div> : <div></div>}
                </div>


              </div>
            </div>

            <div
              className={styles.description_wrapper}
              style={{
                fontFamily: themeFont,
                ...(!linksUser.bio && {marginTop: 0}),
                color: theme.cardTextColor,
                textAlign: getTextAlignment(),
              }}>
              {linksUser.bio}
            </div>
            <div className={styles.social_media_wrapper}>
              <div>{socialMediaData}</div>
            </div>
          </div>
        </div>


        {importantLinksData}
        {musicLinksData}
        {videoData}

        <div className={styles.divider}></div>
        {(selectedStyle === previewStyles.DEFAULT || selectedStyle === previewStyles.DESKTOP_PREVIEW) &&
          <Footer></Footer>
        }
      </div>
    </div>
  );
};

export default UserPublicProfile;
