export type FeeOptions = {
  alfanPercentage?: number;
  pgFeePercentage?: number;
};

export type FeeType = 'alfanCut' | 'stripeCharge' | 'processingFee' | 'earn';

export const calculateFeeAmount = (type: FeeType, amount: number, options: FeeOptions) => {
  const {alfanPercentage = 0, pgFeePercentage = 0} = options;
  switch (type) {
    case 'alfanCut':
      return amount * (alfanPercentage / 100);
    case 'stripeCharge':
      return amount * 0.035;
    case 'processingFee':
      return amount * (pgFeePercentage / 100);
    case 'earn':
      return amount - amount * (alfanPercentage / 100) - amount * (pgFeePercentage / 100);
    default:
      return 0;
  }
};

export const getCurrencyConversion = (currencyMap: any, currency: string = 'usd') => {
  return Number(currencyMap?.filter((e: any) => e.currency == currency)[0]?.conversionFactor || 100);
};
