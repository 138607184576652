/* eslint-disable new-cap */
import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useAppSelector} from '../../app/hooks';
import {selectMe} from '../stores/userSlicer';
import ClaimNickname from '../../pages/ClaimNickname/claimNickname';
import Analytics from '../../pages/Analytics/analytics';
import Header from '../../components/Header/Header';
import MyProfile from '../../pages/MyProfile/MyProfile';
import Design from '../../pages/Design/DesignV2';
import ConfirmEmail from '../../pages/ConfirmEmail/ConfirmEmail';
import RequestDashboard from '../../pages/RequestDashboard/requestDashboard';
import AdvanceSettings from '../../pages/AdvanceSettings/AdvanceSettings';
import Onboarding from '../../pages/Onboarding/Onboarding';
import OnboardHeader from '../../components/OnboardHeader/OnboardHeader';
import Home from '../../pages/Home/Home';
import {logOut} from './login';
import DealsPage from '../../pages/DealsPage/DealsPage';
import ProfileIcons from '../../pages/ProfileIcons/ProfileIcons';
import layout from '../../style/private.module.scss';
import Preview from '../../components/Preview/Preview';
import {store} from '../../app/store';
import NewSettings from '../../pages/NewSettings/NewSettings';


const LoggedSwitch = () => {
  const mobile = useWindowWidth();
  const location = useLocation();
  const me = selectMe(useAppSelector(store.getState)).me;
  const headerHideRoutes = ['/profile/onboard', '/public/claim'];
  const [hideHeader, setHideHeader] = useState(headerHideRoutes.includes(window?.location.pathname) || false);


  useEffect(()=>{
    const path = window.location.pathname;
    if (mobile > 700) {
      // window.Trengo.panelHeader = true;
      // window.FreshworksWidget('show', 'launcher');
    }
  }, [mobile]);

  useEffect(() => {
    const path = window.location.pathname;
    const isPreviewRoute = path === '/profile/preview';

    // Hide header and onboarding header for the preview route
    if (isPreviewRoute) {
      setHideHeader(true);
    } else {
      // Reset hideHeader state for other routes
      setHideHeader(false);

      if (path === '/public/signup' || path === '/public/login') {
        if (me.hasNickname && me.confirmed) {
          setHideHeader(true);
          window.location.assign('/profile/home');
        }
      }
      if (path === '/public/confirm') {
        if (me.hasNickname && me.confirmed) {
          setHideHeader(true);
          window.location.assign('/profile/home');
        } else if (me.confirmed && !me.hasNickname) {
          setHideHeader(true);
          window.location.assign('/public/claim');
        }
      }
      if (path?.includes('/profile') && !me.hasNickname) {
        if (!me.confirmed) {
          setHideHeader(true);
          window.location.assign('/public/confirm');
        } else if (!me.hasNickname) {
          setHideHeader(true);
          window.location.assign('/public/claim');
        } else {
          logOut();
        }
      }
      if (headerHideRoutes.includes(window?.location?.pathname)) {
        setHideHeader(true);
      }
    }
  }, [window.location.pathname, me]);

  return (
    // !me.hasNickname || !me.confirmed ?
    // !me.hasNickname ?
    // <div>
    //   <Switch>
    //     <Route exact path='/public/confirm' component={ConfirmEmail} />
    //     <Route exact path="/public/claim" component={ClaimNickname} />
    //   </Switch>
    // </div>:
    <div style={{display: mobile < 890 ? 'unset' : 'flex'}}>
      <div style={{display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}>
        <div className={layout.main}>
          {!!!hideHeader && <div className={layout.header_container} style={{...(mobile<769 && {top: 0, zIndex: 100, backgroundColor: 'white'})}}>
            {!!me?.hasNickname && <Header/>}
          </div>}
          {hideHeader && mobile > 625 && <OnboardHeader />}
          {/* {hideHeader && <OnboardHeader />} */}
          <div className={`${layout.content_container} ${hideHeader ? layout.hide_header : ''}`}>
            {!me?.hasNickname ? <Switch>
              <Route exact path="/public/claim" component={ClaimNickname} />
              <Route path='/*'>
                <Redirect to={'/public/claim'}></Redirect>
              </Route>
            </Switch> : <Switch>
              <Route exact path="/profile/home" component={Home} />
              <Route exact path="/profile/myprofile" component={MyProfile} />
              <Route exact path="/profile/deals" component={DealsPage} />
              <Route exact path="/profile/preview" component={Preview} />
              <Route exact path="/profile/Design" component={Design} />
              {/* <Route exact path="/profile/analytics" component={Analytics} /> */}
              <Route exact path="/profile/request-dashboard" component={RequestDashboard} />
              <Route exact path="/profile/setting" component={NewSettings} />
              <Route exact path="/profile/settings/*" component={NewSettings} />
              <Route exact path="/profile/settings" component={NewSettings} />
              <Route exact path="/profile/onboard" component={Onboarding} />
              <Route exact path="/profile/profile-icons" component={ProfileIcons} />
              <Route exact path="/" component={Home} />
              {/* <Route exact path='/public/confirm' component={ConfirmEmail} /> */}
              <Route exact path="/public/claim" component={ClaimNickname} />
              <Route path='/*'>
                <Redirect to={!me.hasNickname ? '/public/claim' : '/profile/home'}></Redirect>
              </Route>
            </Switch>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedSwitch;
