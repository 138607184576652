import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import userReducer from '../features/stores/userSlicer';
import notificationReducer from '../features/stores/notificationSlicer';
import changeDataReducer from '../features/stores/changeDataSlicer';
import userThemeReducer from '../features/stores/userThemeSlicer';
import linksUserReducer from '../features/stores/linksUserSlicer';
import configSlicerReducer from '../features/stores/configSlicer';
import walletSlicerReducer from '../features/stores/walletSlicer';
import kycSlicerReducer from '../features/stores/kycSlicer';
import musicAlbumsReducer from '../features/stores/musicAlbumsSlicer';
import dealsReducer from '../features/stores/dealsSlicer';
import previewModeReducer from '../features/stores/previewModeSlicer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notifications: notificationReducer,
    changeData: changeDataReducer,
    userTheme: userThemeReducer,
    linksUser: linksUserReducer,
    config: configSlicerReducer,
    wallet: walletSlicerReducer,
    kyc: kycSlicerReducer,
    musicAlbums: musicAlbumsReducer,
    deals: dealsReducer,
    previewMode: previewModeReducer,

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
