import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./CreatorProfile.module.scss";
import {contentOptions, creatorTypeOptions, contentOptionsProd, creatorTypeOptionsProd} from "../../constants/helper";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {Checkbox} from "@material-ui/core";
import Entertainment from "../../assets/images/versionV2/Content/entertainment.svg";
import Business from "../../assets/images/versionV2/Content/Business.svg";
import Music from "../../assets/images/versionV2/Content/Music.svg";
import Photographer from "../../assets/images/versionV2/Content/photographer-2.svg";
import Sports from "../../assets/images/versionV2/Content/Sports.svg";
import FoodBloggers from "../../assets/images/versionV2/Content/FoodBloggers.svg";
import Lifestyle from "../../assets/images/versionV2/Content/Lifestyle.svg";
import Gaming from "../../assets/images/versionV2/Content/Gaming.svg";
import Family from "../../assets/images/versionV2/Content/Family.svg";
import Storyteller from "../../assets/images/versionV2/Content/Storyteller.svg";
import Religious from "../../assets/images/versionV2/Content/Religious.svg";
import Tvshow from "../../assets/images/versionV2/Content/tvshow.svg";
import Beauty from "../../assets/images/versionV2/Content/beauty.svg";
import Comedy from "../../assets/images/versionV2/Content/beauty.svg";
import Chef from "../../assets/images/versionV2/Profile/Chef.svg";
import Other from "../../assets/images/versionV2/Content/Other.svg";
import TextFieldInput from "../TextFieldInput/TextFieldInput";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import SelectGender from "../SelectGender/SelectGender";
import {isProdSite} from "../../Helper/generalHelper";
import {MAX_BIO_CHARACTERS} from '../../constants/helper';

const iconMap: any = {
  'Entertainment': Entertainment, 'Business': Business, 'Music': Music, 'Photographer': Photographer, 'Food': Chef, 'Sports': Sports, 'Food Bloggers': FoodBloggers,
  'Life style': Lifestyle, 'Gaming': Gaming, 'Family': Family, 'Story teller': Storyteller, 'Religious': Religious, 'Tv Channel | TV Show': Tvshow,
  'Beauty and Fashion': Beauty, 'Comedy': Comedy, 'Other': Other,
};

const CreatorProfile: React.FC = ()=> {
  const {t, i18n} = useTranslation();
  const isProd = isProdSite();
  const linksUser = useSelector(selectLinksUser);
  const showBioAndName = window.location.pathname !== '/profile/setting/personal-information' && window.location.pathname !== '/profile/settings' && window.location.pathname !== '/profile/settings/*';
  const creatorTypeOptionsLocal = isProd ? creatorTypeOptionsProd : creatorTypeOptions;
  const contentOptionsLocal = isProd ? contentOptionsProd : contentOptions;

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newBio = e.target.value;
    const truncatedBio = newBio.slice(0, MAX_BIO_CHARACTERS); // Truncate the text if it exceeds 200 characters
    store.dispatch(setLinksUser({...linksUser, bio: truncatedBio}));
    store.dispatch(setDataChange(true));
  };

  const handleSelect = (value: string, param: string) => {
    const dataCopy = JSON.parse(JSON.stringify(param == 'categoryIds' ? linksUser?.categoryIds : linksUser?.contentCategoryIds));
    store.dispatch(setLinksUser({...linksUser, [param]: dataCopy.includes(value) ? dataCopy.filter((e: string) => e != value) : [...dataCopy, value]}));
    store.dispatch(setDataChange(true));
  };

  return (
    <div className={showBioAndName ? '' : styles.profile_body}>
      <div>
        <div className={styles.heading}>{t('i_am')}</div>
        <div className={styles.i_am}>
          {creatorTypeOptionsLocal.map((e) => <div onClick={() => handleSelect(e?.id, 'categoryIds')} key={e?.id} className={`${styles.chip} ${linksUser?.categoryIds?.includes(e?.id) ? styles.active : null}`}>{e?.name}</div>)}
        </div>
      </div>
      <div className={styles.margin_28_top}>
        <div className={styles.heading}>{t('i_make_content_related_to')}</div>
        <div className={styles.content_realted}>
          {contentOptionsLocal.map((e) => <div onClick={() => handleSelect(e?.id, 'contentCategoryIds')} key={e?.id} className={`${styles.content_item} ${linksUser?.contentCategoryIds?.includes(e?.id) ? styles.active : null}`}>
            <div className={styles.content_text}>
              {iconMap[e?.name] ? <img className={styles.icon} src={iconMap[e?.name]} /> : null}
              {e?.name}
            </div>
            <div>
              <Checkbox style ={{color: linksUser?.contentCategoryIds?.includes(e?.id) ? '#6F65FF' : '#CED0D3', padding: '0'}} checked={linksUser?.contentCategoryIds?.includes(e?.id)} name="withdrawCheck" />
            </div>
          </div>)}
        </div>
      </div>
      {showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div className={styles.heading}>{t('display_name_label')}</div>
          <TextFieldInput
            placeholder={t('display_name_placeholder')}
            value={linksUser.fullname as string}
            customStyle={{height: '56px', padding: '0px 12px'}}
            onChange={(e: any) => {
              store.dispatch(setLinksUser({...linksUser, fullname: e.target.value}));
              store.dispatch(setDataChange(true));
            }}
          />
        </div>
        <div className={styles.margin_20_top}>
          <div className={styles.heading}>{t('bio')}</div>
          <div>
            <textarea
              className={styles.biography}
              rows={6}
              onChange={handleBioChange}
              value={linksUser.bio as string}
              maxLength={MAX_BIO_CHARACTERS} // Set the maxLength attribute for the textarea
              placeholder={t('bio_placeholder')}
            ></textarea>
            <p className={styles.characterCounter}>{`${(linksUser.bio || '').length}/${MAX_BIO_CHARACTERS}`}</p>
          </div>
        </div>
      </>}
      {!showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div className={`${styles.heading} ${styles.margin_12_bottom}`}>{t('your_phone_number')}</div>
          <div>
            <PhoneNumber value={linksUser?.phone} country={'ae'} onChange={(v, c: {dialCode: string}, e) => {
              const phone = v[0] == '+' ? v.replace(/ /g, '') : '+' + v.replace(/ /g, '');
              const dialCode = '+' + c?.dialCode;
              let value = '';
              if (phone.substring(0, dialCode?.length) == dialCode) {
                value = phone;
              } else {
                value = `${dialCode}${v}`;
              }
              store.dispatch(setLinksUser({...linksUser, phone: value}));
              store.dispatch(setDataChange(true));
            }} />
          </div></div>
        <div className={styles.margin_28_top}>
          <div className={`${styles.heading} ${styles.margin_12_bottom}`}>{t('gender')}</div>
          <div>
            <SelectGender value={linksUser?.gender || ''} handleChange={(e: string)=>{
              store.dispatch(setLinksUser({...linksUser, gender: e}));
              store.dispatch(setDataChange(true));
            }} />
          </div></div><br /></>}
    </div>
  );
};

export default CreatorProfile;
