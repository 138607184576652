import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {previewStyles} from '../../constants/newThemes';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import PublicProfileItemCard from '../PublicProfileItemCard/PublicProfileItemCard';
import defaultStyle from './publicProfileSectionCard.module.scss';
import desktopPreviewStyle from './publicProfileSectionCard_desktop-preview.module.scss';
import mobilePreviewStyle from './publicProfileSectionCard_mobile-preview.module.scss';
import {useTranslation} from 'react-i18next';
import {dealItem} from '../../features/stores/dealsSlicer';
import PublicProfileDeal from '../Deals/PublicProfileDeal';
import nonHookRequest from '../../features/API/nonHookRequest';

interface IPublicProfileSectionJsx {
  id: string;
  isVisible: boolean;
  linksSectionItems: LinksSectionItem[];
  title: string;
  subtitle: string
  type: string;
  theme: UserThemeState;
  selectedStyle: string;
  linksUserId: string;
  ownerUserId?: string;
  nickname?: string;
  email?: string;
  setMonitizeID: React.Dispatch<React.SetStateAction<any>>;
  setMonetizeObj?: React.Dispatch<React.SetStateAction<any>>;
  enableSubtitle?: boolean;
  maxDesktopContainerWidth: string;
  deals?: dealItem[];
}

const PublicProfileSectionCard = ({
  id,
  isVisible,
  linksSectionItems,
  title,
  subtitle,
  type,
  selectedStyle,
  linksUserId,
  theme,
  ownerUserId,
  nickname,
  email,
  setMonitizeID,
  setMonetizeObj,
  enableSubtitle,
  maxDesktopContainerWidth,
  deals,
}:IPublicProfileSectionJsx) => {
  const {t, i18n} = useTranslation();
  const [items, setItems] = useState(linksSectionItems) as any;
  const mobile = useWindowWidth();

  let styles: any = '';
  if (selectedStyle === previewStyles.DESKTOP_PREVIEW) styles = desktopPreviewStyle;
  if (selectedStyle === previewStyles.MOBILE_PREVIEW) styles = mobilePreviewStyle;
  if (selectedStyle === previewStyles.DEFAULT) styles = defaultStyle;


  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale || 'en'});
  };
  
  const fetchThumbnailData = async (array: any, setter:Function)=>{
    // function only to provide legacy supporty for videos in DB that dont have thumbnails saved
    let newArray = [] as any;
    newArray = await Promise.all(array.map(async (item: LinksSectionItem)=>{
      if (!item.videoThumbnail) {
        const videoUrl = `https://www.youtube.com/oembed?url=${item.url}`;
        const videoDetails = await axios.get(videoUrl);
        if (videoDetails.data) {
          return {
            ...item,
            videoThumbnail: videoDetails.data.thumbnail_url,
          };
        };
      } else return item;
    }));

    setter(newArray);
  };

  const handleDealClick = async (id: string) => {
    if (id) {
      await nonHookRequest({method: 'POST', url: '/user-deals-analytics', body: {userDealId: id}});
    }
  };

  useEffect(() => {
    const itemList = linksSectionItems?.slice().sort((a: any, b: any) => {
      if (type === 'video') {
        if (a.orderNumber > b.orderNumber) return 1;
        else return -1;
      } else {
        if (a.orderNumber > b.orderNumber) return 1;
        else return -1;
      }
    });

    if (type === 'video') fetchThumbnailData(itemList, setItems);

    else setItems(itemList);
  }, [linksSectionItems]);

  const width = useWindowWidth();

  return (
    <div className={styles.container}
      style={selectedStyle === previewStyles.DESKTOP_PREVIEW ? {
        maxWidth: maxDesktopContainerWidth,
      }: {}}
    >
      {
        !['important_links', 'deals', 'seasonal'].includes(type) &&
        <div className={styles.title_subtitle_wrapper}>
          <span className={styles.title}
            style={{
              // fontFamily: isArabic(i18n.language) ? theme.arabicFont! : theme.englishFont!,
              fontFamily: theme.englishFont!,
              color: theme.nonCardTextColor!}}
          >{translate(title)}</span>

          {(!['video', 'deals'].includes(type)) && <span className={styles.subtitle} style={{color: theme.nonCardTextColor!}}>{translate(subtitle)}</span>}
          {(type === 'video' && enableSubtitle) && <span className={styles.subtitle_enable} style={{color: theme.nonCardTextColor!}}>{translate(subtitle)}</span>}
        </div>
      }
      <div
        className={type === 'video' && (selectedStyle === 'mobile-preview' || mobile < 769) ? styles.video_wrapper : styles.links_wrapper}
        style={{...(type === 'video' && width<=768 && selectedStyle === 'desktop-preview' && {display: 'flex', gridGap: '4px', gap: '4px', justifyContent: 'space-between', flexWrap: 'wrap'})}}
      >
        {items?.map((item:any, index:number) => {
          return (
            <div className={styles.links} key={item.id}>
              <PublicProfileItemCard
                linksUserId={linksUserId}
                sectionType={type}
                selectedStyle={selectedStyle}
                theme={theme}
                index={index}
                item={item}
                ownerUserId={ownerUserId}
                nickname={nickname}
                email={email}
                setMonitizeID={setMonitizeID}
                setMonetizeObj={setMonetizeObj}
              />
            </div>
          );
        })}
      </div>
      {deals && deals.length > 0 && <div 
        className={styles.links_wrappers_deals}>
          {deals?.map((item: dealItem, index: number) => {
            return (
                  <div key={item.id} className={styles.scrollableItem} >
                    <PublicProfileDeal
                      selectedStyle={selectedStyle}
                      theme={theme}
                      dealItem={item}
                      handleDealClick={handleDealClick}
                    />
                  </div>
            );
          })}
      </div>}
    </div>
  );
};

export default PublicProfileSectionCard;
