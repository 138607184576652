import React, {useState, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {urlSafeDecode} from '@aws-amplify/core';
import useRequest from '../../features/API/request';
import styles from '../../style/global.module.scss';
import signupStyle from './signup.module.scss';
import {
  signUpCognito,
  createUserSession,
  removeUserSession,
  socialAuth,
} from '../../features/Login/login';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg'; // being replaced
import logo from '../../assets/images/AlfanBrand.svg';
import loginImage from '../../assets/images/login-bg-new.jpg';
import passwordIconSvg from '../../assets/images/password-icon.svg';
import passwordVisibleIconSvg from '../../assets/images/al-eye-open-red.svg';
import useInput from '../../hooks/useInput';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {determineSafariCss, isRtl} from '../../features/util';
import facebookCircle from '../../assets/images/fbCircleLogo.svg';
import googleCircle from '../../assets/images/googleCircleLogo.svg';
import {useTranslation, Trans} from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import {removeStepCookie} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {sendEvent} from '../../features/util';

const cn = classNames.bind(styles);

const isNotEmpty = (value: String) => value.trim() !== '';
const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const isPassword = (value: String) => {
  const rex = /^.{6,99}$/;
  return rex.test(String(value));
};

const Signup = () => {
  const {t, i18n} = useTranslation();
  const isRTL: boolean = isRtl(i18n.language);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [userHasAccount, setUserHasAccount] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [referrer, setReferrer] = useState('');
  const [loading, setLoading] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);

  // useEffect(() => {
  //   const temp = {
  //     email: emailValue,
  //   };
  //   axios.post('http://localhost:9000/api/v1/user/userExists', temp)
  //     .then((data) => {
  //       console.log(data);
  //     });
  // });
  useEffect(() => {
    removeUserSession();
    if (window && window.clevertap) {
      window.clevertap.event.push('open_signup_form');
    }
    const Trafficads = new URLSearchParams(window.location.search).get(
        'Trafficads',
    );
    if (Trafficads) {
      // cookie expire in 1 hour
      Cookies.set('Trafficads', Trafficads, {
        expires: new Date(new Date().getTime() + 60 * 60 * 1000),
      });
    }
    const ref = new URLSearchParams(window.location.search).get('ref');
    if (ref) {
      setReferrer(ref);
    }
  }, []);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    validate: validateEmail,
  } = useInput(isEmail);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    validate: validatePassword,
  } = useInput(isPassword);

  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: resetConfirmPassword,
    validate: validateConfirmPassword,
  } = useInput(isPassword);

  let formIsValid = false;
  if (emailIsValid && passwordIsValid && confirmPasswordIsValid) {
    formIsValid = true;
  }

  if (emailHasError || passwordHasError || confirmPasswordHasError) {
    formIsValid = false;
  }

  // const onGoogleLogin = async ()=>{
  //   const url = envConfig().baseUrl + `/auth/google-links`;
  //   window.location.href = url;
  // };

  const doRegister = async () => {
    if (passwordValue !== confirmPasswordValue) {
      resetPassword();
      resetConfirmPassword();
      // toast.warn(<span style={{color: 'black'}}>`{'Password and confirm password don\'t match!'}</span>);
      // alert('Password and confirm password don\'t match!');
      validatePassword();
      validateConfirmPassword();
      return;
    }
    validateEmail();
    validatePassword();
    validateConfirmPassword();

    if (!formIsValid) {
      return;
    }

    if (!termsOfService) {
      toast.error(t('accept_term_and_condition'));
      return;
    }

    setLoading(true);

    const registerCredentials = {
      email: emailValue.toLowerCase(),
      password: passwordValue,
      rememberMe: false,
      termsChecked: true,
      username: emailValue.toLowerCase(),
      referredBy: referrer,
    };

    const data: any = await signUpCognito(registerCredentials);
    if (data.error) {
      setLoading(false);
      const splitData = data.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (data.error) {
        case 'InvalidPasswordException':
          setPasswordError(msg || data.message);
          break;
        case 'UsernameExistsException':
          toast.error(msg || t('email_taken_error'));
          setUserHasAccount(true);
          break;
        case 'UserLambdaValidationException':
          toast.error(msg || t('email_taken_error'));
          setUserHasAccount(true);
          break;
        default:
          console.log('no match found');
      }
      return;
    }
    Cookies.set('signup_submit', '1', {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000),
    });
    if (!data.userConfirmed) {
      setLoading(false);
      localStorage.setItem('submit_signup_form', '1');
      createUserSession({
        username: data.user.username,
        creds: passwordValue,
        uuid: data.userSub,
      });
      window.location.href = '/public/confirm';
    }
  };
  const emailClasses = emailHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const passwordClasses = passwordHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const isPasswordVisibleFieldType = isPasswordVisible ? 'text' : 'password';
  const confirmPasswordClasses = confirmPasswordHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const isConfirmPasswordVisibleFieldType = isConfirmPasswordVisible ?
    'text' :
    'password';

  const handleEvents = () => {
    if (
      window.ttq &&
      Cookies.get('signup_clicked') &&
      Cookies.get('utm_source') == 'tiktok'
    ) {
      window.ttq.track('ClickButton', {
        value: 'NA', // number. Value of the order or items sold. Example: 100.
        currency: 'NA', // string. The 4217 currency code. Example: "USD".
        contents: [
          {
            content_id: 'NA', // string. ID of the product. Example: "1077218".
            content_type: 'NA', // string. Either product or product_group.
            content_name: 'NA', // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
      Cookies.remove('signup_clicked');
    } else {
      Cookies.remove('signup_clicked');
    }
  };

  useEffect(() => {
    handleEvents();
    sendEvent('signup_opened');
  }, []);

  const handleLoginEvent = () => {
    sendEvent('signup_page_login_click');
    if (window && window.clevertap) {
      window.clevertap.event.push('signup_page_login_click');
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles['globalLoader']}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div>
      ) : null}
      <section className={styles['signin-section']}>
        <div className={styles['form-section']}>
          <Link to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
          </Link>
          <div className={styles['signin-form']}>
            <div className={styles['signin-form-inner']}>
              <h4>{t('create_account')}</h4>
              <form>
                <div className={signupStyle['socialLogoContainer']}>
                  <div
                    className={signupStyle.google}
                    onClick={() => {
                      socialAuth(
                          CognitoHostedUIIdentityProvider.Google,
                          referrer,
                      );
                      removeStepCookie();
                    }}
                  >
                    <img
                      className={signupStyle['circleLogo']}
                      src={googleCircle}
                    />
                    {t('continue_with_google')}
                  </div>
                </div>

                <div className={signupStyle['socialLabelContainer']}>
                  <div>{t('or_signup_with')}</div>
                  <div className={signupStyle['line']}></div>
                </div>

                <div className={emailClasses}>
                  <TextFieldInput
                    className={styles.input_field}
                    onBlur={emailBlurHandler}
                    placeholder={t('email')}
                    onKeyUp={(e: any) => {
                      if (e.keyCode === 13) {
                        doRegister();
                      }
                    }}
                    label={t('your_email')}
                    staticHeader={true}
                    value={emailValue}
                    customStyle={{padding: '27px 22px 10px'}}
                    onChange={(e: any) => {
                      emailChangeHandler(e.target.value);
                      setUserHasAccount(false);
                    }}
                  />
                  {emailHasError && (
                    <p className={styles['error-text']}>
                      {t('invalid_email_error')}
                    </p>
                  )}
                </div>

                <div className={passwordClasses}>
                  <div className={signupStyle.flex_relative}>
                    <TextFieldInput
                      className={styles.input_field}
                      type={isPasswordVisibleFieldType}
                      value={passwordValue}
                      onChange={(e: any) => {
                        passwordChangeHandler(e.target.value);
                      }}
                      customStyle={{padding: '27px 22px 10px'}}
                      onBlur={passwordBlurHandler}
                      placeholder={t('pwd')}
                      label={t('your_password')}
                      staticHeader={true}
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          doRegister();
                        }
                      }}
                    />
                    <img
                      style={
                        passwordHasError && isRTL ?
                          {top: '30px', left: '20px'} :
                          isRTL ?
                          {left: '20px', top: '30px'} :
                          {right: '20px', top: '30px'}
                      }
                      src={
                        isPasswordVisible ?
                          passwordVisibleIconSvg :
                          passwordIconSvg
                      }
                      onClick={() => {
                        setIsPasswordVisible(!isPasswordVisible);
                      }}
                      alt=""
                    />
                  </div>
                  {passwordHasError && !confirmPasswordHasError && (
                    <p className={styles['error-text']}>
                      {t('password_create_error')}
                    </p>
                  )}
                </div>

                <div className={confirmPasswordClasses}>
                  <div className={signupStyle.flex_relative}>
                    <TextFieldInput
                      className={styles.input_field}
                      type={isConfirmPasswordVisibleFieldType}
                      value={confirmPasswordValue}
                      onChange={(e: any) => {
                        confirmPasswordChangeHandler(e.target.value);
                      }}
                      onBlur={confirmPasswordBlurHandler}
                      placeholder={t('confirm_pwd_placeholder')}
                      label={t('confirm_password')}
                      customStyle={{padding: '27px 22px 10px'}}
                      staticHeader={true}
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          doRegister();
                        }
                      }}
                    />
                    <img
                      style={
                        confirmPasswordHasError && isRTL ?
                          {top: '30px', left: '20px'} :
                          isRTL ?
                          {left: '20px', top: '30px'} :
                          {right: '20px', top: '30px'}
                      }
                      src={
                        isConfirmPasswordVisible ?
                          passwordVisibleIconSvg :
                          passwordIconSvg
                      }
                      onClick={() => {
                        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                      }}
                      alt=""
                    />
                  </div>
                  {confirmPasswordHasError && !passwordHasError && (
                    <p className={styles['error-text']}>{t('confirm_pwd')}</p>
                  )}
                  {confirmPasswordHasError && passwordHasError && (
                    <p className={styles['error-text']}>
                      {passwordError || t('confirm_password_error')}
                    </p>
                  )}
                </div>

                {userHasAccount && (
                  <p className={styles['error-text']}>
                    {t('account_exists')}{' '}
                    <a href="login" style={{color: '#0099ff'}}>
                      {t('want_to_login')}
                    </a>
                  </p>
                )}

                <div className={signupStyle['terms']}>
                  <div>
                    <Checkbox
                      checked={termsOfService}
                      onChange={() => setTermsOfService(!termsOfService)}
                      name="checkedAccept"
                    />
                  </div>
                  <div className={signupStyle['title']}>
                    {t('accept')} <a
                      href="https://app.al.fan/terms-and-condition"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('terms_and_conditions')}
                    </a> {t('and')} <a
                      href="https://app.al.fan/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('privacy_policy')}
                    </a>
                  </div>
                </div>

                <div className={styles['form-group']}>
                  <button
                    type="button"
                    onClick={doRegister}
                    className={styles['main-btn']}
                  >
                    {t('signup')}
                  </button>
                </div>
              </form>

              <span>
                {t('have_account_confirmation')}{' '}
                <Link to="login" onClick={handleLoginEvent}>
                  {t('login')}
                </Link>
              </span>
            </div>
            <LanguagePicker onChange={() => {}} />
          </div>

          <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
        </div>
        <div className={styles['form-img-new']}>
          <img src={loginImage} alt="" />
        </div>
      </section>
    </>
  );
};

Signup.propTypes = {};

export default Signup;
