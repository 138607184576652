import React from 'react';
import styles from './ReferralModal.module.scss';
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import CustomInput from '../CustomInput/CustomInput';
import {themeColor} from '../../constants/newThemes';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {toast} from 'react-toastify';
import copy from 'copy-to-clipboard';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import {ReactComponent as RewardIcon} from '../../assets/images/Certificate.svg';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {useSelector} from 'react-redux';

interface IModalProps {
  open: boolean;
  setIsOpen: (arg: boolean) => void;
}

const ReferralModal = ({open, setIsOpen}: IModalProps) =>{
  const {t} = useTranslation();
  const url = envConfig().copyLinkUrl;
  const linksUser = useSelector(selectLinksUser);
  const refUrl = `${url}/public/signup?ref=${linksUser?.nickname?.toLowerCase()}`;

  const isWebShareSupported = (): boolean => {
    return !!navigator.share;
  };

  const handleShare = async (url: string) => {
    const title = t('share_referral_description');
    try {
      await navigator.share({
        title: title,
        url: url,
      });
      toast(<span style={{color: 'black'}}>{t('referral_share_link_success')}</span>, {
        autoClose: 9000,
      });
      setIsOpen(false);
    } catch (error) {
      console.error('Error sharing:', error);
      setIsOpen(false);
    }
  };

  return (
    open ? 
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div className={styles.content_body}>
            <div className={styles.content_headline}>{t('refer_friend_title')}</div>
            <div className={styles.content_headline_subtext}>{t('refer_friend_desc')}</div>
            <Avatar variant="square" className={`${styles.referral_icon} ${styles.center}`}>
              <RewardIcon className={styles.reward_icon} />
            </Avatar>
            <CustomInput placeholder={'testvalue'}
              value={`${linksUser?.nickname?.toLowerCase()}`} onChange={(e) => {
              }} wrapperClassName={styles.custom_input}>

              <div className={styles.icon_wrapper} onClick={() => {
                copy(refUrl);
                toast(<span style={{color: 'black'}}>{t('referral_copy_link_success')}</span>, {
                  autoClose: 9000,
                });
              }}>
                <div><CopyIcon stroke={themeColor.background} /></div>
              </div>
            </CustomInput>
            {isWebShareSupported() && <div className={styles.button_wrapper} onClick={() => handleShare(refUrl)}>{t('share_invite_via_link')}</div>}
            <div className={styles.content_text}>
              <label className={styles.how_to}>{t('how_to_earn_rewards')}</label>
              <ol>
                <li>{t('refer_friend_title')}</li>
                <li>{t('ask_friend_ref_code')}</li>
                <li>{t('referral_eligibility')}</li>
              </ol>             
            </div>
          </div>
        </div>
      </div>
    </div> : <></>
  );
};

export default ReferralModal;
