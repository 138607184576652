import React, {useEffect, useState} from 'react';
import MobilePreview from '../../components/MobilePreview/MobilePreview';
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks';
import styles from './ProfileIcons.module.scss';
import {useSelector} from 'react-redux';
import {store} from '../../app/store';
import {setDataChange, setShouldSave} from '../../features/stores/changeDataSlicer';
import RouteLeavingGuard from '../../components/UnsavedChanges/RouteLeavingGuard';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import useRequest from '../../features/API/request';
import {LinksSection, LinksSectionItem, selectLinksUser, updateSectionItem} from '../../features/stores/linksUserSlicer';
import {refreshMe} from '../../features/Login/login';
import Loader from 'react-loader-spinner';
import {extendUrl} from '../../constants/socialMedia';
import WarningModal from '../../components/WarningModal/WarningModal';
import useWindowWidth from '../../hooks/useWindowWidth';
import {raiseToast} from '../../components/Toast/Toast';
import {useTranslation} from "react-i18next";
import {makeStyles, Theme} from '@material-ui/core/styles';
import {populateApiDataSocial} from '../../Helper/generalHelper';

export interface StyleProps {
    width: number;
}

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;
const selectUser = (state: any) => state.user.me;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: "none",
    paddingTop: ({width}) => width < 1024 ? '0px' : '80px',
  },
  tabs: {
    "minHeight": '0px',
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      "padding": ({width}) => width < 768 ? '0px 0px 0px 0px' : '0 80px 0 80px',
      "column-gap": ({width}) => width < 768 ? "8px" : "10px",
      "& button": {
        "padding": ({width}) => width < 768 ? "6px 14px" : '8px 16px',
        "margin": "0 !important",
        "& .MuiTab-wrapper": {
          textTransform: 'none',
          fontWeight: 400,
        },
      },
    },
  },
}));

const ProfileIcons = () => {
  const {t, i18n} = useTranslation();
  const dataChange = useSelector(selectChangeData);
  const shouldSave = useSelector(selectShouldSave);
  const linksUser = useSelector(selectLinksUser) as any;
  const user = useSelector(selectUser);
  const [validationFinish, setValidationFinish] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const width = useWindowWidth();
  const classes = useStyles({width});

  const uploadLinksRequest = useRequest({
    method: 'POST',
    url: '/linksSectionItem/section-items',
  });

  useDidUpdateEffect(async () => {
    if (shouldSave) {
      validateAllBeforeSave();
    }
    if (shouldSave && validationFinish && dataChange) {
      saveEverything();
    }
  }, [shouldSave, validationFinish]);

  const validateAllBeforeSave = () => {
    setValidationFinish(false);
    let error = false;
    const socialSection = linksUser?.linksSections.find((e: LinksSection) => e.type === 'social');
    socialSection?.linksSectionItems?.filter((e: any) => e?.url).map((el: LinksSectionItem) => {
      const linkType = el.type;
      // add prefix if only username/id is present
      const newLink = extendUrl({type: linkType, url: el.url});
      store.dispatch(updateSectionItem({id: el.id, data: {url: newLink}}));
    });
    linksUser?.linksSections.forEach((section: LinksSection) => {
      if (!section.title) {
        setShowModal(true);
        setModalText(t('links_empty_title_error'));
        setModalButtonText(t('valid_title_btn_text'));
        error = true;
        return;
      }
    });
    if (error) {
      // error has occured, need to stop the saving flow
      setValidationFinish(false);
      store.dispatch(setShouldSave(false));
      return false;
    }
    setValidationFinish(true);
    return true;
  };

  const handleProfileLinkEvent = () => {
    if (window.clevertap) {
      const links: any = [];
      user?.linksUser?.linksSections.map((link: any) => {
        const linkType = link.type;
        const changeLink = linksUser?.linksSections.filter((e: any) => e.type == linkType) || [];
        if (changeLink.length && !link.linksSectionItems.length && changeLink[0].linksSectionItems.length>0) {
          links.push(linkType);
        }
      });
      if (links.length) {
        window.clevertap.event.push('add_profile_link', {'link_type': links.join(), 'UUID': user.email});
      }
    }
  };
  
  const saveEverything = async () => {
    const populateApiData = populateApiDataSocial(linksUser);
    await uploadLinksRequest.doRequest(populateApiData?.data); 
    handleProfileLinkEvent();
    raiseToast({message: t('changes_saved')});
    store.dispatch(setShouldSave(false));
    store.dispatch(setDataChange(false));
    setValidationFinish(false);
    refreshMe();
    return true;
  };

  return (
    <div className={styles.container}>
      <RouteLeavingGuard when={dataChange} navigate={() => '/profile/home'}
        shouldBlockNavigation={() => true} saveFunction={async ()=>{
          const validationResult = await validateAllBeforeSave();
          if (validationResult) {
            await saveEverything();
            return true;
          }
          return false;
        }}/>
      <WarningModal setIsOpen={setShowModal} open={showModal} contentHeadline={modalText} buttonText={modalButtonText}/>
      <div className={styles.loadingOnSave}>{(shouldSave || uploadLinksRequest.isFetching) && <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />}</div>
      <div className={styles.left}>
      <div className={styles.profile_items_container}>
      <div className={classes.root}>
              <div className={styles.section_container}>
                <div className={`${styles.heading} ${styles.icon_title}`}>{t('add_your_socials')}</div>
                <SocialMediaLinks />
              </div>
    </div>
          
      <div className={styles.divider}></div>
      </div>
      </div>
      <div className={styles.right}>
        <MobilePreview showLink={width > 768}/>
      </div>
    </div>
  );
};

export default ProfileIcons;
