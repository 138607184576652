import React, {useMemo, useRef} from 'react';
import styles from './UploadButton.module.scss';
import {CircularProgress} from '@material-ui/core';
import DeleteButton from './DeleteButton';
import {toast} from 'react-toastify';
import {useUploadRequest} from '../../../features/API/request';

const MAX_SIZE = 10_000_000;

type Attachment = {
  id: string;
  url: string;
  name: string;
};
type UploadButtonProps = {
  contentType?: string;
  onUpload?: (uploadedFile: Attachment) => void;
  url?: string;
  label?: string;
  onDelete?: () => void;
  editable?: boolean;
};
const UploadButton = ({contentType, onUpload, url, label, onDelete, editable = true}: UploadButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});
  const loading = uploadFileRequest.isFetching;

  const accept = useMemo(() => {
    switch (contentType) {
      case 'picture':
        return 'image/*';
      case 'video':
        return 'video/*';
      case 'guide':
        return 'application/pdf';
      default:
        return '*';
    }
  }, [contentType]);

  const uploadFile = async (file: File) => {
    const body = {
      file: {
        key: 'file',
        value: file,
        fileName: file.name,
      },
    };

    try {
      const [uploadedFile] = await uploadFileRequest.doRequest(body);
      onUpload!(uploadedFile);
    } catch (e) {
      toast('Upload failed', {type: 'error'});
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      file.size > MAX_SIZE ? toast('File size should be less than 10MB', {type: 'error'}) : uploadFile(file);
    }
    inputRef.current!.value = '';
  };

  return (
    <div>
      <input ref={inputRef} type='file' accept={accept} onChange={handleChange} style={{display: 'none'}} />
      {url ? (
        <div className={styles.flexContainer}>
          <a className={styles.button} href={url} target='_blank' rel='noreferrer'>
            View {label}
          </a>
          {editable && <DeleteButton onClick={onDelete} />}
        </div>
      ) : (
        <button className={styles.button} disabled={loading} onClick={() => inputRef.current?.click()}>
          {loading ? <CircularProgress size='1rem' style={{color: '#7066FF'}} /> : `Upload ${label}`}
        </button>
      )}
    </div>
  );
};

export default UploadButton;
