import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import styles from './ChargeCardModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {CardForm} from './CardForm';
import nonHookRequest from '../../features/API/nonHookRequest';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {useTranslation, Trans} from 'react-i18next';
import {toast} from 'react-toastify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import defaultAvatar from './../../assets/images/al_default_avatar.png';
import CalendarBooking from '../CalendarBooking/CalendarBooking';
import {getCurrencyLabel} from '../../constants/currencies';
import Preview from './Preview';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import {isArabic} from '../../features/util';
import ar from 'react-phone-input-2/lang/ar.json';
import {Checkbox} from '@material-ui/core';

interface IProps{
    handleClose: () => void;
    clientSecret: any;
    setCientSecret: React.Dispatch<React.SetStateAction<any>>;
    monitizeID: string;
    theme?: any;
    monetizeObj: any;
    linksUser: any;
};
const stripePromise = loadStripe(envConfig().stripeKey as string);
const ChargeCardModal = ({handleClose, clientSecret, setCientSecret, monitizeID, theme, monetizeObj, linksUser}: IProps) => {
  const currencyLabel = getCurrencyLabel(monetizeObj.currency);
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(monetizeObj.note || monetizeObj?.attachment?.thumbUrl ? 'preview' : 'form');
  const [visitorData, setVisitorData] = useState<any>({name: '', email: '', notes: '', tipJarAmount: 5, isCustom: false, phone: '', countryCode: ''});
  const [visitorDataError, setVisitorDataError] = useState({name: '', email: '', tipJarAmount: '', phone: '', countryCode: ''});
  const [title, setTitle] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const profileAvatar = linksUser ? (linksUser.avatar?.url || linksUser.avatar || defaultAvatar) : (theme.avatar || defaultAvatar);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const locale = theme.displayLocale || 'en';
  const amountLabel = t('amount', {lng: locale});
  const minAmountLabel = t('tip_amount_cant_be_less_than', {lng: locale});
  const minPrice = currencyLabel === '$' ? `${currencyLabel}${monetizeObj.minPrice}` : `${monetizeObj.minPrice} ${currencyLabel}`;

  const getAmountOptions = (minPrice: number) => {
    const options = [];
    const start = Math.ceil(minPrice / 5) * 5;
    for (let i = start; i <= monetizeObj.maxPrice && options.length < 3; i += 5) {
      options.push(i);
    }
    return options;
  };

  const createPaymentIntent = async () => {
    try {
      setLoading(true);
      const intentResponse = await nonHookRequest({method: 'POST', url: '/finance/api/stripe/paymentIntent', body: {linkId: monitizeID, currency: monetizeObj?.currency, amount: monetizeObj?.type == 'tipJar' ? (visitorData.tipJarAmount * 100) : undefined, productType: 'Link'}, isShortUrl: true});
      if (intentResponse?.clientSecret) {
        setCientSecret(intentResponse?.clientSecret);
      } else if (intentResponse?.name == 'Error') {
        toast(<span style={{color: 'black'}}>{intentResponse.message}</span>, {autoClose: 3000});
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      console.log('something went wrong!');
      setLoading(false);
    }
  };

  const handleChangeInput = (value: any, param: string) => {
    if (!value && param != 'notes') {
      if (param == 'tipJarAmount' && monetizeObj.type != 'tipJar') {
        return;
      }
      setVisitorDataError({...visitorDataError, [param]: t('this_field_is_required', {lng: theme.displayLocale || 'en'})});
    } else {
      setVisitorDataError({...visitorDataError, [param]: ''});
    }
    setVisitorData({...visitorData, [param]: param == 'tipJarAmount' ? parseInt(value) : value});
  };

  const validateData = () => {
    let valid = true;
    let obj = JSON.parse(JSON.stringify(visitorDataError));
    if (!visitorData.name) {
      obj = {...obj, name: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (!visitorData.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(visitorData.email)) {
      obj = {...obj, email: t('not_a_valid_email', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (monetizeObj.type == 'tipJar' && (!visitorData?.tipJarAmount || (visitorData?.tipJarAmount < monetizeObj.minPrice) || (visitorData?.tipJarAmount > monetizeObj.maxPrice))) {
      obj = {...obj, tipJarAmount: visitorData?.tipJarAmount > monetizeObj.maxPrice ? `${t('tip_amount_cant_be_more_than')} ${currencyLabel} ${monetizeObj.maxPrice}` : `${t('tip_amount_cant_be_less_than')} ${currencyLabel} ${monetizeObj.minPrice}`};
      valid = false;
    }
    if (!visitorData.phone) {
      obj = {...obj, phone: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (!visitorData.countryCode) {
      obj = {...obj, countryCode: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    setVisitorDataError(obj);
    return valid;
  };

  const getHeading = () => {
    switch (monetizeObj.type) {
      case 'videoMsg':
        return {text: 'how_can_i_help', notes: 'whats_your_question'};
      case 'tipJar':
        return {text: 'thankyou_for_your_support', notes: 'something_you_would_like_to_tell_us'};
      case 'socialMediaPromote':
        return {text: `Brand promotion on ${monetizeObj.title.split(' ')[monetizeObj.title.split(' ').length - 1]}`, notes: 'what_would_you_like_to_promote'};
      case 'sellProduct':
        return {text: monetizeObj.title, notes: 'something_you_would_like_to_tell_us'};
      case 'shortCall':
        return {text: title || 'book_one_one_call', notes: 'please_share_anything_that_will_help_prepare_for_our_meeting'};
      default:
        return {text: title || 'card_payment', notes: 'notes'};
    }
  };

  const isRefundable = (monetizationType: string) => {
    return monetizationType == 'socialMediaPromote';
  };

  const handlePhoneChange = (phone:any, country:any) => {
    const countryDial = country.dialCode;
    setVisitorData({...visitorData, phone: phone, countryCode: countryDial});
  };

  useEffect(() => {
    // api hit for intent
    if (clientSecret) {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('linkType') || '';
      if (type == 'shortCall' && !monitizeID) {
        const name = urlParams.get('name') || '';
        const email = urlParams.get('email') || '';
        const notes = urlParams.get('notes') || '';
        setVisitorData({...visitorData, name: name, email: email, notes: notes});
      }
      setStep('card');
    }
  }, [clientSecret]);

  useEffect(() => {
    if (step && step == 'bookingSlot') {
      toast(<span style={{color: 'black'}}>{t('your_payment_is_successfully_done', {lng: theme.displayLocale || 'en'})}</span>, {autoClose: 3000});
    }
  }, [step]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
            {step == 'card' && monitizeID && <div className={styles.back} onClick={() => setStep('form')}><ArrowBackIcon /></div>}
            <h4 style={{marginBottom: '0px', textAlign: 'center'}}>
              {step == 'preview' ? '' : t(step == 'card' && clientSecret ? 'card_payment' : getHeading()?.text, {lng: theme.displayLocale || 'en'})}
            </h4>
            <div className={styles.content_body}>
              {['form', 'preview'].includes(step) && !loading && <form>
                {
                  step == 'preview' ? (
                    <>
                      <Preview theme={theme} monetizeObj={monetizeObj} linksUser={linksUser} getHeading={getHeading} />
                    </>
                  ) : (
                    <>
                      <div className={styles.row}>
                        {monetizeObj.type == 'tipJar' && <>
                          <div className={styles.label} style={{marginBottom: '6px'}}>
                            {amountLabel}
                            <span className={styles.description} style={{paddingLeft: '3px'}}>
                              ({minAmountLabel} {minPrice})
                            </span>
                          </div>
                        <div className={`${styles.row} ${styles.tipjarBox}`}>
                            {getAmountOptions(monetizeObj.minPrice).map((amount: number, index: number) => (
                              <div key={index} onClick={() => setVisitorData({...visitorData, tipJarAmount: amount, isCustom: false})} className={visitorData.tipJarAmount == amount && !visitorData.isCustom ? styles.activeBox : ''}>{currencyLabel} {amount}</div>
                            ))}
                          <div onClick={() => setVisitorData({...visitorData, tipJarAmount: 20, isCustom: true})} className={visitorData.isCustom ? styles.activeBox : ''}>Custom ({currencyLabel})</div>
                        </div>
                        {visitorData.isCustom && <div className={styles.row} style={{marginTop: '10px'}}>
                          {/* <div className={styles.label}>{t('amount')}</div> */}
                          <div className={styles.input}>
                            <input type="number" className={visitorDataError.tipJarAmount ? styles.input_error : ''} value={visitorData.tipJarAmount} onChange={(e: any) => handleChangeInput(e.target.value, 'tipJarAmount')} />
                          </div>
                          {visitorDataError?.tipJarAmount && <div className={styles.error}>{t(visitorDataError?.tipJarAmount)}</div>}
                        </div>}
                        </>}
                        <div className={styles.label}>{t('full_name', {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <input type="text" className={visitorDataError.name ? styles.input_error : ''} value={visitorData.name} onChange={(e: any) => handleChangeInput(e.target.value, 'name')} />
                        </div>
                        {visitorDataError?.name && <div className={styles.error}>{t(visitorDataError?.name)}</div>}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.label}>{t('email', {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <input type="email" className={visitorDataError.email ? styles.input_error : ''} value={visitorData.email} onChange={(e: any) => handleChangeInput(e.target.value, 'email')} />
                        </div>
                        {visitorDataError?.email && <div className={styles.error}>{t(visitorDataError?.email)}</div>}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.label}>{t('mobileNumber', {lng: theme.displayLocale || 'en'})}</div>
                        <PhoneNumber
                          localization={ isArabic(i18n.language) ? ar : undefined}
                          placeholder={t('mobileNumber')}
                          value={visitorData.phone}
                          onChange={(v, c)=> handlePhoneChange(v, c)}
                          specialLabel='' country={'ae'}
                          inputClass={styles.phoneInput}
                          inputProps={{name: 'phone', required: true, id: 'phone'}}/>
                        {(visitorDataError?.phone || visitorDataError?.countryCode) && <div className={styles.error}>{t(visitorDataError?.phone)}</div>}
                        {/* </div> */}
                      </div>
                      <div className={styles.row} style={{marginTop: '15px'}}>
                        <div className={styles.label}>{t(getHeading()?.notes, {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <textarea value={visitorData.notes} onChange={(e: any) => handleChangeInput(e.target.value, 'notes')} style={{height: '100px'}} />
                        </div>
                      </div>
                      <div className={styles.terms}>
                        <div>
                        <Checkbox checked={termsOfService} onChange={() => setTermsOfService(!termsOfService)} name="checkedAccept" />
                        </div>
                        <div className={styles.title}>
                          <div>
                            <Trans i18nKey='term_and_conditions'>
                              Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
                {isRefundable(monetizeObj.type) && <><br /><div className={styles.refund_policy}>{t('refund_policy')}</div></>}
                <button type="button" onClick={(e: any) => {
                  if (step == 'preview') {
                    setStep('form');
                  } else if (step == 'form') {
                    const isValid = validateData();
                    isValid ? createPaymentIntent() : '';
                  }
                }} className={styles.button} disabled={loading || (step == 'form' && (!!!visitorData.name.length || !!!visitorData.email.length || !!!visitorData.phone.length || !!!visitorData.countryCode.length || !!!termsOfService))} id="submit">
                  <span className={styles.button_text}>
                    {loading ? <div className={styles.spinner}></div> : (['form', 'preview'].includes(step) ? t('next') : t('pay_now', {lng: theme.displayLocale || 'en'}))}
                  </span>
                </button>
              </form>}
              {step == 'card' && clientSecret && (
                <Elements options={options as StripeElementsOptions} stripe={stripePromise}>
                  <CardForm theme={theme} visitorData={visitorData} monetizeObj={monetizeObj} monitizeID={monitizeID} setTitle={setTitle}
                  // onPaymentIntentReady={() => setLoading(false)}
                    step={step} setStep={setStep} />
                </Elements>
              )}
              {step == 'bookingSlot' && <CalendarBooking url={`${monetizeObj.url}?hide_gdpr_banner=1&name=${visitorData.name}&email=${visitorData.email}&notes=${visitorData.notes}`} />}
              {loading && <div className={styles.loader}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div>}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ChargeCardModal;
