import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {Trans, useTranslation} from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {GoSell} from '@tap-payments/gosell';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {isArabic} from '../../features/util';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import ar from 'react-phone-input-2/lang/ar.json';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import nonHookRequest from '../../features/API/nonHookRequest';
import {toast} from 'react-toastify';
import CalendarBooking from '../CalendarBooking/CalendarBooking';
import {getCurrencyLabel} from '../../constants/currencies';
import Preview from '../ChargeCardModal/Preview';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import {Checkbox} from '@material-ui/core';

interface IProps{
    handleClose: () => void;
    clientSecret: any;
    // setCientSecret: React.Dispatch<React.SetStateAction<any>>;
    monitizeID: string;
    theme?: any;
    monetizeObj: any;
    userId?: any;
    linksUser: any;
};
const mIDMap: any = {
  usd: 'tapUsdMID',
  aed: 'tapAedMID',
  sar: 'tapSarMID',
};
// const stripePromise = loadStripe(envConfig().stripeKey as string);
const ChargeCardTapModal = ({handleClose, monitizeID, theme, monetizeObj, clientSecret, userId, linksUser}: IProps) => {
  const currencyLabel = getCurrencyLabel(monetizeObj.currency);
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(monetizeObj.note || monetizeObj?.attachment?.thumbUrl ? 'preview' : 'form');
  const [visitorData, setVisitorData] = useState<any>({name: '', email: '', notes: '', tipJarAmount: 5, isCustom: false, phone: '', countryCode: ''});
  const [visitorDataError, setVisitorDataError] = useState({name: '', email: '', tipJarAmount: '', phone: '', countryCode: ''});
  const [title, setTitle] = useState('');
  const [tapStatus, setTapStatus] = useState('');
  const [tapMsg, setTapMsg] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('linkType') || '';
  const envConfigObj: any = envConfig();

  const locale = theme.displayLocale || 'en';
  const amountLabel = t('amount', {lng: locale});
  const minAmountLabel = t('tip_amount_cant_be_less_than', {lng: locale});
  const minPrice = currencyLabel === '$' ? `${currencyLabel}${monetizeObj.minPrice}` : `${monetizeObj.minPrice} ${currencyLabel}`;

  const getAmountOptions = (minPrice: number) => {
    const options = [];
    const start = Math.ceil(minPrice / 5) * 5;
    for (let i = start; i <= monetizeObj.maxPrice && options.length < 3; i += 5) {
      options.push(i);
    }
    return options;
  };

  const handleChangeInput = (value: any, param: string) => {
    if (!value && param != 'notes') {
      if (param == 'tipJarAmount' && monetizeObj.type != 'tipJar') {
        return;
      }
      setVisitorDataError({...visitorDataError, [param]: t('this_field_is_required', {lng: theme.displayLocale || 'en'})});
    } else {
      setVisitorDataError({...visitorDataError, [param]: ''});
    }
    setVisitorData({...visitorData, [param]: param == 'tipJarAmount' ? parseInt(value) : value});
  };

  const validateData = () => {
    let valid = true;
    let obj = JSON.parse(JSON.stringify(visitorDataError));
    if (!visitorData.name) {
      obj = {...obj, name: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (!visitorData.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(visitorData.email)) {
      obj = {...obj, email: t('not_a_valid_email', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (monetizeObj.type == 'tipJar' && (!visitorData?.tipJarAmount || (visitorData?.tipJarAmount < monetizeObj.minPrice) || (visitorData?.tipJarAmount > monetizeObj.maxPrice))) {
      obj = {...obj, tipJarAmount: visitorData?.tipJarAmount > monetizeObj.maxPrice ? `${t('tip_amount_cant_be_more_than')} ${currencyLabel} ${monetizeObj.maxPrice}` : `${t('tip_amount_cant_be_less_than')} ${currencyLabel} ${monetizeObj.minPrice}`};
      valid = false;
    }
    if (!visitorData.phone) {
      obj = {...obj, phone: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    if (!visitorData.countryCode) {
      obj = {...obj, countryCode: t('this_field_is_required', {lng: theme.displayLocale || 'en'})};
      valid = false;
    }
    setVisitorDataError(obj);
    return valid;
  };

  const getHeading = () => {
    switch (monetizeObj.type) {
      case 'videoMsg':
        return {text: 'how_can_i_help', notes: 'whats_your_question', subTitle: 'ask_me_whatever_you_want'};
      case 'tipJar':
        return {text: 'thankyou_for_your_support', notes: 'something_you_would_like_to_tell_us', subTitle: 'send_a_tip_to_the_user'};
      case 'socialMediaPromote':
        return {text: `Brand promotion on ${monetizeObj.title.split(' ')[monetizeObj.title.split(' ').length - 1]}`, notes: 'what_would_you_like_to_promote', subTitle: 'get_your_brand_promoted_on_my_social_media'};
      case 'sellProduct':
        return {text: monetizeObj.title, notes: 'something_you_would_like_to_tell_us', subTitle: 'get_access_to_my_exclusive_digital_content'};
      case 'shortCall':
        return {text: tapStatus == 'CAPTURED' ? 'book_a_slot' : 'book_one_one_call', notes: 'please_share_anything_that_will_help_prepare_for_our_meeting', subTitle: 'connect_with_me_directly_to_get_your_questions_answered'};
      default:
        return {text: title || 'card_payment', notes: 'notes', subTitle: ''};
    }
  };

  const handlePhoneChange = (phone:any, country:any) => {
    const countryDial = country.dialCode;
    setVisitorData({...visitorData, phone: phone, countryCode: countryDial});
  };

  const fetchChargeStatus = async () => {
    try {
      setLoading(true);
      const chargeResponse = await nonHookRequest({method: 'GET', url: `/finance/api/tap/charges/${clientSecret}`, isShortUrl: true});
      setTapStatus(chargeResponse?.status);
      setTapMsg(chargeResponse?.response?.message);
      if (chargeResponse?.status == 'CAPTURED' && type == 'shortCall' && !monitizeID) {
        setStep('bookingSlot');
      } else {
        setStep('card');
      }
      setLoading(false);
    } catch (error: any) {
      toast(<span style={{color: 'black'}}>{error?.message || 'Something went wrong!'}</span>, {autoClose: 3000});
    }
  };

  const getChargeHtml = () => {
    switch (tapStatus) {
      case 'CAPTURED':
        return (<div className={styles.payment_done}>
          <CheckCircleIcon fontSize='large' style={{color: 'green'}} />
          <div style={{textAlign: 'center'}}>{isArabic(theme?.displayLocale) ? <>شكرًا لك على إكمال طلبك ، سوف تتلقى تحديثًا متعلقًا بطلبك على {new URLSearchParams(window.location.search).get('email')}.<br />{' '}إذا كان لديك أي أسئلة أو استفسارات ، يمكنك التواصل معنا على <a href="mailto:support@alfangroup.com">support@alfangroup.com</a></> : <>Thank you for completing your request, you should receive an update related to your order on {new URLSearchParams(window.location.search).get('email')}.<br /> For any questions or concerns, you can contact us at <a href="mailto:support@alfangroup.com">support@alfangroup.com</a></>}</div>
        </div>);
      default:
        return (<div className={styles.payment_done}>
          <CancelIcon fontSize='large' style={{color: 'red'}} />
          <div style={{textAlign: 'center'}}>{`The transaction has been ${tapMsg?.toLowerCase()}. Please try with the correct details or try again later.`}</div>
        </div>);
    }
  };

  const handleMonetizationLinkClick = async (data: any) => {
    await nonHookRequest({url: '/finance/api/clevertap/monetization_links', method: 'POST', body: {...data, productType: 'Link'}, isShortUrl: true});
    GoSell.openPaymentPage();
  };

  useEffect(() => {
    // api hit for intent
    if (clientSecret) {
      if (type == 'shortCall' && !monitizeID) {
        const name = urlParams.get('name') || '';
        const email = urlParams.get('email') || '';
        const notes = urlParams.get('notes') || '';
        setVisitorData({...visitorData, name: name, email: email, notes: notes});
      }
      fetchChargeStatus();
    }
  }, [clientSecret]);

  useEffect(() => {
    if (step && step == 'bookingSlot') {
      toast(<span style={{color: 'black'}}>{t('your_payment_is_successfully_done', {lng: theme.displayLocale || 'en'})}</span>, {autoClose: 3000});
    }
  }, [step]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
            {step == 'card' && monitizeID && <div className={styles.back} onClick={() => setStep('form')}><ArrowBackIcon /></div>}
            <div className={styles.heading}>{step == 'preview' ? '' : t(getHeading()?.text, {lng: theme.displayLocale || 'en'})}</div>
            <div className={styles.sub_heading}>{step == 'preview' ? '' : t(getHeading()?.subTitle, {lng: theme.displayLocale || 'en'})}</div>
            <div className={styles.content_body}>
              {['form', 'preview'].includes(step) && !loading && <form>
                {
                  step == 'preview' ? (
                    <>
                      <Preview theme={theme} monetizeObj={monetizeObj} linksUser={linksUser} getHeading={getHeading} />
                    </>
                  ) : (
                    <>
                      <div className={styles.row}>
                        {monetizeObj.type == 'tipJar' && <>
                          <div className={styles.label} style={{marginBottom: '6px'}}>
                            {amountLabel}
                            <span className={styles.description} style={{paddingLeft: '3px'}}>
                              ({minAmountLabel} {minPrice})
                            </span>
                          </div>
                        <div className={`${styles.row} ${styles.tipjarBox}`}>
                            {getAmountOptions(monetizeObj.minPrice).map((amount: number, index: number) => (
                              <div key={index} onClick={() => setVisitorData({...visitorData, tipJarAmount: amount, isCustom: false})} className={visitorData.tipJarAmount == amount && !visitorData.isCustom ? styles.activeBox : ''}>{currencyLabel} {amount}</div>
                            ))}
                          <div onClick={() => setVisitorData({...visitorData, tipJarAmount: 20, isCustom: true})} className={visitorData.isCustom ? styles.activeBox : ''} >Custom ({currencyLabel})</div>
                        </div>
                        {visitorData.isCustom && <div className={styles.row} style={{marginTop: '10px'}}>
                          {/* <div className={styles.label}>{t('amount')}</div> */}
                          <div className={styles.input}>
                            <input type="number" className={visitorDataError.tipJarAmount ? styles.input_error : ''} value={visitorData.tipJarAmount} onChange={(e: any) => handleChangeInput(e.target.value, 'tipJarAmount')} />
                          </div>
                          {visitorDataError?.tipJarAmount && <div className={styles.error}>{t(visitorDataError?.tipJarAmount)}</div>}
                        </div>}
                        </>}
                        <div className={styles.label}>{t('full_name', {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <input type="text" className={visitorDataError.name ? styles.input_error : ''} value={visitorData.name} onChange={(e: any) => handleChangeInput(e.target.value, 'name')} />
                        </div>
                        {visitorDataError?.name && <div className={styles.error}>{t(visitorDataError?.name)}</div>}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.label}>{t('email', {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <input type="email" className={visitorDataError.email ? styles.input_error : ''} value={visitorData.email} onChange={(e: any) => handleChangeInput(e.target.value, 'email')} />
                        </div>
                        {visitorDataError?.email && <div className={styles.error}>{t(visitorDataError?.email)}</div>}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.label}>{t('mobileNumber', {lng: theme.displayLocale || 'en'})}</div>
                        {/* <div className={styles.input}> */}
                        {/* <PhoneInput
                          localization={ isArabic(i18n.language) ? ar : undefined}
                          placeholder={t('mobileNumber')}
                          value={visitorData.phone}
                          onChange={(v, c)=> handlePhoneChange(v, c)}
                          specialLabel='' country={'ae'}
                          inputClass={styles.phoneInput}
                          inputProps={{name: 'phone', required: true, id: 'phone'}}/> */}
                        <PhoneNumber
                          localization={ isArabic(i18n.language) ? ar : undefined}
                          placeholder={t('mobileNumber')}
                          value={visitorData.phone}
                          onChange={(v, c)=> handlePhoneChange(v, c)}
                          specialLabel='' country={'ae'}
                          inputClass={styles.phoneInput}
                          inputProps={{name: 'phone', required: true, id: 'phone'}}/>
                        {(visitorDataError?.phone || visitorDataError?.countryCode) && <div className={styles.error}>{t(visitorDataError?.phone)}</div>}
                        {/* </div> */}
                      </div>
                      <div className={styles.row} style={{marginTop: '15px'}}>
                        <div className={styles.label}>{t(getHeading()?.notes, {lng: theme.displayLocale || 'en'})}</div>
                        <div className={styles.input}>
                          <textarea value={visitorData.notes} onChange={(e: any) => handleChangeInput(e.target.value, 'notes')} style={{height: '100px'}} />
                        </div>
                      </div>
                      <div className={styles.terms}>
                        <div>
                        <Checkbox checked={termsOfService} onChange={() => setTermsOfService(!termsOfService)} name="checkedAccept" />
                        </div>
                        <div className={styles.title}>
                          <div>
                            <Trans i18nKey='term_and_conditions'>
                              Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
                <button type="button" onClick={(e: any) => {
                  if (step == 'preview') {
                    setStep('form');
                  } else if (step == 'form') {
                    const isValid = validateData();
                    if (isValid) {
                      // setLoadTap(true);
                      // GoSell.openLightBox();
                      handleMonetizationLinkClick(monetizeObj?.type == 'tipJar' ? {link_id: monitizeID, amount: visitorData.tipJarAmount * 100, currency: 'USD', clevertapEvent: 'tip_jar_intent'} : {link_id: monitizeID, clevertapEvent: 'payment_intent'});
                    }
                  }
                }} className={styles.button} disabled={loading || (step == 'form' && (!!!visitorData.name.length || !!!visitorData.email.length || !!!visitorData.phone.length || !!!visitorData.countryCode.length || !!!termsOfService))} id="submit">
                  <span className={styles.button_text}>
                    {loading ? <div className={styles.spinner}></div> : (['form', 'preview'].includes(step) ? 'Next' : t('pay_now', {lng: theme.displayLocale || 'en'}))}
                  </span>
                </button>
              </form>}
              {step == 'card' && clientSecret && !loading && getChargeHtml()}
              {step == 'bookingSlot' && <CalendarBooking url={`${monetizeObj.url}?hide_gdpr_banner=1&name=${visitorData.name}&email=${visitorData.email}&a1=${visitorData.notes}`} />}
              {loading && <div className={styles.loader}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div>}
            </div>

          </div>
        </div>
      </div>
      {monitizeID && <GoSell
        gateway={{
          publicKey: envConfigObj.tapPublicKey as string,
          merchantId: envConfigObj[mIDMap[monetizeObj.currency?.toLowerCase()] || mIDMap['usd']] as string,
          language: 'en',
          contactInfo: true,
          supportedCurrencies: 'all',
          supportedPaymentMethods: 'all',
          saveCardOption: true,
          customerCards: true,
          notifications: 'standard',
          backgroundImg: {
            url: '',
            opacity: '0.5',
          },
          labels: {
            cardNumber: 'Card Number',
            expirationDate: 'MM/YY',
            cvv: 'CVV',
            cardHolder: 'Name on Card',
            actionButton: 'Pay',
          },
          style: {
            base: {
              'color': '#535353',
              'lineHeight': '18px',
              'fontFamily': 'sans-serif',
              'fontSmoothing': 'antialiased',
              'fontSize': '16px',
              '::placeholder': {
                color: 'rgba(0, 0, 0, 0.26)',
                fontSize: '15px',
              },
            },
            invalid: {
              color: 'red',
              iconColor: '#fa755a',
            },
          },
        }}
        customer={{
          first_name: visitorData.name,
          email: visitorData.email,
          phone: {
            country_code: visitorData.countryCode,
            number: visitorData.phone.slice(visitorData.countryCode.length, visitorData.phone.length),
          },
        }}
        order={{
          // TODO: Dynamic currency conversion factor implementation
          amount: monetizeObj?.type == 'tipJar' ? (visitorData.tipJarAmount) : monetizeObj.amount[0].amount/100,
          currency: monetizeObj.currency?.toUpperCase() ?? 'USD',
          shipping: null,
          taxes: null,
        }}
        transaction={{
          mode: 'charge',
          charge: {
            saveCard: false,
            threeDSecure: true,
            metadata: {
              linkId: monitizeID,
              userId: userId,
              email: visitorData.email,
              name: visitorData.name,
              phone: `${visitorData.countryCode}${visitorData.phone.slice(visitorData.countryCode.length, visitorData.phone.length)}`,
              notes: visitorData.notes,
              productType: 'Link',
            },
            receipt: {
              email: false,
              sms: true,
            },
            redirect: `${window.location.origin}${window.location.pathname}?email=${visitorData.email}&linkType=${monetizeObj.type}&bookUrl=${monetizeObj.url}&name=${visitorData?.name}&notes=${visitorData?.notes}`,
            post: `${envConfig(true).baseUrl}/finance/api/tap/webhook`,
          },
        }}
      />}
    </>
  );
};

export default ChargeCardTapModal;
