import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./SelectGender.module.scss";
type GenderProps = {
    value: string;
    handleChange: Function;
};

const SelectGender: React.FC<GenderProps> = ({value, handleChange}: GenderProps)=> {
  const {t} = useTranslation();
  return (
      <select onChange={(e) => handleChange(e?.target?.value || '')} value={value} className={styles.select}>
          <option value="">{t('select')}</option>
          <option value="Male">{t('male')}</option>
          <option value="Female">{t('female')}</option>
          <option value="Other">{t('other')}</option>
      </select>
  );
};

export default SelectGender;
                
