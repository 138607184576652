import React, {ComponentProps, useState} from 'react';
import {ReactComponent as PencilIcon} from '../../../assets/images/edit_pencil.svg';
import styles from './EditableTextInput.module.scss';

export type EditableTextInputProps = Omit<
  ComponentProps<'input'>,
  'onChange' | 'value'
> & {
  value: string;
  onChange: (value: string) => void;
  label: string;
};

const EditableTextInput = ({
  value,
  onChange,
  ...inputProps
}: Omit<EditableTextInputProps, 'label'>) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className={styles.container}>
      <input
        id={styles.input}
        type='text'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => setIsEditing(false)}
        onClick={() => setIsEditing(true)}
        {...inputProps}
      />
      {!isEditing && !inputProps.disabled && (
        <label htmlFor={styles.input}>
          <PencilIcon className={styles.pencilIcon} width={24} />
        </label>
      )}
    </div>
  );
};

export default EditableTextInput;
