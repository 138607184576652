import React, {useState} from 'react';
import styles from './ExploreModal.module.scss';
import {useSelector} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {ExploreModalData, socialMediaLinks, addLinkIcons} from '../../constants/socialMedia';
import {LinksSectionItem, selectLinksUser} from '../../features/stores/linksUserSlicer';
import {getConfig} from '../../features/stores/configSlicer';
import 'antd/dist/antd.css';
import {useTranslation} from 'react-i18next';
import {haveSegmentPermission} from '../../features/util';
import LinkCard, {ExploreLinkItem} from './LinkCard';
import {selectMe} from '../../features/stores/userSlicer';

interface IProps{
    handleClose: () => void;
    importantLinksSection: any;
    onAddLink: (link: LinksSectionItem, editable: boolean) => void;
};

const ExploreModal = ({handleClose, onAddLink, importantLinksSection}: IProps) => {
  const {t, i18n} = useTranslation();
  const [currentView, setCurrentView] = useState('OVERVIEW');
  const linksUser = useSelector(selectLinksUser);
  const user = useSelector(selectMe);
  const publicProfileLang = user.me?.linksUser?.displayLocale ?? 'en';
  const userLang = i18n.language as 'ar' | 'en';
  const config = useSelector(getConfig).data || [];

  const seasonalLinksSection = linksUser.linksSections.find((section) => section.type === 'seasonal');


  const linkLimit = haveSegmentPermission(config, 'maxLinkLimit') || 10;
  const importantLinksCount = importantLinksSection?.linksSectionItems?.length || 0;
  const isLinkLimitReached = importantLinksCount >= linkLimit;

  const setCurrentViewHandle = (value: string) => {
    setCurrentView(value);
  };


  const handleAddLink = (item: ExploreLinkItem, editable: boolean, socialLinks: boolean = false) => {
    const link: LinksSectionItem = {
      id: Date.now().toString(),
      type: item?.type ?? 'other',
      url: item?.url ?? '',
      customUploadedImage: '',
      title: item?.name ?? '',
      linksSectionId: item?.sectionId ?? importantLinksSection?.id,
      orderNumber: 0,
      hidden: false,
      isVisible: true,
      layout: 'new',
      startDate: item?.startDate,
      endDate: item?.endDate,
      price: ['shortCall'].includes(item?.type) || socialLinks ? undefined : [{type: "default", value: "default", amount: undefined}],
    };
    onAddLink(link, editable);
    handleClose();
  };


  const seasonalLinksPermission = haveSegmentPermission(config, 'seasonalLinks');
  // each user can have only 1 seasonal link of each type
  const AvailableSeasonalLinks = Array.isArray(seasonalLinksPermission) ? seasonalLinksPermission.filter((e) => !seasonalLinksSection?.linksSectionItems.find((link) => link.type === e.type)) : [];
  const seasonalLinks: ExploreLinkItem[] = AvailableSeasonalLinks.map((item: any) => ({
    type: item.type,
    name: item.title?.[publicProfileLang],
    title: item.title?.[userLang],
    subTitle: item.subTitle?.[userLang],
    url: item.url,
    editable: false,
    defaultIcon: socialMediaLinks.find((e) => e.key === item.type)?.defaultIcon || '',
    sectionId: seasonalLinksSection?.id,
    startDate: item.startDate,
    endDate: item.endDate,
  }));


  const socialLinks: ExploreLinkItem[] = ExploreModalData?.map((item) => {
    const LinkIcon = addLinkIcons[item.key] || addLinkIcons['regularLink'];
    return {
      type: item.key,
      title: item.name,
      name: item.name,
      subTitle: item.subTitle,
      defaultIcon: <LinkIcon />,
      sectionId: importantLinksSection?.id,
    };
  });

  const monetizationEnabled = haveSegmentPermission(config, 'monetize') && !haveSegmentPermission(config, 'monetizeBlocklist');
  const monetizeLinks: ExploreLinkItem[] = haveSegmentPermission(config, 'monetize2')?.map((item: any) => {
    const LinkIcon = addLinkIcons[item.id] || addLinkIcons['regularLink'];
    return {
      type: item.id,
      title: item.title,
      name: item.title,
      subTitle: item.subTitle,
      defaultIcon: <LinkIcon />,
    };
  });

  const getContentBody = () => {
    switch (currentView) {
      case 'OVERVIEW':
        return <>
          {monetizationEnabled && !isLinkLimitReached ?
            <>
              <div className={styles.head_container}>
                <div className={styles.head_left}>
                  <div className={styles.head_title}>{t('monetize_and_earn')}</div>
                  <div className={styles.head_subtitle}>{t('earn_by_providing_services_thorugh_alfan_link')}</div>
                </div>
              </div>
              <div className={styles.box_body}>
                {monetizeLinks.map((item, index) => (
                  <LinkCard
                    key={`${item.type}-${index}`}
                    item={item}
                    onAddLink={(item) => handleAddLink(item, true)} />
                ))}
              </div>
            </> : null}
          {seasonalLinks.length > 0 &&
            <>
              <div className={styles.head_container}>
                <div className={styles.head_left}>
                  <div className={styles.head_title}>{t('links_of_the_season')}</div>
                </div>
              </div>
              <div className={`${styles.box_body} ${styles.seasonal_links_box_body}`}>
                {seasonalLinks?.map((item, index) => (
                  <LinkCard
                    key={`${item.type}-${index}`}
                    item={item}
                    onAddLink={(link) => handleAddLink(link, false, true)}
                    viewBtn={true} />
                ))}
              </div>
            </>}
          {!isLinkLimitReached &&
            <>
              <div className={styles.head_container}>
                <div className={styles.head_left}>
                  <div className={styles.head_title}>{t('connect_your_content')}</div>
                </div>
                <div className={styles.head_right} onClick={() => setCurrentViewHandle('CHOOSE-LINK')}>{t('view_all')} <ArrowRightAltIcon /></div>
              </div>
              <div className={styles.box_body}>
                {socialLinks.slice(0, 4).map((item, index) => (
                  <LinkCard
                    key={`${item.type}-${index}`}
                    item={item}
                    onAddLink={(link) => handleAddLink(link, true, true)}
                  />
                ))}
              </div>
            </>}
        </>;
        case 'CHOOSE-LINK': 
            return <>
            <div className={styles.head_container}>
                <div className={styles.head_left}>
                    <div className={styles.head_title}>{t('connect_your_content')}</div>
                    <div className={styles.head_subtitle}>{t('share_content_directly_on_your_alfan_link')}</div>
                </div>
            </div>
            <div className={styles.box_body}>
            {socialLinks.map((item, index) => (
              <LinkCard
                key={`${item.type}-${index}`}
                item={item}
                onAddLink={(link) => handleAddLink(link, true, true)} />
            ))}
            </div>
            </>;        
        default:
            break;       
    }  
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
              <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
              {/* {currentView != 'OVERVIEW' && <div className={styles.back} onClick={handleBackwardClick}><KeyboardBackspaceIcon /></div>} */}
              <div className={styles.main_heading}>{t('add_to_alfan_links')}</div>
              <div className={`${styles.content_body} ${currentView == 'ADD-LINK' ? styles.add_view : ''}`}>
                {getContentBody()}
              </div>
              
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreModal;
